import { Card, Divider, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { RcFile } from "antd/lib/upload";
import { generatePath } from "react-router-dom";
import t from "../../../../../app/i18n";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import FileTypeIconWithFilename from "../../../../../common/components/icons/FileTypeIconWithFilename";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import UploadDragger from "../../../../../common/components/views/UploadDragger";
import { TableSizes } from "../../../../../common/constants";
import { formatFileSize, formatLocaleDateTime } from "../../../../../common/utils/formatUtils";
import { tableStandardProps } from "../../../../../common/utils/utils";
import {
  deleteContractAttachmentActions,
  downloadContractAttachmentsAsZipActions,
  uploadContractAttachmentsActions
} from "../../../ducks";
import { CONTRACT_ROUTE_PATHS } from "../../../paths";
import { Contract, ContractAttachment } from "../../../types";

interface Props {
  contract: Contract;
  onDownloadAsZip: typeof downloadContractAttachmentsAsZipActions.request;
  onUpload: typeof uploadContractAttachmentsActions.request;
  onDelete: typeof deleteContractAttachmentActions.request;
}

const ContractAttachmentsSection = ({ contract, ...props }: Props) => {
  const handleAttachmentsUpload = (_: RcFile, fileList: RcFile[]): boolean => {
    if (fileList.length > 0) {
      const formData = new FormData();
      fileList.forEach(file => formData.append("files", file));
      fileList.splice(0, fileList.length);

      props.onUpload({ id: contract.id, object: formData });
    }

    return false;
  };

  const columns: ColumnsType<ContractAttachment> = [
    {
      key: "createdAt",
      title: t("common.createdAt"),
      width: 160,
      render: (_, record) => formatLocaleDateTime(record.createdAt)
    },
    {
      key: "filename",
      title: t("contract.sections.attachments.filename"),
      ellipsis: { showTitle: false },
      render: (_, record) => (
        <FileTypeIconWithFilename contentType={record.file.contentType} filename={record.file.filename} ellipsis />
      )
    },
    {
      key: "size",
      title: t("contract.sections.attachments.size"),
      width: 100,
      render: (_, record) => formatFileSize(record.file.size)
    },
    {
      key: "sentToClient",
      title: t("contract.sections.attachments.sentToClient"),
      width: 80,
      render: (_, record) => (record.sentToClient ? <AntIcon type="check" /> : <AntIcon type="close" />)
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon
            path={generatePath(CONTRACT_ROUTE_PATHS.attachment.to, { id1: contract.id, id2: record.id })}
            target="_blank"
            icon="export"
            color="blue"
            text={t("common.open")}
          />

          {record.isDeletable && (
            <>
              <Divider type="vertical" />

              <Popconfirm
                title={t("contract.helpers.deleteAttachmentConfirm")}
                icon={<PopconfirmDeleteIcon />}
                okText={t("common.yes")}
                cancelText={t("common.no")}
                okType="danger"
                onConfirm={() => props.onDelete({ id1: contract.id, id2: record.id })}
              >
                <span>
                  <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
                </span>
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];

  return (
    <Card
      type="inner"
      className="card-box card-box--inner-extra"
      title={t("contract.sections.attachments.label")}
      extra={
        contract.attachments.length > 0 && (
          <ActionButton
            icon="download"
            label={t("contract.actions.downloadAttachmentsAsZip")}
            onClick={() => props.onDownloadAsZip({ id: contract.id })}
          />
        )
      }
    >
      <Table<ContractAttachment>
        {...tableStandardProps()}
        columns={columns}
        scroll={{ x: TableSizes.MEDIUM }}
        dataSource={contract.attachments}
        pagination={false}
      />

      <div className="margin-top-small margin-bottom-small">
        <UploadDragger multiple showUploadList={false} beforeUpload={handleAttachmentsUpload} />
      </div>
    </Card>
  );
};

export default ContractAttachmentsSection;
