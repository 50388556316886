import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import classNames from "classnames";
import AntIcon, { type AntIconType } from "../icons/AntIcon";
import styles from "./ActionButton.module.scss";

interface Props extends ButtonProps {
  icon: AntIconType;
  label: string;
}

const ActionButton = ({ icon, label, color, className, ...props }: Props) => (
  <Button
    {...props}
    size="small"
    type="dashed"
    icon={<AntIcon type={icon} />}
    className={classNames(className, color ? styles[`action-button-${color}`] : "")}
  >
    {label}
  </Button>
);

export default ActionButton;
