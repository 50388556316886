import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { PageSizes } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { ActionProps, RootState } from "../../../common/types";
import { appendSearchParamsToURL, numberOrZero } from "../../../common/utils/utils";
import { selectRouterLocationSearch } from "../../ducks";
import InstitutionFilterView from "../components/views/InstitutionFilterView";
import InstitutionListTableView from "../components/views/InstitutionListTableView";
import {
  deleteStateInstitutionsPageAction,
  filterInstitutionsActions,
  selectInstitutionsCurrentPage,
  updateInstitutionSettingsActions
} from "../ducks";
import { InstitutionType } from "../enums";
import { InstitutionFilterPageRequest, InstitutionFilterPageResult, InstitutionList } from "../types";

interface StateProps {
  institutionsCurrentPage: InstitutionFilterPageResult;
  urlSearchQuery: string;
}

interface ActionsMap {
  filterInstitutions: typeof filterInstitutionsActions.request;
  updateInstitutionSettings: typeof updateInstitutionSettingsActions.request;
  deleteStateInstitutionsPage: typeof deleteStateInstitutionsPageAction;
}

const InstitutionListContainer = ({
  institutionsCurrentPage,
  urlSearchQuery,
  actions
}: StateProps & ActionProps<ActionsMap>) => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(urlSearchQuery);
    actions.filterInstitutions({
      pageIndex: numberOrZero(urlParams.get("pageIndex")),
      pageSize: PageSizes.LARGE,
      keyword: urlParams.get("keyword") ?? undefined,
      types: urlParams.getAll("types") as InstitutionType[]
    });
    return () => {
      actions.deleteStateInstitutionsPage();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTablePageChange = (pageNumber: number): void => {
    const { pageSize, keyword, types } = institutionsCurrentPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterInstitutions({ pageIndex: pageNumber - 1, pageSize, keyword, types });
  };

  const handleFilterSubmit = (filter: InstitutionFilterPageRequest): void => {
    navigate(appendSearchParamsToURL({ ...filter, pageIndex: undefined, keyword: filter.keyword || undefined }), {
      replace: true
    });
    actions.filterInstitutions({ pageIndex: 0, pageSize: institutionsCurrentPage.pageSize, ...filter });
  };

  const handleInstitutionDeactivatedToggle = (institution: InstitutionList) => {
    if (institution.settings) {
      actions.updateInstitutionSettings({
        id: institution.id,
        object: {
          optimisticLockVersion: institution.settings.optimisticLockVersion,
          deactivated: !institution.settings.deactivated
        }
      });
    }
  };

  return (
    <ContentWrapper>
      <InstitutionFilterView filter={institutionsCurrentPage} onFilterSubmit={handleFilterSubmit} />

      <InstitutionListTableView
        institutionsPage={institutionsCurrentPage}
        onPageChange={handleTablePageChange}
        onInstitutionDeactivatedToggle={handleInstitutionDeactivatedToggle}
      />
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  institutionsCurrentPage: selectInstitutionsCurrentPage(state),
  urlSearchQuery: selectRouterLocationSearch(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      filterInstitutions: filterInstitutionsActions.request,
      updateInstitutionSettings: updateInstitutionSettingsActions.request,
      deleteStateInstitutionsPage: deleteStateInstitutionsPageAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(InstitutionListContainer);
