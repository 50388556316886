export const ATTACHMENT_BOUNDARY_PATH_PREFIX = "/file-view";
export const WILDCARD_ROUTE = "*";
export const NESTED_ROUTE_PATH = "/" + WILDCARD_ROUTE;
export const AUTH_REDIRECT_SEARCH_PARAM = "redirect";

export const accessTreePathDelimiter = "|";
export const rowGutter = 16;

export const HttpStatus = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  MULTI_STATUS: 207,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  UNSUPPORTED_MEDIA_TYPE: 415,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500
};

export const ViewBreakpoints = {
  TABLET: 992,
  DESKTOP: 1200
};

export const ContentType = {
  RAR: "application/octet-stream",
  ZIP: "application/x-zip-compressed",
  PDF: "application/pdf",
  DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  PPTX: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  CSV: "text/csv"
};

export const PageSizes = {
  TINY: 5,
  SMALL: 10,
  MEDIUM: 15,
  LARGE: 25,
  HUGE: 50
};

export const ModalSizes = {
  SMALL: 400,
  MEDIUM: 700,
  LARGE: 850,
  HUGE: 1000
};

export const DrawerSizes = {
  SMALL: 500,
  MEDIUM: 800,
  LARGE: 975,
  HUGE: 1300
};

export const TableSizes = {
  SMALL: 600,
  MEDIUM: 900,
  LARGE: 1150,
  HUGE: 1500
};

export const Cities = [
  "Bratislava",
  "Košice",
  "Banská Bystrica",
  "Nitra",
  "Prešov",
  "Trenčín",
  "Trnava",
  "Žilina",
  "Banská Štiavnica",
  "Bardejov",
  "Brezno",
  "Bytča",
  "Bánovce nad Bebravou",
  "Detva",
  "Dolný Kubín",
  "Dunajská Streda",
  "Galanta",
  "Gelnica",
  "Hlohovec",
  "Humenné",
  "Iľava",
  "Kežmarok",
  "Komárno",
  "Krupina",
  "Kysucké Nové Mesto",
  "Levice",
  "Levoča",
  "Liptovský Mikuláš",
  "Lučenec",
  "Malacky",
  "Martin",
  "Medzilaborce",
  "Michalovce",
  "Myjava",
  "Nové Mesto nad Váhom",
  "Nové Zámky",
  "Námestovo",
  "Partizánske",
  "Pezinok",
  "Piešťany",
  "Poltár",
  "Poprad",
  "Považská Bystrica",
  "Prievidza",
  "Púchov",
  "Revúca",
  "Rimavská Sobota",
  "Rožňava",
  "Ružomberok",
  "Sabinov",
  "Senec",
  "Senica",
  "Skalica",
  "Snina",
  "Sobrance",
  "Spišská Nová Ves",
  "Stará Ľubovňa",
  "Stropkov",
  "Svidník",
  "Topoľčany",
  "Trebišov",
  "Turčianske Teplice",
  "Tvrdošín",
  "Veľký Krtíš",
  "Vranov nad Topľou",
  "Zlaté Moravce",
  "Zvolen",
  "Čadca",
  "Šaľa",
  "Žarnovica",
  "Žiar nad Hronom"
];

export const AcademicDegrees = [
  "Bc.",
  "Ing.",
  "Mgr.",
  "JUDr.",
  "MUDr.",
  "PaedDr.",
  "PhDr.",
  "RNDr.",
  "abs. v. š.",
  "akad.",
  "akad. arch.",
  "akad. mal.",
  "akad. soch.",
  "arch.",
  "Bc. Mgr.",
  "BcA.",
  "dipl.",
  "dipl. tech.",
  "doc.",
  "doc. Dr. Ing.",
  "doc. Dr.",
  "doc. Ing. PhDr.",
  "doc. Ing. arch.",
  "doc. Ing.",
  "doc. JUDr.",
  "doc. MUDr.",
  "doc. MVDr.",
  "doc. Mgr. art.",
  "doc. Mgr.",
  "doc. PaedDr.",
  "doc. PhDr. Ing.",
  "doc. PhDr.",
  "doc. PhMr.",
  "doc. RNDr.",
  "doc. RSDr.",
  "doc. ThDr. PaedDr.",
  "doc. ThDr.",
  "doc. akad. mal.",
  "Dr.",
  "Dr. Ing.",
  "Dr. JUDr.",
  "Dr. MUDr.",
  "Dr. Mgr.",
  "Dr. PhDr.",
  "Dr. PhMr.",
  "Dr. RNDr.",
  "ICDr.",
  "Ing. JUDr.",
  "Ing. MUDr.",
  "Ing. MVDr.",
  "Ing. Mgr.",
  "Ing. PaedDr.",
  "Ing. PhDr.",
  "Ing. RNDr.",
  "Ing. ThDr.",
  "Ing. akad. arch.",
  "Ing. arch. Mgr.",
  "Ing. arch.",
  "JCDr.",
  "JUDr. Ing.",
  "JUDr. MUDr.",
  "JUDr. MVDr.",
  "JUDr. Mgr.",
  "JUDr. PaedDr.",
  "JUDr. PhDr.",
  "JUDr. RNDr.",
  "MBA",
  "MDDr.",
  "MUDr. Ing.",
  "MUDr. MVDr.",
  "MUDr. Mgr.",
  "MUDr. PhDr.",
  "MVDr.",
  "MVDr. Ing.",
  "MVDr. MUDr.",
  "MVDr. PhDr.",
  "Mag. iur.",
  "MgA.",
  "Mgr. Ing.",
  "Mgr. JUDr.",
  "Mgr. PaedDr.",
  "Mgr. PhDr.",
  "Mgr. RSDr.",
  "Mgr. art.",
  "PaedDr. Ing.",
  "PaedDr. JUDr.",
  "PaedDr. MUDr.",
  "PaedDr. Mgr. art.",
  "PaedDr. Mgr.",
  "PaedDr. PhDr.",
  "PhDr. Ing.",
  "PhDr. JUDr.",
  "PhDr. MUDr.",
  "PhDr. Mgr.",
  "PhDr. PaedDr.",
  "PhDr. RNDr.",
  "PhMr.",
  "PhMr. Ing.",
  "PhMr. MVDr.",
  "PhMr. Mgr.",
  "PharmDr.",
  "PharmDr. RNDr.",
  "prof.",
  "prof. Dr.",
  "prof. Dr. Ing.",
  "prof. Ing.",
  "prof. Ing. PhDr.",
  "prof. Ing. RNDr.",
  "prof. JUDr.",
  "prof. MUDr.",
  "prof. MVDr.",
  "prof. MVDr. JUDr.",
  "prof. Mgr.",
  "prof. PaedDr.",
  "prof. PhDr.",
  "prof. RNDr.",
  "prof. RNDr. PhDr.",
  "prof. ThDr.",
  "prof. doc.",
  "prof. doc. Ing.",
  "prof. doc. Mgr.",
  "prom. biol.",
  "prom. chem.",
  "prom. d.",
  "prom. ek.",
  "prom. fil.",
  "prom. filol.",
  "prom. fyz.",
  "prom. geog.",
  "prom. geol.",
  "prom. hist.",
  "prom. knih.",
  "prom. logop.",
  "prom. mat.",
  "prom. nov.",
  "prom. ped.",
  "prom. pharm.",
  "prom. práv.",
  "prom. psych.",
  "prom. vet.",
  "prom. zub.",
  "RCDr.",
  "RNDr. Ing.",
  "RNDr. Mgr.",
  "RNDr. PhDr.",
  "RNDr. PhMr.",
  "RSDr.",
  "RSDr. Ing.",
  "RSDr. Mgr.",
  "ThDr.",
  "ThDr. ThLic.",
  "ThLic.",
  "ThMgr."
];

export const AcademicDegreesAfter = [
  "ArtD.",
  "BA",
  "BArch",
  "BBA",
  "BBS",
  "BSc",
  "CSc.",
  "DiS.",
  "DiS.art",
  "Dr.h.c.",
  "Dr.theol.",
  "DrSc.",
  "FCCA",
  "LL.M",
  "M.Mus.",
  "M.Phil.",
  "MA",
  "MBA",
  "MPH",
  "MSc",
  "PhD.",
  "PhD. CSc.",
  "PhD. MPH.",
  "Th.D."
];

export const DistrictOffices = [
  "Banská Bystrica",
  "Bardejov",
  "Bánovce nad Bebravou",
  "Bratislava",
  "Brezno",
  "Čadca",
  "Dolný Kubín",
  "Dunajská Streda",
  "Galanta",
  "Humenné",
  "Kežmarok",
  "Komárno",
  "Košice",
  "Košice - okolie",
  "Levice",
  "Liptovský Mikuláš",
  "Lučenec",
  "Malacky",
  "Martin",
  "Michalovce",
  "Námestovo",
  "Nitra",
  "Nové Mesto nad Váhom",
  "Nové Zámky",
  "Pezinok",
  "Piešťany",
  "Poprad",
  "Považská Bystrica",
  "Prešov",
  "Prievidza",
  "Rimavská Sobota",
  "Rožňava",
  "Ružomberok",
  "Senec",
  "Senica",
  "Spišská Nová Ves",
  "Stará Ľubovňa",
  "Stropkov",
  "Svidník",
  "Šaľa",
  "Štúrovo",
  "Topoľčany",
  "Trebišov",
  "Trenčín",
  "Trnava",
  "Veľký Krtíš",
  "Vranov nad Topľou",
  "Zvolen",
  "Žiar nad Hronom",
  "Žilina",
  "Národná banka Slovenska"
];

export const CommercialRegisterSections = ["Sro", "Pš", "Sa", "Sr", "Firm", "Dr", "Po", "Pšn"];

export const CommercialRegisterDistrictOffices = [
  "Mestský súd Bratislava III",
  "Mestský súd Košice",
  "Okresný súd Banská Bystrica",
  "Okresný súd Nitra",
  "Okresný súd Prešov",
  "Okresný súd Trenčín",
  "Okresný súd Trnava",
  "Okresný súd Žilina",
  "Štatistický úrad Slovenskej republiky"
];

export const BratislavaCityDistricts = [
  "Čunovo",
  "Devín",
  "Devínska Nová Ves",
  "Dúbravka",
  "Jarovce",
  "Karlova Ves",
  "Lamač",
  "Nové Mesto",
  "Petržalka",
  "Podunajské Biskupice",
  "Rača",
  "Rusovce",
  "Ružinov",
  "Staré Mesto",
  "Vajnory",
  "Vrakuňa",
  "Záhorská Bystrica"
];

export const KosiceCityDistricts = [
  "Barca",
  "Dargovských hrdinov",
  "Džungľa",
  "Juh",
  "Kavečany",
  "Košická Nová Ves",
  "Krásna",
  "Lorinčík",
  "Luník IX",
  "Myslava",
  "Nad jazerom",
  "Pereš",
  "Poľov",
  "Sever",
  "Sídlisko KVP",
  "Sídlisko Ťahanovce",
  "Staré Mesto",
  "Šaca",
  "Šebastovce",
  "Ťahanovce",
  "Vyšné Opátske",
  "Západ"
];

// https://www.vlada.gov.sk//statne-sviatky/
export const SlovakHolidays = [
  { d: 1, m: 1 }, // 1. 1.   - Deň vzniku Slovenskej republiky
  { d: 6, m: 1 }, // 6. 1.   - Zjavenie Pána (Traja králi a vianočný sviatok pravoslávnych kresťanov)
  { d: 1, m: 5 }, // 1. 5.   - Sviatok práce
  { d: 8, m: 5 }, // 8. 5.   - Deň víťazstva nad fašizmom
  { d: 5, m: 7 }, // 5. 7.   - Sviatok sv. Cyrila a Metoda
  { d: 29, m: 8 }, // 29. 8.  - Výročie Slovenského národného povstania
  { d: 1, m: 9 }, // 1. 9.   - Deň ústavy SR
  { d: 15, m: 9 }, // 15. 9.  - Sedembolestná Panna Mária
  { d: 1, m: 11 }, // 1. 11.  - Sviatok všetkých svätých
  { d: 17, m: 11 }, // 17. 11. - Deň boja za slobodu a demokraciu
  { d: 24, m: 12 }, // 24. 12. - Štedrý deň
  { d: 25, m: 12 }, // 25. 12. - Prvý sviatok vianočný
  { d: 26, m: 12 } // 26. 12. - Druhý sviatok vianočný
];
