import { Card } from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../app/i18n";
import ActionButton from "../../../common/components/buttons/ActionButton";
import BackNavigationArrow from "../../../common/components/views/BackNavigationArrow";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import { ActionProps, EntityIdObject, RootState } from "../../../common/types";
import { requests } from "../api";
import AdminCreateUserAccountForm from "../components/forms/AdminCreateUserAccountForm";
import AdminUpdateUserAccountForm from "../components/forms/AdminUpdateUserAccountForm";
import AdminUpdateUserForm from "../components/forms/AdminUpdateUserForm";
import AdminUserDetailView from "../components/views/detail/AdminUserDetailView";
import {
  adminAddAgentUserAccountActions,
  adminDeleteAgentUserAccountActions,
  adminGetUserActions,
  adminResendUserConfirmLinkActions,
  adminUpdateAgentUserAccountActions,
  adminUpdateUserActions,
  deleteStateAdminUserDetailAction,
  selectAdminUserDetail
} from "../ducks";
import { UserAccount, UserAdminView } from "../types";

interface StateProps {
  user?: UserAdminView;
}

interface ActionsMap {
  adminGetUser: typeof adminGetUserActions.request;
  adminUpdateUser: typeof adminUpdateUserActions.request;
  adminAddAgentUserAccount: typeof adminAddAgentUserAccountActions.request;
  adminUpdateAgentUserAccount: typeof adminUpdateAgentUserAccountActions.request;
  adminDeleteAgentUserAccount: typeof adminDeleteAgentUserAccountActions.request;
  adminResendUserConfirmLink: typeof adminResendUserConfirmLinkActions.request;
  deleteStateAdminUserDetail: typeof deleteStateAdminUserDetailAction;
}

type Props = StateProps & ActionProps<ActionsMap>;

const AdminUserDetailContainer = ({ user, ...props }: Props) => {
  const { id } = useParams<EntityIdObject>() as { id: string };

  const [userUpdateFormOpen, setUserUpdateFormOpen] = useState<boolean>(false);
  const [userAccountCreateFormOpen, setUserAccountCreateFormOpen] = useState<boolean>(false);
  const [updatedUserAccount, setUpdatedUserAccount] = useState<UserAccount>();

  useEffect(() => {
    if (user?.id !== id) {
      props.actions.adminGetUser({ id });
    }

    return () => {
      props.actions.deleteStateAdminUserDetail();
    };
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentWrapper>
      <DisplayWrapper itemLoaded={!!user} notFoundCheckRequest={requests.ADMIN_GET_USER}>
        {user && (
          <Card
            className="card-box"
            title={
              <BackNavigationArrow>
                <h2 className="left-float">{user.name}</h2>
              </BackNavigationArrow>
            }
          >
            <AdminUserDetailView
              user={user}
              onUpdateUserClick={() => setUserUpdateFormOpen(true)}
              onResendUserConfirmLink={props.actions.adminResendUserConfirmLink}
              onUpdateUserAccountClick={setUpdatedUserAccount}
              onDeleteUserAccount={props.actions.adminDeleteAgentUserAccount}
            />

            <AdminUpdateUserForm
              open={userUpdateFormOpen}
              user={user}
              onFormSubmit={props.actions.adminUpdateUser}
              onFormCancel={() => setUserUpdateFormOpen(false)}
            />

            <AdminCreateUserAccountForm
              open={userAccountCreateFormOpen}
              user={user}
              onFormSubmit={props.actions.adminAddAgentUserAccount}
              onFormCancel={() => setUserAccountCreateFormOpen(false)}
            />

            <AdminUpdateUserAccountForm
              open={!!updatedUserAccount}
              user={user}
              account={updatedUserAccount}
              onFormSubmit={props.actions.adminUpdateAgentUserAccount}
              onFormCancel={() => setUpdatedUserAccount(undefined)}
            />

            <ActionButton
              icon="plus"
              label={t("user.actions.addAccount")}
              className="margin-top-small"
              onClick={() => setUserAccountCreateFormOpen(true)}
            />
          </Card>
        )}
      </DisplayWrapper>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  user: selectAdminUserDetail(state)
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      adminGetUser: adminGetUserActions.request,
      adminUpdateUser: adminUpdateUserActions.request,
      adminAddAgentUserAccount: adminAddAgentUserAccountActions.request,
      adminUpdateAgentUserAccount: adminUpdateAgentUserAccountActions.request,
      adminDeleteAgentUserAccount: adminDeleteAgentUserAccountActions.request,
      adminResendUserConfirmLink: adminResendUserConfirmLinkActions.request,
      deleteStateAdminUserDetail: deleteStateAdminUserDetailAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(AdminUserDetailContainer);
