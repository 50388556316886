import { AFFILIATE_PARTNER_ROUTE_PATHS } from "../../../modules/affiliate/paths";
import { AGENT_ROUTE_PATHS, AGENT_ROUTE_PREFIX } from "../../../modules/agent/paths";
import { CALC_BLACKLIST_ROUTE_PATHS } from "../../../modules/calculator/blacklist/paths";
import { CALC_ROUTE_PATHS, CALC_ROUTE_PREFIX } from "../../../modules/calculator/paths";
import { ADMIN_CALC_ROUTE_PATHS } from "../../../modules/calculator/settings/paths";
import { CLIENT_ROUTE_PATHS, CLIENT_ROUTE_PREFIX } from "../../../modules/client/paths";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "../../../modules/commissions/batch/paths";
import { COMMISSIONS_LEVEL_ROUTE_PATHS } from "../../../modules/commissions/level/paths";
import { POSTPONED_COMMISSIONS_ROUTE_PATHS } from "../../../modules/commissions/postponed/paths";
import { COMPLICITY_ROUTE_PATHS } from "../../../modules/complicity/paths";
import { CONTRACT_ROUTE_PATHS, CONTRACT_ROUTE_PREFIX } from "../../../modules/contract/paths";
import { CONTRACT_UPDATE_ROUTE_PATHS } from "../../../modules/contractupdate/paths";
import { COVERAGE_LIMIT_ROUTE_PATHS } from "../../../modules/coveragelimit/paths";
import { DASHBOARD_ROUTE_PATHS } from "../../../modules/dashboard/paths";
import { CONFIG_PROPERTY_ROUTE_PATHS } from "../../../modules/dynamicconfig/paths";
import { FINANCIAL_MEDIATION_ROUTE_PATHS } from "../../../modules/financialmediation/paths";
import { INSTITUTION_ROUTE_PATHS } from "../../../modules/institution/paths";
import { JOB_ROUTE_PATHS } from "../../../modules/jobs/paths";
import { LIFE_INSURENCE_TARIFF_ROUTE_PATHS } from "../../../modules/lifeinsurancetariff/paths";
import { PARTNER_ROUTE_PATHS } from "../../../modules/partner/paths";
import { PRODUCT_ROUTE_PATHS } from "../../../modules/product/paths";
import { ADMIN_USER_ROUTE_PATHS, CURRENT_USER_ROUTE_PATHS } from "../../../modules/user/paths";
import { VEHICLE_BRAND_ROUTE_PATHS } from "../../../modules/vehicle/brand/paths";
import { VEHICLE_ENUM_ROUTE_PATHS } from "../../../modules/vehicle/paths";
import { PagePermissionsRule } from "../../types";

export const DIVIDER_KEY = "-divider";
export const CONTRACT_REPORTS_DIVIDER_KEY = "/contract-reports" + DIVIDER_KEY;
export const CONTRACT_FORMS_DIVIDER_KEY = "/contract-forms" + DIVIDER_KEY;
export const AGENT_REPORTS_DIVIDER_KEY = "/agent-reports" + DIVIDER_KEY;
export const COMMISSIONS_SETTINGS_DIVIDER_KEY = "/commissions-settings" + DIVIDER_KEY;

export const SUBMENU_KEY = "-submenu";
export const COMMISSIONS_SUBMENU_KEY = "/commissions" + SUBMENU_KEY;
export const ADMIN_SUBMENU_KEY = "/admin" + SUBMENU_KEY;
export const SETTINGS_SUBMENU_KEY = "/settings" + SUBMENU_KEY;

export enum PermissionsCategory {
  PROFILE = "PROFILE_CAT",
  CLIENTS = "CLIENTS_CAT",
  INSURANCES = "INSURANCES_CAT",
  LOANS = "LOANS_CAT",
  INVESTMENTS = "INVESTMENTS_CAT",
  DEPOSITS = "DEPOSITS_CAT",
  SECOND_PILLARS = "SECOND_PILLARS_CAT",
  THIRD_PILLARS = "THIRD_PILLARS_CAT",
  GENERICS = "GENERICS_CAT",
  CONTRACTS = "CONTRACTS_CAT",
  CALCULATORS = "CALCULATORS_CAT",
  PARTNERS = "PARTNERS_CAT",
  AGENTS = "AGENTS_CAT",
  COMMISSIONS = "COMMISSIONS_CAT",
  ADMIN = "ADMIN_CAT",
  SYSTEM = "SYSTEM_CAT"
}

export enum Feature {
  INSURANCES = "INSURANCES",
  LOANS = "LOANS",
  INVESTMENTS = "INVESTMENTS",
  DEPOSITS = "DEPOSITS",
  SECOND_PILLARS = "SECOND_PILLARS",
  THIRD_PILLARS = "THIRD_PILLARS",
  GENERICS = "GENERICS",
  MTPL_CALCULATORS = "MTPL_CALCULATORS",
  CRASH_CALCULATORS = "CRASH_CALCULATORS",
  REALTY_CALCULATORS = "REALTY_CALCULATORS",
  TRAVEL_CALCULATORS = "TRAVEL_CALCULATORS",
  CLIENT_ACCESS = "CLIENT_ACCESS",
  PARTNERS = "PARTNERS",
  AGENTS = "AGENTS",
  COMMISSIONS = "COMMISSIONS"
}

export enum Permission {
  PROFILE_COMMISSIONS = "PROFILE_COMMISSIONS",
  PROFILE_COMPETENCE = "PROFILE_COMPETENCE",
  PROFILE_LICENSE = "PROFILE_LICENSE",
  PROFILE_BROKER_NUMBER = "PROFILE_BROKER_NUMBER",

  CLIENT_READ = "CLIENT_READ",
  CLIENT_CREATE = "CLIENT_CREATE",
  CLIENT_UPDATE = "CLIENT_UPDATE",
  CLIENT_DELETE = "CLIENT_DELETE",
  CLIENT_ACCOUNTS = "CLIENT_ACCOUNTS",
  CLIENT_IMPORT = "CLIENT_IMPORT",
  CLIENT_EXPORT = "CLIENT_EXPORT",

  INSURANCE_READ = "INSURANCE_READ",
  INSURANCE_CREATE = "INSURANCE_CREATE",
  INSURANCE_UPDATE = "INSURANCE_UPDATE",
  INSURANCE_DELETE = "INSURANCE_DELETE",

  INSURANCE_VERIFICATION_STATUS_CHANGE = "INSURANCE_VERIFICATION_STATUS_CHANGE",
  PRIVILEGED_CHANGES_ON_VERIFIED_INSURANCE = "PRIVILEGED_CHANGES_ON_VERIFIED_INSURANCE",

  LOAN_READ = "LOAN_READ",
  LOAN_CREATE = "LOAN_CREATE",
  LOAN_UPDATE = "LOAN_UPDATE",
  LOAN_DELETE = "LOAN_DELETE",

  LOAN_VERIFICATION_STATUS_CHANGE = "LOAN_VERIFICATION_STATUS_CHANGE",
  PRIVILEGED_CHANGES_ON_VERIFIED_LOAN = "PRIVILEGED_CHANGES_ON_VERIFIED_LOAN",

  INVESTMENT_READ = "INVESTMENT_READ",
  INVESTMENT_CREATE = "INVESTMENT_CREATE",
  INVESTMENT_UPDATE = "INVESTMENT_UPDATE",
  INVESTMENT_DELETE = "INVESTMENT_DELETE",

  INVESTMENT_VERIFICATION_STATUS_CHANGE = "INVESTMENT_VERIFICATION_STATUS_CHANGE",
  PRIVILEGED_CHANGES_ON_VERIFIED_INVESTMENT = "PRIVILEGED_CHANGES_ON_VERIFIED_INVESTMENT",

  DEPOSIT_READ = "DEPOSIT_READ",
  DEPOSIT_CREATE = "DEPOSIT_CREATE",
  DEPOSIT_UPDATE = "DEPOSIT_UPDATE",
  DEPOSIT_DELETE = "DEPOSIT_DELETE",

  DEPOSIT_VERIFICATION_STATUS_CHANGE = "DEPOSIT_VERIFICATION_STATUS_CHANGE",
  PRIVILEGED_CHANGES_ON_VERIFIED_DEPOSIT = "PRIVILEGED_CHANGES_ON_VERIFIED_DEPOSIT",

  SECOND_PILLAR_READ = "SECOND_PILLAR_READ",
  SECOND_PILLAR_CREATE = "SECOND_PILLAR_CREATE",
  SECOND_PILLAR_UPDATE = "SECOND_PILLAR_UPDATE",
  SECOND_PILLAR_DELETE = "SECOND_PILLAR_DELETE",

  SECOND_PILLAR_VERIFICATION_STATUS_CHANGE = "SECOND_PILLAR_VERIFICATION_STATUS_CHANGE",
  PRIVILEGED_CHANGES_ON_VERIFIED_SECOND_PILLAR = "PRIVILEGED_CHANGES_ON_VERIFIED_SECOND_PILLAR",

  THIRD_PILLAR_READ = "THIRD_PILLAR_READ",
  THIRD_PILLAR_CREATE = "THIRD_PILLAR_CREATE",
  THIRD_PILLAR_UPDATE = "THIRD_PILLAR_UPDATE",
  THIRD_PILLAR_DELETE = "THIRD_PILLAR_DELETE",

  THIRD_PILLAR_VERIFICATION_STATUS_CHANGE = "THIRD_PILLAR_VERIFICATION_STATUS_CHANGE",
  PRIVILEGED_CHANGES_ON_VERIFIED_THIRD_PILLAR = "PRIVILEGED_CHANGES_ON_VERIFIED_THIRD_PILLAR",

  GENERIC_READ = "GENERIC_READ",
  GENERIC_CREATE = "GENERIC_CREATE",
  GENERIC_UPDATE = "GENERIC_UPDATE",
  GENERIC_DELETE = "GENERIC_DELETE",

  GENERIC_VERIFICATION_STATUS_CHANGE = "GENERIC_VERIFICATION_STATUS_CHANGE",
  PRIVILEGED_CHANGES_ON_VERIFIED_GENERIC = "PRIVILEGED_CHANGES_ON_VERIFIED_GENERIC",

  CONTRACT_IMPORT = "CONTRACT_IMPORT",
  CONTRACT_EXPORT = "CONTRACT_EXPORT",

  MTPL_CALCULATE = "MTPL_CALCULATE",
  MTPL_GENERATE_CONTRACT = "MTPL_GENERATE_CONTRACT",

  CRASH_CALCULATE = "CRASH_CALCULATE",
  CRASH_GENERATE_CONTRACT = "CRASH_GENERATE_CONTRACT",

  REALTY_CALCULATE = "REALTY_CALCULATE",
  REALTY_GENERATE_CONTRACT = "REALTY_GENERATE_CONTRACT",

  TRAVEL_CALCULATE = "TRAVEL_CALCULATE",
  TRAVEL_GENERATE_CONTRACT = "TRAVEL_GENERATE_CONTRACT",

  CARD_READER = "CARD_READER",

  PARTNER_INSURANCE_COMPANIES = "PARTNER_INSURANCE_COMPANIES",
  PARTNER_BANKS = "PARTNER_BANKS",
  PARTNER_SECURITIES_BROKERS = "PARTNER_SECURITIES_BROKERS",
  PARTNER_BUILDING_SAVINGS_BANKS = "PARTNER_BUILDING_SAVINGS_BANKS",
  PARTNER_MANAGERIAL_PENSION_COMPANIES = "PARTNER_MANAGERIAL_PENSION_COMPANIES",
  PARTNER_SUPPLEMENTARY_PENSION_COMPANIES = "PARTNER_SUPPLEMENTARY_PENSION_COMPANIES",
  PARTNER_OTHERS = "PARTNER_OTHERS",

  AGENT_READ = "AGENT_READ",
  AGENT_CREATE = "AGENT_CREATE",
  AGENT_UPDATE = "AGENT_UPDATE",
  AGENT_DELETE = "AGENT_DELETE",
  AGENT_EXPORT = "AGENT_EXPORT",

  AGENT_COMMISSIONS = "AGENT_COMMISSIONS",
  AGENT_COMMISSIONS_SETTINGS = "AGENT_COMMISSIONS_SETTINGS",
  AGENT_COMMISSIONS_SETTINGS_MANAGE = "AGENT_COMMISSIONS_SETTINGS_MANAGE",

  AGENT_COMPETENCE = "AGENT_COMPETENCE",
  AGENT_COMPETENCE_MANAGE = "AGENT_COMPETENCE_MANAGE",
  AGENT_LICENSE = "AGENT_LICENSE",
  AGENT_LICENSE_MANAGE = "AGENT_LICENSE_MANAGE",
  AGENT_BROKER_NUMBER = "AGENT_BROKER_NUMBER",
  AGENT_BROKER_NUMBER_MANAGE = "AGENT_BROKER_NUMBER_MANAGE",

  COMMISSIONS = "COMMISSIONS",
  COMMISSIONS_MANAGE = "COMMISSIONS_MANAGE",
  POSTPONED_COMMISSIONS = "POSTPONED_COMMISSIONS",
  TOP_AGENT_COMMISSIONS = "TOP_AGENT_COMMISSIONS",
  TOP_AGENT_COMMISSIONS_MANAGE = "TOP_AGENT_COMMISSIONS_MANAGE",

  ADMIN_DASHBOARD = "ADMIN_DASHBOARD",
  ADMIN_USERS = "ADMIN_USERS",
  ADMIN_ENUMERATIONS = "ADMIN_ENUMERATIONS",
  ADMIN_DOCUMENT_NODES = "ADMIN_DOCUMENT_NODES",
  ADMIN_MTPL_CALCULATORS = "ADMIN_MTPL_CALCULATORS",
  ADMIN_CRASH_CALCULATORS = "ADMIN_CRASH_CALCULATORS",
  ADMIN_REALTY_CALCULATORS = "ADMIN_REALTY_CALCULATORS",
  ADMIN_TRAVEL_CALCULATORS = "ADMIN_TRAVEL_CALCULATORS",
  ADMIN_PARTNER_CONFIGS = "ADMIN_PARTNER_CONFIGS",

  ADMIN_TOP_AGENTS = "ADMIN_TOP_AGENTS",
  ADMIN_GLOBAL_ENUMERATIONS = "ADMIN_GLOBAL_ENUMERATIONS",
  ADMIN_JOBS = "ADMIN_JOBS",
  ADMIN_SYSTEM_CONFIG = "ADMIN_SYSTEM_CONFIG"
}

export enum Role {
  AGENT = "AGENT",
  CLIENT = "CLIENT"
}

export const categoryPermissionsMap = new Map<PermissionsCategory, Permission[]>([
  [
    PermissionsCategory.PROFILE,
    [
      Permission.PROFILE_COMMISSIONS,
      Permission.PROFILE_COMPETENCE,
      Permission.PROFILE_LICENSE,
      Permission.PROFILE_BROKER_NUMBER
    ]
  ],
  [
    PermissionsCategory.CLIENTS,
    [
      Permission.CLIENT_READ,
      Permission.CLIENT_CREATE,
      Permission.CLIENT_UPDATE,
      Permission.CLIENT_DELETE,
      Permission.CLIENT_ACCOUNTS,
      Permission.CLIENT_IMPORT,
      Permission.CLIENT_EXPORT
    ]
  ],
  [
    PermissionsCategory.INSURANCES,
    [
      Permission.INSURANCE_READ,
      Permission.INSURANCE_CREATE,
      Permission.INSURANCE_UPDATE,
      Permission.INSURANCE_DELETE,
      Permission.INSURANCE_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_INSURANCE
    ]
  ],
  [
    PermissionsCategory.LOANS,
    [
      Permission.LOAN_READ,
      Permission.LOAN_CREATE,
      Permission.LOAN_UPDATE,
      Permission.LOAN_DELETE,
      Permission.LOAN_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_LOAN
    ]
  ],
  [
    PermissionsCategory.INVESTMENTS,
    [
      Permission.INVESTMENT_READ,
      Permission.INVESTMENT_CREATE,
      Permission.INVESTMENT_UPDATE,
      Permission.INVESTMENT_DELETE,
      Permission.INVESTMENT_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_INVESTMENT
    ]
  ],
  [
    PermissionsCategory.DEPOSITS,
    [
      Permission.DEPOSIT_READ,
      Permission.DEPOSIT_CREATE,
      Permission.DEPOSIT_UPDATE,
      Permission.DEPOSIT_DELETE,
      Permission.DEPOSIT_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_DEPOSIT
    ]
  ],
  [
    PermissionsCategory.SECOND_PILLARS,
    [
      Permission.SECOND_PILLAR_READ,
      Permission.SECOND_PILLAR_CREATE,
      Permission.SECOND_PILLAR_UPDATE,
      Permission.SECOND_PILLAR_DELETE,
      Permission.SECOND_PILLAR_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_SECOND_PILLAR
    ]
  ],
  [
    PermissionsCategory.THIRD_PILLARS,
    [
      Permission.THIRD_PILLAR_READ,
      Permission.THIRD_PILLAR_CREATE,
      Permission.THIRD_PILLAR_UPDATE,
      Permission.THIRD_PILLAR_DELETE,
      Permission.THIRD_PILLAR_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_THIRD_PILLAR
    ]
  ],
  [
    PermissionsCategory.GENERICS,
    [
      Permission.GENERIC_READ,
      Permission.GENERIC_CREATE,
      Permission.GENERIC_UPDATE,
      Permission.GENERIC_DELETE,
      Permission.GENERIC_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_GENERIC
    ]
  ],
  [PermissionsCategory.CONTRACTS, [Permission.CONTRACT_IMPORT, Permission.CONTRACT_EXPORT]],
  [
    PermissionsCategory.CALCULATORS,
    [
      Permission.MTPL_CALCULATE,
      Permission.MTPL_GENERATE_CONTRACT,
      Permission.CRASH_CALCULATE,
      Permission.CRASH_GENERATE_CONTRACT,
      Permission.REALTY_CALCULATE,
      Permission.REALTY_GENERATE_CONTRACT,
      Permission.TRAVEL_CALCULATE,
      Permission.TRAVEL_GENERATE_CONTRACT,
      Permission.CARD_READER
    ]
  ],
  [
    PermissionsCategory.PARTNERS,
    [
      Permission.PARTNER_INSURANCE_COMPANIES,
      Permission.PARTNER_BANKS,
      Permission.PARTNER_SECURITIES_BROKERS,
      Permission.PARTNER_BUILDING_SAVINGS_BANKS,
      Permission.PARTNER_MANAGERIAL_PENSION_COMPANIES,
      Permission.PARTNER_SUPPLEMENTARY_PENSION_COMPANIES,
      Permission.PARTNER_OTHERS
    ]
  ],
  [
    PermissionsCategory.AGENTS,
    [
      Permission.AGENT_READ,
      Permission.AGENT_CREATE,
      Permission.AGENT_UPDATE,
      Permission.AGENT_DELETE,
      Permission.AGENT_EXPORT,
      Permission.AGENT_COMPETENCE,
      Permission.AGENT_COMPETENCE_MANAGE,
      Permission.AGENT_LICENSE,
      Permission.AGENT_LICENSE_MANAGE,
      Permission.AGENT_BROKER_NUMBER,
      Permission.AGENT_BROKER_NUMBER_MANAGE,
      Permission.AGENT_COMMISSIONS,
      Permission.AGENT_COMMISSIONS_SETTINGS,
      Permission.AGENT_COMMISSIONS_SETTINGS_MANAGE
    ]
  ],
  [
    PermissionsCategory.COMMISSIONS,
    [
      Permission.COMMISSIONS,
      Permission.COMMISSIONS_MANAGE,
      Permission.POSTPONED_COMMISSIONS,
      Permission.TOP_AGENT_COMMISSIONS,
      Permission.TOP_AGENT_COMMISSIONS_MANAGE
    ]
  ],
  [
    PermissionsCategory.ADMIN,
    [
      Permission.ADMIN_DASHBOARD,
      Permission.ADMIN_USERS,
      Permission.ADMIN_ENUMERATIONS,
      Permission.ADMIN_DOCUMENT_NODES,
      Permission.ADMIN_MTPL_CALCULATORS,
      Permission.ADMIN_CRASH_CALCULATORS,
      Permission.ADMIN_REALTY_CALCULATORS,
      Permission.ADMIN_TRAVEL_CALCULATORS,
      Permission.ADMIN_PARTNER_CONFIGS
    ]
  ],
  [
    PermissionsCategory.SYSTEM,
    [
      Permission.ADMIN_TOP_AGENTS,
      Permission.ADMIN_GLOBAL_ENUMERATIONS,
      Permission.ADMIN_JOBS,
      Permission.ADMIN_SYSTEM_CONFIG
    ]
  ]
]);

export const systemAdminOnlyPermissions = [
  Permission.ADMIN_TOP_AGENTS,
  Permission.ADMIN_GLOBAL_ENUMERATIONS,
  Permission.ADMIN_JOBS,
  Permission.ADMIN_SYSTEM_CONFIG
];

export const featureAlwaysAllowedPermissions = [
  Permission.CLIENT_READ,
  Permission.CLIENT_CREATE,
  Permission.CLIENT_UPDATE,
  Permission.CLIENT_DELETE,
  Permission.CLIENT_IMPORT,
  Permission.CLIENT_EXPORT,
  Permission.CONTRACT_IMPORT,
  Permission.CONTRACT_EXPORT,
  Permission.AGENT_READ,
  Permission.AGENT_CREATE,
  Permission.AGENT_UPDATE,
  Permission.AGENT_DELETE,
  Permission.AGENT_EXPORT,
  Permission.ADMIN_DASHBOARD,
  Permission.ADMIN_USERS,
  Permission.ADMIN_ENUMERATIONS,
  Permission.ADMIN_DOCUMENT_NODES,
  Permission.ADMIN_TOP_AGENTS,
  Permission.ADMIN_GLOBAL_ENUMERATIONS,
  Permission.ADMIN_JOBS,
  Permission.ADMIN_SYSTEM_CONFIG
];

export const featurePermissionsMap = new Map<Feature, Permission[]>([
  [
    Feature.INSURANCES,
    [
      Permission.INSURANCE_READ,
      Permission.INSURANCE_CREATE,
      Permission.INSURANCE_UPDATE,
      Permission.INSURANCE_DELETE,
      Permission.INSURANCE_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_INSURANCE
    ]
  ],
  [
    Feature.LOANS,
    [
      Permission.LOAN_READ,
      Permission.LOAN_CREATE,
      Permission.LOAN_UPDATE,
      Permission.LOAN_DELETE,
      Permission.LOAN_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_LOAN
    ]
  ],
  [
    Feature.INVESTMENTS,
    [
      Permission.INVESTMENT_READ,
      Permission.INVESTMENT_CREATE,
      Permission.INVESTMENT_UPDATE,
      Permission.INVESTMENT_DELETE,
      Permission.INVESTMENT_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_INVESTMENT
    ]
  ],
  [
    Feature.DEPOSITS,
    [
      Permission.DEPOSIT_READ,
      Permission.DEPOSIT_CREATE,
      Permission.DEPOSIT_UPDATE,
      Permission.DEPOSIT_DELETE,
      Permission.DEPOSIT_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_DEPOSIT
    ]
  ],
  [
    Feature.SECOND_PILLARS,
    [
      Permission.SECOND_PILLAR_READ,
      Permission.SECOND_PILLAR_CREATE,
      Permission.SECOND_PILLAR_UPDATE,
      Permission.SECOND_PILLAR_DELETE,
      Permission.SECOND_PILLAR_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_SECOND_PILLAR
    ]
  ],
  [
    Feature.THIRD_PILLARS,
    [
      Permission.THIRD_PILLAR_READ,
      Permission.THIRD_PILLAR_CREATE,
      Permission.THIRD_PILLAR_UPDATE,
      Permission.THIRD_PILLAR_DELETE,
      Permission.THIRD_PILLAR_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_THIRD_PILLAR
    ]
  ],
  [
    Feature.GENERICS,
    [
      Permission.GENERIC_READ,
      Permission.GENERIC_CREATE,
      Permission.GENERIC_UPDATE,
      Permission.GENERIC_DELETE,
      Permission.GENERIC_VERIFICATION_STATUS_CHANGE,
      Permission.PRIVILEGED_CHANGES_ON_VERIFIED_GENERIC
    ]
  ],
  [
    Feature.MTPL_CALCULATORS,
    [
      Permission.MTPL_CALCULATE,
      Permission.MTPL_GENERATE_CONTRACT,
      Permission.CARD_READER,
      Permission.ADMIN_MTPL_CALCULATORS
    ]
  ],
  [
    Feature.CRASH_CALCULATORS,
    [
      Permission.CRASH_CALCULATE,
      Permission.CRASH_GENERATE_CONTRACT,
      Permission.CARD_READER,
      Permission.ADMIN_CRASH_CALCULATORS
    ]
  ],
  [
    Feature.REALTY_CALCULATORS,
    [Permission.REALTY_CALCULATE, Permission.REALTY_GENERATE_CONTRACT, Permission.ADMIN_REALTY_CALCULATORS]
  ],
  [
    Feature.TRAVEL_CALCULATORS,
    [Permission.TRAVEL_CALCULATE, Permission.TRAVEL_GENERATE_CONTRACT, Permission.ADMIN_TRAVEL_CALCULATORS]
  ],
  [Feature.CLIENT_ACCESS, [Permission.CLIENT_ACCOUNTS]],
  [
    Feature.PARTNERS,
    [
      Permission.PARTNER_INSURANCE_COMPANIES,
      Permission.PARTNER_BANKS,
      Permission.PARTNER_SECURITIES_BROKERS,
      Permission.PARTNER_BUILDING_SAVINGS_BANKS,
      Permission.PARTNER_MANAGERIAL_PENSION_COMPANIES,
      Permission.PARTNER_SUPPLEMENTARY_PENSION_COMPANIES,
      Permission.PARTNER_OTHERS,
      Permission.ADMIN_PARTNER_CONFIGS
    ]
  ],
  [
    Feature.AGENTS,
    [
      Permission.PROFILE_COMPETENCE,
      Permission.PROFILE_LICENSE,
      Permission.PROFILE_BROKER_NUMBER,
      Permission.AGENT_COMPETENCE,
      Permission.AGENT_COMPETENCE_MANAGE,
      Permission.AGENT_LICENSE,
      Permission.AGENT_LICENSE_MANAGE,
      Permission.AGENT_BROKER_NUMBER,
      Permission.AGENT_BROKER_NUMBER_MANAGE
    ]
  ],
  [
    Feature.COMMISSIONS,
    [
      Permission.PROFILE_COMMISSIONS,
      Permission.AGENT_COMMISSIONS,
      Permission.AGENT_COMMISSIONS_SETTINGS,
      Permission.AGENT_COMMISSIONS_SETTINGS_MANAGE,
      Permission.COMMISSIONS,
      Permission.COMMISSIONS_MANAGE,
      Permission.POSTPONED_COMMISSIONS,
      Permission.TOP_AGENT_COMMISSIONS,
      Permission.TOP_AGENT_COMMISSIONS_MANAGE
    ]
  ]
]);

export const permissionsPrerequisitesMap = new Map<Permission, Permission[]>([
  [Permission.PROFILE_COMMISSIONS, []],
  [Permission.PROFILE_COMPETENCE, []],
  [Permission.PROFILE_LICENSE, []],
  [Permission.PROFILE_BROKER_NUMBER, []],

  [Permission.CLIENT_READ, []],
  [Permission.CLIENT_CREATE, [Permission.CLIENT_READ]],
  [Permission.CLIENT_UPDATE, [Permission.CLIENT_READ]],
  [Permission.CLIENT_DELETE, [Permission.CLIENT_READ]],
  [Permission.CLIENT_ACCOUNTS, [Permission.CLIENT_READ]],
  [Permission.CLIENT_IMPORT, [Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.CLIENT_EXPORT, [Permission.CLIENT_READ]],

  [Permission.INSURANCE_READ, []],
  [Permission.INSURANCE_CREATE, [Permission.INSURANCE_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.INSURANCE_UPDATE, [Permission.INSURANCE_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.INSURANCE_DELETE, [Permission.INSURANCE_READ]],

  [Permission.INSURANCE_VERIFICATION_STATUS_CHANGE, [Permission.INSURANCE_UPDATE]],
  [Permission.PRIVILEGED_CHANGES_ON_VERIFIED_INSURANCE, [Permission.INSURANCE_UPDATE]],

  [Permission.LOAN_READ, []],
  [Permission.LOAN_CREATE, [Permission.LOAN_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.LOAN_UPDATE, [Permission.LOAN_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.LOAN_DELETE, [Permission.LOAN_READ]],

  [Permission.LOAN_VERIFICATION_STATUS_CHANGE, [Permission.LOAN_UPDATE]],
  [Permission.PRIVILEGED_CHANGES_ON_VERIFIED_LOAN, [Permission.LOAN_UPDATE]],

  [Permission.INVESTMENT_READ, []],
  [Permission.INVESTMENT_CREATE, [Permission.INVESTMENT_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.INVESTMENT_UPDATE, [Permission.INVESTMENT_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.INVESTMENT_DELETE, [Permission.INVESTMENT_READ]],

  [Permission.INVESTMENT_VERIFICATION_STATUS_CHANGE, [Permission.INVESTMENT_UPDATE]],
  [Permission.PRIVILEGED_CHANGES_ON_VERIFIED_INVESTMENT, [Permission.INVESTMENT_UPDATE]],

  [Permission.DEPOSIT_READ, []],
  [Permission.DEPOSIT_CREATE, [Permission.DEPOSIT_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.DEPOSIT_UPDATE, [Permission.DEPOSIT_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.DEPOSIT_DELETE, [Permission.DEPOSIT_READ]],

  [Permission.DEPOSIT_VERIFICATION_STATUS_CHANGE, [Permission.DEPOSIT_UPDATE]],
  [Permission.PRIVILEGED_CHANGES_ON_VERIFIED_DEPOSIT, [Permission.DEPOSIT_UPDATE]],

  [Permission.SECOND_PILLAR_READ, []],
  [
    Permission.SECOND_PILLAR_CREATE,
    [Permission.SECOND_PILLAR_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]
  ],
  [
    Permission.SECOND_PILLAR_UPDATE,
    [Permission.SECOND_PILLAR_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]
  ],
  [Permission.SECOND_PILLAR_DELETE, [Permission.SECOND_PILLAR_READ]],

  [Permission.SECOND_PILLAR_VERIFICATION_STATUS_CHANGE, [Permission.SECOND_PILLAR_UPDATE]],
  [Permission.PRIVILEGED_CHANGES_ON_VERIFIED_SECOND_PILLAR, [Permission.SECOND_PILLAR_UPDATE]],

  [Permission.THIRD_PILLAR_READ, []],
  [Permission.THIRD_PILLAR_CREATE, [Permission.THIRD_PILLAR_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.THIRD_PILLAR_UPDATE, [Permission.THIRD_PILLAR_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.THIRD_PILLAR_DELETE, [Permission.THIRD_PILLAR_READ]],

  [Permission.THIRD_PILLAR_VERIFICATION_STATUS_CHANGE, [Permission.THIRD_PILLAR_UPDATE]],
  [Permission.PRIVILEGED_CHANGES_ON_VERIFIED_THIRD_PILLAR, [Permission.THIRD_PILLAR_UPDATE]],

  [Permission.GENERIC_READ, []],
  [Permission.GENERIC_CREATE, [Permission.GENERIC_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.GENERIC_UPDATE, [Permission.GENERIC_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]],
  [Permission.GENERIC_DELETE, [Permission.GENERIC_READ]],

  [Permission.GENERIC_VERIFICATION_STATUS_CHANGE, [Permission.GENERIC_UPDATE]],
  [Permission.PRIVILEGED_CHANGES_ON_VERIFIED_GENERIC, [Permission.GENERIC_UPDATE]],

  [Permission.CONTRACT_IMPORT, []],
  [Permission.CONTRACT_EXPORT, []],

  [Permission.MTPL_CALCULATE, []],
  [
    Permission.MTPL_GENERATE_CONTRACT,
    [Permission.MTPL_CALCULATE, Permission.INSURANCE_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]
  ],
  [Permission.CRASH_CALCULATE, []],
  [
    Permission.CRASH_GENERATE_CONTRACT,
    [Permission.CRASH_CALCULATE, Permission.INSURANCE_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]
  ],
  [Permission.REALTY_CALCULATE, []],
  [
    Permission.REALTY_GENERATE_CONTRACT,
    [Permission.REALTY_CALCULATE, Permission.INSURANCE_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]
  ],
  [Permission.TRAVEL_CALCULATE, []],
  [
    Permission.TRAVEL_GENERATE_CONTRACT,
    [Permission.TRAVEL_CALCULATE, Permission.INSURANCE_READ, Permission.CLIENT_CREATE, Permission.CLIENT_UPDATE]
  ],
  [Permission.CARD_READER, []],

  [Permission.PARTNER_INSURANCE_COMPANIES, []],
  [Permission.PARTNER_BANKS, []],
  [Permission.PARTNER_SECURITIES_BROKERS, []],
  [Permission.PARTNER_BUILDING_SAVINGS_BANKS, []],
  [Permission.PARTNER_MANAGERIAL_PENSION_COMPANIES, []],
  [Permission.PARTNER_SUPPLEMENTARY_PENSION_COMPANIES, []],
  [Permission.PARTNER_OTHERS, []],

  [Permission.AGENT_READ, []],
  [Permission.AGENT_CREATE, [Permission.AGENT_READ]],
  [Permission.AGENT_UPDATE, [Permission.AGENT_READ]],
  [Permission.AGENT_DELETE, [Permission.AGENT_READ]],
  [Permission.AGENT_EXPORT, [Permission.AGENT_READ]],
  [Permission.AGENT_COMPETENCE, [Permission.AGENT_READ]],
  [Permission.AGENT_COMPETENCE_MANAGE, [Permission.AGENT_COMPETENCE]],
  [Permission.AGENT_LICENSE, [Permission.AGENT_READ]],
  [Permission.AGENT_LICENSE_MANAGE, [Permission.AGENT_LICENSE]],
  [Permission.AGENT_BROKER_NUMBER, [Permission.AGENT_READ]],
  [Permission.AGENT_BROKER_NUMBER_MANAGE, [Permission.AGENT_BROKER_NUMBER]],
  [Permission.AGENT_COMMISSIONS, [Permission.AGENT_READ]],
  [Permission.AGENT_COMMISSIONS_SETTINGS, [Permission.AGENT_COMMISSIONS]],
  [Permission.AGENT_COMMISSIONS_SETTINGS_MANAGE, [Permission.AGENT_COMMISSIONS_SETTINGS]],

  [Permission.COMMISSIONS, []],
  [Permission.COMMISSIONS_MANAGE, [Permission.COMMISSIONS]],
  [Permission.POSTPONED_COMMISSIONS, []],
  [Permission.TOP_AGENT_COMMISSIONS, []],
  [Permission.TOP_AGENT_COMMISSIONS_MANAGE, [Permission.TOP_AGENT_COMMISSIONS]],

  [Permission.ADMIN_DASHBOARD, []],
  [Permission.ADMIN_USERS, []],
  [Permission.ADMIN_ENUMERATIONS, []],
  [Permission.ADMIN_DOCUMENT_NODES, []],
  [Permission.ADMIN_MTPL_CALCULATORS, []],
  [Permission.ADMIN_CRASH_CALCULATORS, []],
  [Permission.ADMIN_REALTY_CALCULATORS, []],
  [Permission.ADMIN_TRAVEL_CALCULATORS, []],
  [
    Permission.ADMIN_PARTNER_CONFIGS,
    [
      Permission.PARTNER_INSURANCE_COMPANIES,
      Permission.PARTNER_BANKS,
      Permission.PARTNER_SECURITIES_BROKERS,
      Permission.PARTNER_BUILDING_SAVINGS_BANKS,
      Permission.PARTNER_MANAGERIAL_PENSION_COMPANIES,
      Permission.PARTNER_SUPPLEMENTARY_PENSION_COMPANIES,
      Permission.PARTNER_OTHERS
    ]
  ],

  [Permission.ADMIN_TOP_AGENTS, [Permission.AGENT_READ]],
  [Permission.ADMIN_GLOBAL_ENUMERATIONS, []],
  [Permission.ADMIN_JOBS, []],
  [Permission.ADMIN_SYSTEM_CONFIG, []]
]);

export const pageRulePermissionsMap = new Map<string, PagePermissionsRule>([
  [
    DASHBOARD_ROUTE_PATHS.homepage.to,
    {
      permissions: []
    }
  ],
  [
    CURRENT_USER_ROUTE_PATHS.commissions.to,
    {
      permissions: [Permission.PROFILE_COMMISSIONS]
    }
  ],
  [
    CALC_ROUTE_PREFIX + SUBMENU_KEY,
    {
      isAnyPermissionRequired: true,
      permissions: [
        Permission.MTPL_CALCULATE,
        Permission.CRASH_CALCULATE,
        Permission.REALTY_CALCULATE,
        Permission.TRAVEL_CALCULATE
      ]
    }
  ],
  [
    CALC_ROUTE_PATHS.vehicle.to,
    {
      isAnyPermissionRequired: true,
      permissions: [Permission.MTPL_CALCULATE, Permission.CRASH_CALCULATE]
    }
  ],
  [
    CALC_ROUTE_PATHS.vehicleDraft.to,
    {
      isAnyPermissionRequired: true,
      permissions: [Permission.MTPL_CALCULATE, Permission.CRASH_CALCULATE]
    }
  ],
  [
    CALC_ROUTE_PATHS.travel.to,
    {
      permissions: [Permission.TRAVEL_CALCULATE]
    }
  ],
  [
    CALC_ROUTE_PATHS.travelDraft.to,
    {
      permissions: [Permission.TRAVEL_CALCULATE]
    }
  ],
  [
    CALC_ROUTE_PATHS.realty.to,
    {
      permissions: [Permission.REALTY_CALCULATE]
    }
  ],
  [
    CALC_ROUTE_PATHS.realtyDraft.to,
    {
      permissions: [Permission.REALTY_CALCULATE]
    }
  ],
  [
    CALC_ROUTE_PATHS.records.to,
    {
      isAnyPermissionRequired: true,
      permissions: [
        Permission.ADMIN_MTPL_CALCULATORS,
        Permission.ADMIN_CRASH_CALCULATORS,
        Permission.ADMIN_REALTY_CALCULATORS,
        Permission.ADMIN_TRAVEL_CALCULATORS
      ]
    }
  ],
  [
    PARTNER_ROUTE_PATHS.configs.to,
    {
      isAnyPermissionRequired: true,
      permissions: [
        Permission.PARTNER_INSURANCE_COMPANIES,
        Permission.PARTNER_BANKS,
        Permission.PARTNER_SECURITIES_BROKERS,
        Permission.PARTNER_BUILDING_SAVINGS_BANKS,
        Permission.PARTNER_MANAGERIAL_PENSION_COMPANIES,
        Permission.PARTNER_SUPPLEMENTARY_PENSION_COMPANIES,
        Permission.PARTNER_OTHERS
      ]
    }
  ],
  [
    CLIENT_ROUTE_PREFIX + SUBMENU_KEY,
    {
      permissions: [Permission.CLIENT_READ]
    }
  ],
  [
    CLIENT_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.CLIENT_READ]
    }
  ],
  [
    CLIENT_ROUTE_PATHS.create.to,
    {
      permissions: [Permission.CLIENT_CREATE]
    }
  ],
  [
    CONTRACT_ROUTE_PREFIX + SUBMENU_KEY,
    {
      isAnyPermissionRequired: true,
      permissions: [
        Permission.INSURANCE_READ,
        Permission.LOAN_READ,
        Permission.INVESTMENT_READ,
        Permission.DEPOSIT_READ,
        Permission.SECOND_PILLAR_READ,
        Permission.THIRD_PILLAR_READ,
        Permission.GENERIC_READ
      ]
    }
  ],
  [
    CONTRACT_ROUTE_PATHS.list.to,
    {
      isAnyPermissionRequired: true,
      permissions: [
        Permission.INSURANCE_READ,
        Permission.LOAN_READ,
        Permission.INVESTMENT_READ,
        Permission.DEPOSIT_READ,
        Permission.SECOND_PILLAR_READ,
        Permission.THIRD_PILLAR_READ,
        Permission.GENERIC_READ
      ]
    }
  ],
  [
    CONTRACT_ROUTE_PATHS.create.to,
    {
      isAnyPermissionRequired: true,
      permissions: [
        Permission.INSURANCE_CREATE,
        Permission.LOAN_CREATE,
        Permission.INVESTMENT_CREATE,
        Permission.DEPOSIT_CREATE,
        Permission.SECOND_PILLAR_CREATE,
        Permission.THIRD_PILLAR_CREATE,
        Permission.GENERIC_CREATE
      ]
    }
  ],
  [
    CONTRACT_REPORTS_DIVIDER_KEY,
    {
      isAnyPermissionRequired: true,
      permissions: [
        Permission.INSURANCE_READ,
        Permission.LOAN_READ,
        Permission.INVESTMENT_READ,
        Permission.DEPOSIT_READ,
        Permission.SECOND_PILLAR_READ,
        Permission.THIRD_PILLAR_READ,
        Permission.GENERIC_READ
      ]
    }
  ],
  [
    CONTRACT_ROUTE_PATHS.predictedCommissions.to,
    {
      isAnyPermissionRequired: true,
      permissions: [
        Permission.INSURANCE_READ,
        Permission.LOAN_READ,
        Permission.INVESTMENT_READ,
        Permission.DEPOSIT_READ,
        Permission.SECOND_PILLAR_READ,
        Permission.THIRD_PILLAR_READ,
        Permission.GENERIC_READ
      ]
    }
  ],
  [
    CONTRACT_ROUTE_PATHS.anniversaryDate.to,
    {
      isAnyPermissionRequired: true,
      permissions: [Permission.INSURANCE_READ, Permission.LOAN_READ]
    }
  ],
  [
    CONTRACT_ROUTE_PATHS.unpaid.to,
    {
      permissions: [Permission.INSURANCE_READ]
    }
  ],
  [
    CONTRACT_FORMS_DIVIDER_KEY,
    {
      permissions: [Permission.INSURANCE_READ]
    }
  ],
  [
    CONTRACT_ROUTE_PATHS.forms.to,
    {
      isAnyPermissionRequired: true,
      permissions: [
        Permission.INSURANCE_READ,
        Permission.LOAN_READ,
        Permission.DEPOSIT_READ,
        Permission.SECOND_PILLAR_READ,
        Permission.THIRD_PILLAR_READ
      ]
    }
  ],
  [
    FINANCIAL_MEDIATION_ROUTE_PATHS.create.to,
    {
      isAnyPermissionRequired: true,
      permissions: [
        Permission.INSURANCE_CREATE,
        Permission.LOAN_CREATE,
        Permission.DEPOSIT_CREATE,
        Permission.SECOND_PILLAR_CREATE,
        Permission.THIRD_PILLAR_CREATE
      ]
    }
  ],
  [
    AGENT_ROUTE_PREFIX + SUBMENU_KEY,
    {
      permissions: [Permission.AGENT_READ]
    }
  ],
  [
    AGENT_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.AGENT_READ]
    }
  ],
  [
    AGENT_ROUTE_PATHS.tree.to,
    {
      permissions: [Permission.AGENT_READ]
    }
  ],
  [
    AGENT_ROUTE_PATHS.create.to,
    {
      permissions: [Permission.AGENT_CREATE]
    }
  ],
  [
    AGENT_REPORTS_DIVIDER_KEY,
    {
      isAnyPermissionRequired: true,
      permissions: [Permission.AGENT_COMMISSIONS, Permission.AGENT_COMPETENCE]
    }
  ],
  [
    AGENT_ROUTE_PATHS.endingCompetences.to,
    {
      permissions: [Permission.AGENT_COMPETENCE]
    }
  ],
  [
    AGENT_ROUTE_PATHS.pointsReport.to,
    {
      permissions: [Permission.AGENT_COMMISSIONS]
    }
  ],
  [
    AGENT_ROUTE_PATHS.bailAccountsReport.to,
    {
      permissions: [Permission.AGENT_COMMISSIONS]
    }
  ],
  [
    AGENT_ROUTE_PATHS.debtAccountsReport.to,
    {
      permissions: [Permission.AGENT_COMMISSIONS]
    }
  ],
  [
    COMMISSIONS_SUBMENU_KEY,
    {
      isAnyPermissionRequired: true,
      permissions: [Permission.COMMISSIONS, Permission.POSTPONED_COMMISSIONS, Permission.TOP_AGENT_COMMISSIONS]
    }
  ],
  [
    COMMISSIONS_BATCH_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.COMMISSIONS]
    }
  ],
  [
    POSTPONED_COMMISSIONS_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.POSTPONED_COMMISSIONS]
    }
  ],
  [
    COMMISSIONS_SETTINGS_DIVIDER_KEY,
    {
      permissions: [Permission.TOP_AGENT_COMMISSIONS]
    }
  ],
  [
    COMMISSIONS_LEVEL_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.TOP_AGENT_COMMISSIONS]
    }
  ],
  [
    ADMIN_SUBMENU_KEY,
    {
      isAnyPermissionRequired: true,
      permissions: [
        Permission.ADMIN_DASHBOARD,
        Permission.ADMIN_USERS,
        Permission.ADMIN_ENUMERATIONS,
        Permission.ADMIN_DOCUMENT_NODES,
        Permission.ADMIN_MTPL_CALCULATORS,
        Permission.ADMIN_CRASH_CALCULATORS,
        Permission.ADMIN_REALTY_CALCULATORS,
        Permission.ADMIN_TRAVEL_CALCULATORS,
        Permission.CLIENT_IMPORT,
        Permission.CONTRACT_IMPORT
      ]
    }
  ],
  [
    ADMIN_USER_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.ADMIN_USERS]
    }
  ],
  [
    ADMIN_USER_ROUTE_PATHS.create.to,
    {
      permissions: [Permission.ADMIN_USERS]
    }
  ],
  [
    INSTITUTION_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.ADMIN_ENUMERATIONS]
    }
  ],
  [
    INSTITUTION_ROUTE_PATHS.create.to,
    {
      permissions: [Permission.ADMIN_GLOBAL_ENUMERATIONS]
    }
  ],
  [
    PRODUCT_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.ADMIN_ENUMERATIONS]
    }
  ],
  [
    AFFILIATE_PARTNER_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.ADMIN_ENUMERATIONS]
    }
  ],
  [
    ADMIN_CALC_ROUTE_PATHS.list.to,
    {
      isAnyPermissionRequired: true,
      permissions: [
        Permission.ADMIN_MTPL_CALCULATORS,
        Permission.ADMIN_CRASH_CALCULATORS,
        Permission.ADMIN_REALTY_CALCULATORS,
        Permission.ADMIN_TRAVEL_CALCULATORS
      ]
    }
  ],
  [
    CONTRACT_UPDATE_ROUTE_PATHS.list.to,
    {
      isAnyPermissionRequired: true,
      permissions: [Permission.CLIENT_IMPORT, Permission.CONTRACT_IMPORT]
    }
  ],
  [
    ADMIN_CALC_ROUTE_PATHS.create.to,
    {
      isAnyPermissionRequired: true,
      permissions: [
        Permission.ADMIN_MTPL_CALCULATORS,
        Permission.ADMIN_CRASH_CALCULATORS,
        Permission.ADMIN_REALTY_CALCULATORS,
        Permission.ADMIN_TRAVEL_CALCULATORS
      ]
    }
  ],
  [
    SETTINGS_SUBMENU_KEY,
    {
      isAnyPermissionRequired: true,
      permissions: [Permission.ADMIN_GLOBAL_ENUMERATIONS, Permission.ADMIN_JOBS, Permission.ADMIN_SYSTEM_CONFIG]
    }
  ],
  [
    LIFE_INSURENCE_TARIFF_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.ADMIN_GLOBAL_ENUMERATIONS]
    }
  ],
  [
    COVERAGE_LIMIT_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.ADMIN_GLOBAL_ENUMERATIONS]
    }
  ],
  [
    COMPLICITY_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.ADMIN_GLOBAL_ENUMERATIONS]
    }
  ],
  [
    VEHICLE_BRAND_ROUTE_PATHS.brands.to,
    {
      permissions: [Permission.ADMIN_GLOBAL_ENUMERATIONS]
    }
  ],
  [
    VEHICLE_ENUM_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.ADMIN_GLOBAL_ENUMERATIONS]
    }
  ],
  [
    JOB_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.ADMIN_JOBS]
    }
  ],
  [
    CALC_BLACKLIST_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.ADMIN_GLOBAL_ENUMERATIONS]
    }
  ],
  [
    CONFIG_PROPERTY_ROUTE_PATHS.list.to,
    {
      permissions: [Permission.ADMIN_SYSTEM_CONFIG]
    }
  ]
]);
