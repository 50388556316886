import { ATTACHMENT_BOUNDARY_PATH_PREFIX } from "../../common/constants";

export const CALC_ROUTE_PREFIX = "/calc";

export const CALC_ROUTE_PATHS = {
  vehicle: { path: "vehicle", to: `${CALC_ROUTE_PREFIX}/vehicle` },
  realty: { path: "realty", to: `${CALC_ROUTE_PREFIX}/realty` },
  travel: { path: "travel", to: `${CALC_ROUTE_PREFIX}/travel` },
  vehicleDraft: { path: "vehicle/drafts", to: `${CALC_ROUTE_PREFIX}/vehicle/drafts` },
  realtyDraft: { path: "realty/drafts", to: `${CALC_ROUTE_PREFIX}/realty/drafts` },
  travelDraft: { path: "travel/drafts", to: `${CALC_ROUTE_PREFIX}/travel/drafts` },
  records: { path: "records", to: `${CALC_ROUTE_PREFIX}/records` },
  attachment: { to: `${ATTACHMENT_BOUNDARY_PATH_PREFIX}${CALC_ROUTE_PREFIX}/drafts/:id1/attachments/:id2` }
};
