import { Col, Form, Select } from "antd";
import classNames from "classnames";
import t from "../../../../../app/i18n";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import { selectStandardProps } from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { QUESTIONNAIRE_ANSWERS_COUNT_MAP, QUESTIONNAIRE_MULTIPLE_ANSWERS_QUESTIONS_NUMBERS } from "../../../utils";

interface Props {
  index: number;
  questionNumber: number;
  required: boolean;
  validateAllFields: boolean;
}

const FinancialMediationQuestionnaireSelect = ({ index, questionNumber, required, validateAllFields }: Props) => {
  const multipleAnswersPossible = QUESTIONNAIRE_MULTIPLE_ANSWERS_QUESTIONS_NUMBERS.includes(questionNumber);
  const label = `${index + 1}. ${t(`financialMediation.helpers.questionnaire.q${questionNumber}.label`)}`;

  return (
    <Col span={8}>
      <Form.Item
        name={["mediationData", "investmentQuestionnaireData", "answers", index]}
        label={
          <span className={classNames({ "form-item-additional": required })}>
            {multipleAnswersPossible ? (
              <LabelWithTooltip label={label} tooltip={t("financialMediation.helpers.questionnaire.multipleAnswers")} />
            ) : (
              label
            )}
          </span>
        }
        rules={[validations.conditionalRule(validateAllFields && required, validations.notNull)]}
      >
        <Select
          {...selectStandardProps}
          allowClear={!required}
          mode={multipleAnswersPossible ? "multiple" : undefined}
          maxTagCount={multipleAnswersPossible ? "responsive" : undefined}
          options={[...Array(QUESTIONNAIRE_ANSWERS_COUNT_MAP.get(questionNumber))].map((_, index) => ({
            value: index,
            label: t(`financialMediation.helpers.questionnaire.q${questionNumber}.a${index + 1}`)
          }))}
        />
      </Form.Item>
    </Col>
  );
};

export default FinancialMediationQuestionnaireSelect;
