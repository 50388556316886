import { ConfigProvider, Menu } from "antd";
import { ItemType, MenuDividerType, MenuItemType } from "antd/es/menu/interface";
import classNames from "classnames";
import { Location } from "history";
import { MenuInfo } from "rc-menu/lib/interface";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AntIcon from "../../../common/components/icons/AntIcon";
import { ViewBreakpoints } from "../../../common/constants";
import {
  ADMIN_SUBMENU_KEY,
  AGENT_REPORTS_DIVIDER_KEY,
  categoryPermissionsMap,
  COMMISSIONS_SETTINGS_DIVIDER_KEY,
  COMMISSIONS_SUBMENU_KEY,
  CONTRACT_FORMS_DIVIDER_KEY,
  CONTRACT_REPORTS_DIVIDER_KEY,
  Permission,
  PermissionsCategory,
  SETTINGS_SUBMENU_KEY,
  SUBMENU_KEY
} from "../../../common/security/authorization/enums";
import { MenuDividerProps, RootState } from "../../../common/types";
import { useWindowSize } from "../../../common/utils/hooksUtils";
import { checkHasPathAccess, createMenuItem, createSubMenuItem } from "../../../common/utils/menuUtils";
import { cssVariables, parseFirstPartOfPathname } from "../../../common/utils/utils";
import { AFFILIATE_PARTNER_ROUTE_PATHS } from "../../../modules/affiliate/paths";
import { AGENT_ROUTE_PATHS, AGENT_ROUTE_PREFIX } from "../../../modules/agent/paths";
import { selectHasAnyPermissions, selectPermissions } from "../../../modules/auth/ducks";
import CreateUserBugReportForm from "../../../modules/bugreport/components/forms/CreateUserBugReportForm";
import { CALC_BLACKLIST_ROUTE_PATHS } from "../../../modules/calculator/blacklist/paths";
import { CALC_ROUTE_PATHS, CALC_ROUTE_PREFIX } from "../../../modules/calculator/paths";
import { ADMIN_CALC_ROUTE_PATHS } from "../../../modules/calculator/settings/paths";
import { CLIENT_ROUTE_PATHS, CLIENT_ROUTE_PREFIX } from "../../../modules/client/paths";
import { COMMISSIONS_BATCH_ROUTE_PATHS } from "../../../modules/commissions/batch/paths";
import { COMMISSIONS_LEVEL_ROUTE_PATHS } from "../../../modules/commissions/level/paths";
import { POSTPONED_COMMISSIONS_ROUTE_PATHS } from "../../../modules/commissions/postponed/paths";
import { COMPLICITY_ROUTE_PATHS } from "../../../modules/complicity/paths";
import { CONTRACT_ROUTE_PATHS, CONTRACT_ROUTE_PREFIX } from "../../../modules/contract/paths";
import { CONTRACT_UPDATE_ROUTE_PATHS } from "../../../modules/contractupdate/paths";
import { COVERAGE_LIMIT_ROUTE_PATHS } from "../../../modules/coveragelimit/paths";
import { DASHBOARD_ROUTE_PATHS } from "../../../modules/dashboard/paths";
import { DOCUMENT_NODE_ROUTE_PATHS } from "../../../modules/documents/paths";
import { selectRouterLocation } from "../../../modules/ducks";
import { CONFIG_PROPERTY_ROUTE_PATHS } from "../../../modules/dynamicconfig/paths";
import { INSTITUTION_ROUTE_PATHS } from "../../../modules/institution/paths";
import { JOB_ROUTE_PATHS } from "../../../modules/jobs/paths";
import { LIFE_INSURENCE_TARIFF_ROUTE_PATHS } from "../../../modules/lifeinsurancetariff/paths";
import { PARTNER_ROUTE_PATHS } from "../../../modules/partner/paths";
import { PRODUCT_ROUTE_PATHS } from "../../../modules/product/paths";
import { ADMIN_USER_ROUTE_PATHS } from "../../../modules/user/paths";
import { VEHICLE_BRAND_ROUTE_PATHS } from "../../../modules/vehicle/brand/paths";
import { VEHICLE_ENUM_ROUTE_PATHS } from "../../../modules/vehicle/paths";
import styles from "./SideMenu.module.scss";

const BUG_REPORT_ITEM_KEY = "bug-report-menu-item";

const createMenuDivider = ({ path, permissions, ...dividerProps }: MenuDividerProps): MenuDividerType | undefined => {
  return checkHasPathAccess(path, permissions)
    ? {
        ...dividerProps,
        key: dividerProps.key || path,
        type: "divider",
        className: classNames(dividerProps?.className, styles.menuDivider)
      }
    : undefined;
};

const SideMenu = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([location.pathname]);
  const [openedKeys, setOpenedKeys] = useState<string[]>([parseFirstPartOfPathname(location.pathname)]);
  const [bugReportFormOpen, setBugReportFormOpen] = useState<boolean>(false);

  const routerLocation = useSelector<RootState, Location>(selectRouterLocation);
  const permissions = useSelector<RootState, Permission[]>(selectPermissions);
  const hasAdminOrSystemPermissions = useSelector<RootState, boolean>(state =>
    selectHasAnyPermissions(
      ...(categoryPermissionsMap.get(PermissionsCategory.ADMIN) ?? []),
      ...(categoryPermissionsMap.get(PermissionsCategory.SYSTEM) ?? [])
    )(state)
  );

  const isTabletSize = useWindowSize().innerWidth < ViewBreakpoints.DESKTOP;

  useEffect(() => {
    setSelectedKeys([location.pathname]);
    setOpenedKeys(isTabletSize ? [] : [parseFirstPartOfPathname(location.pathname) + SUBMENU_KEY]);
  }, [location, isTabletSize]);

  const handleMenuItemClick = (clickInfo: MenuInfo): void => {
    if (clickInfo.key !== location.pathname && clickInfo.key !== BUG_REPORT_ITEM_KEY) {
      setSelectedKeys([clickInfo.key]);
    }
  };

  const handleSubmenuClick = (openKeys: React.Key[]): void => {
    const keys = openKeys as string[];
    setOpenedKeys(keys.length > 1 ? [keys[1] as string] : keys);
  };

  const handleBugReportItemClick = (): void => {
    setBugReportFormOpen(true);
  };

  const handleBugReportFormCancel = (): void => {
    setBugReportFormOpen(false);
  };

  const getMenuItems = useMemo(
    () =>
      (permissions: Permission[], routerLocation: Location): ItemType[] => {
        return [
          createMenuItem({
            path: DASHBOARD_ROUTE_PATHS.homepage.to,
            label: t("navigation.sideMenu.dashboard"),
            icon: <AntIcon type="dashboard" />,
            permissions,
            routerLocation,
            configProps: { topLevelMenuItem: true }
          }),
          createSubMenuItem({
            path: CALC_ROUTE_PREFIX + SUBMENU_KEY,
            label: t("navigation.header.calc"),
            icon: <AntIcon type="calculator" />,
            permissions,
            children: [
              createMenuItem({
                path: CALC_ROUTE_PATHS.vehicle.to,
                label: t("navigation.sideMenu.calc.car"),
                icon: <AntIcon type="car" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: CALC_ROUTE_PATHS.realty.to,
                label: t("navigation.sideMenu.calc.realty"),
                icon: <AntIcon type="home" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: CALC_ROUTE_PATHS.travel.to,
                label: t("navigation.sideMenu.calc.travel"),
                icon: <AntIcon type="global" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: CALC_ROUTE_PATHS.records.to,
                label: t("navigation.sideMenu.calc.records"),
                icon: <AntIcon type="file-search" />,
                permissions,
                routerLocation
              })
            ].filter(item => item !== undefined) as ItemType[]
          }),
          createMenuItem({
            path: PARTNER_ROUTE_PATHS.configs.to,
            label: t("navigation.sideMenu.partners"),
            icon: <AntIcon type="appstore" />,
            permissions,
            routerLocation,
            configProps: { topLevelMenuItem: true }
          }),
          createSubMenuItem({
            path: CLIENT_ROUTE_PREFIX + SUBMENU_KEY,
            label: t("navigation.header.clients"),
            icon: <AntIcon type="team" />,
            permissions,
            children: [
              createMenuItem({
                path: CLIENT_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.clients.list"),
                icon: <AntIcon type="team" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: CLIENT_ROUTE_PATHS.create.to,
                label: t("navigation.sideMenu.clients.new"),
                icon: <AntIcon type="user-add" />,
                permissions,
                routerLocation
              })
            ].filter(item => item !== undefined) as ItemType[]
          }),
          createSubMenuItem({
            path: CONTRACT_ROUTE_PREFIX + SUBMENU_KEY,
            label: t("navigation.header.contracts"),
            icon: <AntIcon type="audit" />,
            permissions,
            children: [
              createMenuItem({
                path: CONTRACT_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.contracts.list"),
                icon: <AntIcon type="database" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: CONTRACT_ROUTE_PATHS.create.to,
                label: t("navigation.sideMenu.contracts.new"),
                icon: <AntIcon type="plus-circle" />,
                permissions,
                routerLocation
              }),
              createMenuDivider({ path: CONTRACT_REPORTS_DIVIDER_KEY, permissions }),
              createMenuItem({
                path: CONTRACT_ROUTE_PATHS.predictedCommissions.to,
                label: t("navigation.sideMenu.contracts.predictedCommissions"),
                icon: <AntIcon type="trophy" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: CONTRACT_ROUTE_PATHS.anniversaryDate.to,
                label: t("navigation.sideMenu.contracts.anniversaryDate"),
                icon: <AntIcon type="alert" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: CONTRACT_ROUTE_PATHS.unpaid.to,
                label: t("navigation.sideMenu.contracts.unpaid"),
                icon: <AntIcon type="euro" />,
                permissions,
                routerLocation
              }),
              createMenuDivider({ path: CONTRACT_FORMS_DIVIDER_KEY, permissions }),
              createMenuItem({
                path: CONTRACT_ROUTE_PATHS.forms.to,
                label: t("navigation.sideMenu.contracts.forms"),
                icon: <AntIcon type="form" />,
                permissions,
                routerLocation
              })
            ].filter(item => item !== undefined) as ItemType[]
          }),
          createSubMenuItem({
            path: AGENT_ROUTE_PREFIX + SUBMENU_KEY,
            label: t("navigation.header.agents"),
            icon: <AntIcon type="user" />,
            permissions,
            children: [
              createMenuItem({
                path: AGENT_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.agents.list"),
                icon: <AntIcon type="team" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: AGENT_ROUTE_PATHS.tree.to,
                label: t("navigation.sideMenu.agents.tree"),
                icon: <AntIcon type="apartment" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: AGENT_ROUTE_PATHS.create.to,
                label: t("navigation.sideMenu.agents.new"),
                icon: <AntIcon type="user-add" />,
                permissions,
                routerLocation
              }),
              createMenuDivider({ path: AGENT_REPORTS_DIVIDER_KEY, permissions }),
              createMenuItem({
                path: AGENT_ROUTE_PATHS.endingCompetences.to,
                label: t("navigation.sideMenu.agents.endingCompetences"),
                icon: <AntIcon type="file-done" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: AGENT_ROUTE_PATHS.pointsReport.to,
                label: t("navigation.sideMenu.agents.points"),
                icon: <AntIcon type="cluster" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: AGENT_ROUTE_PATHS.bailAccountsReport.to,
                label: t("navigation.sideMenu.agents.bailAccounts"),
                icon: <AntIcon type="plus-square" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: AGENT_ROUTE_PATHS.debtAccountsReport.to,
                label: t("navigation.sideMenu.agents.debtAccounts"),
                icon: <AntIcon type="minus-square" />,
                permissions,
                routerLocation
              })
            ].filter(item => item !== undefined) as ItemType[]
          }),
          createMenuItem({
            path: DOCUMENT_NODE_ROUTE_PATHS.list.to,
            label: t("navigation.header.documents"),
            icon: <AntIcon type="folder" />,
            permissions,
            routerLocation,
            configProps: { topLevelMenuItem: true }
          }),
          createSubMenuItem({
            path: COMMISSIONS_SUBMENU_KEY,
            label: t("navigation.header.commissions"),
            icon: <AntIcon type="trophy" />,
            permissions,
            children: [
              createMenuItem({
                path: COMMISSIONS_BATCH_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.commissions.batchesList"),
                icon: <AntIcon type="euro" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: POSTPONED_COMMISSIONS_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.commissions.postponedCommissions"),
                icon: <AntIcon type="pause" />,
                permissions,
                routerLocation
              }),
              createMenuDivider({ path: COMMISSIONS_SETTINGS_DIVIDER_KEY, permissions }),
              createMenuItem({
                path: COMMISSIONS_LEVEL_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.commissions.levels"),
                icon: <AntIcon type="fork" />,
                permissions,
                routerLocation
              })
            ].filter(item => item !== undefined) as ItemType[]
          }),
          createSubMenuItem({
            path: ADMIN_SUBMENU_KEY,
            label: t("navigation.header.admin"),
            icon: <AntIcon type="safety-certificate" />,
            permissions,
            children: [
              createMenuItem({
                path: ADMIN_USER_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.admin.users"),
                icon: <AntIcon type="user-switch" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: INSTITUTION_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.admin.institutions"),
                icon: <AntIcon type="bank" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: PRODUCT_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.admin.products"),
                icon: <AntIcon type="reconciliation" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: AFFILIATE_PARTNER_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.admin.affiliatePartners"),
                icon: <AntIcon type="deployment-unit" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: ADMIN_CALC_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.admin.calculators"),
                icon: <AntIcon type="calculator" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: CONTRACT_UPDATE_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.admin.contractUpdates"),
                icon: <AntIcon type="cloud-sync" />,
                permissions,
                routerLocation
              })
            ].filter(item => item !== undefined) as ItemType[]
          }),
          createSubMenuItem({
            path: SETTINGS_SUBMENU_KEY,
            label: t("navigation.header.settings"),
            icon: <AntIcon type="settings" />,
            permissions,
            children: [
              createMenuItem({
                path: LIFE_INSURENCE_TARIFF_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.settings.lifeInsuranceTariffs"),
                icon: <AntIcon type="container" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: COVERAGE_LIMIT_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.settings.coverageLimits"),
                icon: <AntIcon type="reconciliation" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: COMPLICITY_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.settings.complicities"),
                icon: <AntIcon type="sister-node" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: VEHICLE_BRAND_ROUTE_PATHS.brands.to,
                label: t("navigation.sideMenu.settings.vehicleBrandsAndModels"),
                icon: <AntIcon type="heat-map" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: VEHICLE_ENUM_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.settings.vehicleEnums"),
                icon: <AntIcon type="car" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: JOB_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.settings.jobs"),
                icon: <AntIcon type="robot" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: CALC_BLACKLIST_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.settings.blacklist"),
                icon: <AntIcon type="calculator" />,
                permissions,
                routerLocation
              }),
              createMenuItem({
                path: CONFIG_PROPERTY_ROUTE_PATHS.list.to,
                label: t("navigation.sideMenu.settings.configProperties"),
                icon: <AntIcon type="key" />,
                permissions,
                routerLocation
              })
            ].filter(item => item !== undefined) as ItemType[]
          }),
          hasAdminOrSystemPermissions
            ? ({
                key: BUG_REPORT_ITEM_KEY,
                label: t("navigation.sideMenu.bugReport"),
                icon: <AntIcon type="bug" />,
                className: styles.sideMenuBugReportItem,
                style: { margin: 0, marginBottom: 8 },
                onClick: handleBugReportItemClick
              } as MenuItemType)
            : undefined
        ].filter(item => item !== undefined) as ItemType[];
      },
    [permissions, routerLocation, hasAdminOrSystemPermissions] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <ConfigProvider theme={{ components: { Menu: { colorSplit: cssVariables.colorGrey } } }}>
        <Menu
          theme="dark"
          mode="inline"
          items={getMenuItems(permissions, routerLocation)}
          selectedKeys={selectedKeys}
          openKeys={openedKeys}
          onClick={handleMenuItemClick}
          onOpenChange={handleSubmenuClick}
          className={styles.menuWrapper}
        />
      </ConfigProvider>

      {hasAdminOrSystemPermissions && (
        <CreateUserBugReportForm open={bugReportFormOpen} onFormCancel={handleBugReportFormCancel} />
      )}
    </>
  );
};

export default React.memo(SideMenu);
