import { Checkbox, Col, Form, Input, Modal, Row, Select, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import { ContentType, ModalSizes, rowGutter } from "../../../../../common/constants";
import {
  resolveFormValidationError,
  selectTagsStandardProps,
  useFormErrorHandler
} from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { requests } from "../../../api";
import { importContractsActions } from "../../../ducks";
import { ContractVerificationStatus, contractVerificationStatusTMap } from "../../../enums";
import ContractVerificationStatusTag from "../../ContractVerificationStatusTag";

interface Props {
  open: boolean;
  onFormCancel: () => void;
}

const ContractsImportForm = ({ open, onFormCancel }: Props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<{
    dryRun: boolean;
    allowUpdate: boolean;
    verificationStatus: ContractVerificationStatus;
    verificationNote: string;
  }>();

  useFormErrorHandler(form, "contract.helpers.import", [requests.IMPORT_CONTRACTS]);

  const inProgress = useRequestFinishedCallback([requests.IMPORT_CONTRACTS], onFormCancel);

  const [file, setFile] = useState<RcFile>();

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onImport: importContractsActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  const handleFileUpload = (file: RcFile): boolean | string => {
    if (file.type !== ContentType.CSV) {
      messageUtils.errorNotification({ message: t("common.error"), description: t("helpers.onlyCsvSupported") });
      return Upload.LIST_IGNORE;
    }
    setFile(file);
    return false;
  };

  const handleFileRemove = (): boolean => {
    setFile(undefined);
    return true;
  };

  const handleAfterClosed = (): void => {
    setFile(undefined);
    form.resetFields();
  };

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (!file) {
          messageUtils.errorNotification({ message: t("common.error"), description: t("helpers.missingInputFile") });
        } else {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("dryRun", values.dryRun.toString());
          formData.append("allowUpdate", values.allowUpdate.toString());
          formData.append("verificationStatus", values.verificationStatus);

          if (values.verificationNote) {
            formData.append("verificationNote", values.verificationNote);
          }

          actions.onImport(formData);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={open}
      title={t("contract.titles.import")}
      okText={t("common.submit")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
      afterClose={handleAfterClosed}
    >
      <Form form={form} layout="vertical" name="contractsImportForm">
        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item name="dryRun" valuePropName="checked" rules={[validations.none]} initialValue={true}>
              <Checkbox>
                <LabelWithTooltip
                  label={t("contract.helpers.import.dryRun")}
                  tooltip={t("contract.helpers.import.dryRunDesc")}
                />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item name="allowUpdate" valuePropName="checked" rules={[validations.none]} initialValue={false}>
              <Checkbox>
                <LabelWithTooltip
                  label={t("contract.helpers.import.allowUpdate")}
                  tooltip={t("contract.helpers.import.allowUpdateDesc")}
                />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item
              name="verificationStatus"
              label={
                <LabelWithTooltip
                  label={t("contract.enums.verificationStatus._label")}
                  tooltip={t("contract.helpers.import.verificationStatusDesc")}
                />
              }
              rules={[validations.notNull]}
            >
              <Select
                {...selectTagsStandardProps(contractVerificationStatusTMap)}
                options={Object.values(ContractVerificationStatus).map(status => ({
                  value: status,
                  label: <ContractVerificationStatusTag status={status} />
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item noStyle shouldUpdate={(prev, next) => prev.verificationStatus !== next.verificationStatus}>
          {({ getFieldValue }) => {
            const status = getFieldValue("verificationStatus") as ContractVerificationStatus;
            return status === ContractVerificationStatus.SENT ||
              status === ContractVerificationStatus.INTERNAL_INTERVENTION ||
              status === ContractVerificationStatus.EXTERNAL_INTERVENTION ? (
              <Row gutter={rowGutter}>
                <Col span={24}>
                  <Form.Item
                    name="verificationNote"
                    label={t("contract.helpers.import.verificationNote")}
                    rules={[validations.size(1, 1024)]}
                  >
                    <Input.TextArea autoSize={{ minRows: 3, maxRows: 15 }} />
                  </Form.Item>
                </Col>
              </Row>
            ) : null;
          }}
        </Form.Item>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Upload
              maxCount={1}
              accept=".csv"
              fileList={file ? [file] : []}
              beforeUpload={handleFileUpload}
              onRemove={handleFileRemove}
            >
              <ActionButton icon="plus" label={t("common.chooseFile")} />
            </Upload>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ContractsImportForm;
