import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import { selectTagsStandardProps } from "../../../../../common/utils/formUtils";
import { validationConstants, validations } from "../../../../../common/utils/validationUtils";
import { ClientType, clientTypeTMap } from "../../../enums";
import { ClientFilterPageRequest, ClientFilterPageResult } from "../../../types";
import ClientTypeTag from "../../tags/ClientTypeTag";

interface Props {
  filter: ClientFilterPageResult;
  onFilterSubmit: (filter: ClientFilterPageRequest) => void;
}

const ClientFilterView = ({ filter, onFilterSubmit }: Props) => {
  const [form] = Form.useForm<ClientFilterPageRequest>();

  useEffect(() => {
    form.setFieldsValue({ keyword: filter.keyword, types: filter.types || [] });
  }, [filter, form]);

  const handleFilterClear = (): void => {
    form.resetFields();
    onFilterSubmit(form.getFieldsValue());
  };

  const colSpan = 6;

  return (
    <Card
      className="card-box"
      title={<h2>{t("client.titles.list")}</h2>}
      extra={<ActionTextIcon icon="reload" color="red" onClick={handleFilterClear} text={t("common.resetFilter")} />}
    >
      <Form form={form} onFinish={onFilterSubmit} layout="vertical" name="clientFilterViewForm">
        <Row gutter={rowGutter} justify="center">
          <Col span={colSpan}>
            <Form.Item
              name="keyword"
              label={t("common.searchKeyword")}
              rules={[
                validations.size(
                  validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
                  validationConstants.SEARCH_KEYWORD_MAX_LENGTH
                )
              ]}
            >
              <Input allowClear placeholder={t("client.helpers.searchHint")} />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item name="types" label={t("client.enums.type._label")} rules={[validations.none]}>
              <Select
                {...selectTagsStandardProps(clientTypeTMap)}
                allowClear
                mode="multiple"
                maxTagCount="responsive"
                tagRender={props => (
                  <ClientTypeTag
                    type={ClientType[props.value as keyof typeof ClientType]}
                    closable={props.closable}
                    onClose={props.onClose}
                    label={props.label}
                  />
                )}
                options={Object.values(ClientType).map(type => ({
                  value: type,
                  label: <ClientTypeTag type={type} />
                }))}
              />
            </Form.Item>
          </Col>

          <Col flex="110px" className="no-title-space">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ClientFilterView;
