import { Button, Card, Col, Radio, Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import t from "../../../../app/i18n";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../common/constants";
import ContentWrapper from "../../../../common/modules/wrappers/ContentWrapper";
import { Permission } from "../../../../common/security/authorization/enums";
import { ActionProps, RootState } from "../../../../common/types";
import { appendSearchParamsToURL, hasAllPermissions, hasPermission } from "../../../../common/utils/utils";
import { selectPermissions } from "../../../auth/ducks";
import { selectRouterLocationSearchParam } from "../../../ducks";
import { CalcType } from "../../enums";
import CalcSettingsListView from "../components/list/CalcSettingsListView";
import {
  deleteStateCalcSettingsListAction,
  listCalcSettingsActions,
  selectCalcSettingsList,
  updateCalcSettingsActions
} from "../ducks";
import { ADMIN_CALC_ROUTE_PATHS } from "../paths";
import { CalcSettings } from "../types";

interface StateProps {
  permissions: Permission[];
  calcSettingsList: CalcSettings[];
  urlCalcType: keyof typeof CalcType;
}

interface ActionsMap {
  listCalcSettings: typeof listCalcSettingsActions.request;
  updateCalcSettings: typeof updateCalcSettingsActions.request;
  deleteStateCalcSettingsList: typeof deleteStateCalcSettingsListAction;
}

type Props = StateProps & ActionProps<ActionsMap>;

const CalcSettingsListContainer = ({ calcSettingsList, ...props }: Props) => {
  const navigate = useNavigate();

  const allowedCalcTypes = useMemo(
    () => [
      ...(hasPermission(props.permissions, Permission.ADMIN_MTPL_CALCULATORS) ? [CalcType.MTPL] : []),
      ...(hasPermission(props.permissions, Permission.ADMIN_CRASH_CALCULATORS) ? [CalcType.CRASH] : []),
      ...(hasAllPermissions(props.permissions, [Permission.ADMIN_MTPL_CALCULATORS, Permission.ADMIN_CRASH_CALCULATORS])
        ? [CalcType.MTPL_CRASH]
        : []),
      ...(hasPermission(props.permissions, Permission.ADMIN_CRASH_CALCULATORS) ? [CalcType.GAP, CalcType.PAS] : []),
      ...(hasPermission(props.permissions, Permission.ADMIN_REALTY_CALCULATORS) ? [CalcType.REALTY] : []),
      ...(hasPermission(props.permissions, Permission.ADMIN_TRAVEL_CALCULATORS) ? [CalcType.TRAVEL] : [])
    ],
    [props.permissions]
  );

  const [currentCalcType, setCurrentCalcType] = useState<CalcType>(
    allowedCalcTypes.includes(CalcType[props.urlCalcType])
      ? CalcType[props.urlCalcType]
      : (allowedCalcTypes[0] as CalcType)
  );

  useEffect(() => {
    handleCalcTypeSwitch(currentCalcType);
    return () => {
      props.actions.deleteStateCalcSettingsList();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCalcTypeSwitch = (calcType: CalcType): void => {
    navigate(appendSearchParamsToURL({ calcType }), { replace: true });
    props.actions.listCalcSettings({ calcType });
    setCurrentCalcType(calcType);
  };

  const handleCalcEnabledToggle = (settings: CalcSettings): void => {
    props.actions.updateCalcSettings({
      id: settings.id,
      object: {
        type: settings.type,
        optimisticLockVersion: settings.optimisticLockVersion,
        enabled: !settings.enabled,
        paymentDetails: settings.paymentDetails,
        institutionId: settings.institution.id,
        attachmentConfs: settings.attachmentConfs,
        mtplProductId: settings.mtplProduct?.id,
        crashProductId: settings.crashProduct?.id,
        mtplCrashProductId: settings.mtplCrashProduct?.id,
        gapProductId: settings.gapProduct?.id,
        pasProductId: settings.pasProduct?.id,
        realtyProductId: settings.realtyProduct?.id,
        shortTermInsuranceProductId: settings.shortTermInsuranceProduct?.id,
        yearInsuranceProductId: settings.yearInsuranceProduct?.id,
        cancellationInsuranceProductId: settings.cancellationInsuranceProduct?.id
      }
    });
  };

  const handleCreateClick = (): void => {
    navigate(ADMIN_CALC_ROUTE_PATHS.create.to + "?calcType=" + currentCalcType);
  };

  return (
    <ContentWrapper>
      <Card
        className="card-box"
        title={<h2>{t("calc.settings.titles.list", { calcType: t("calc.enums.calcType." + currentCalcType) })}</h2>}
        extra={
          currentCalcType && (
            <Button
              className="margin-right-small"
              type="primary"
              icon={<AntIcon type="plus" />}
              onClick={handleCreateClick}
            >
              {t("calc.settings.actions.createSettings", { calcType: t("calc.enums.calcType." + currentCalcType) })}
            </Button>
          )
        }
      >
        <Row gutter={rowGutter} justify="center" className="margin-bottom-medium">
          <Col xs={24} xxl={15} className="center-align">
            <Radio.Group
              size="large"
              buttonStyle="solid"
              value={currentCalcType}
              onChange={e => handleCalcTypeSwitch(CalcType[e.target.value as keyof typeof CalcType])}
            >
              {allowedCalcTypes.map(type => (
                <Radio.Button value={type} key={type}>
                  {t("calc.enums.calcType." + type)}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Col>
        </Row>

        <Row gutter={rowGutter} justify="center">
          <Col xs={24} xxl={14}>
            <CalcSettingsListView calcSettingsList={calcSettingsList} onCalcEnabledToggle={handleCalcEnabledToggle} />
          </Col>
        </Row>
      </Card>
    </ContentWrapper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  permissions: selectPermissions(state),
  calcSettingsList: selectCalcSettingsList(state),
  urlCalcType: selectRouterLocationSearchParam(state, "calcType") as keyof typeof CalcType
});

const mapDispatchToProps = (dispatch: Dispatch): ActionProps<ActionsMap> => ({
  actions: bindActionCreators(
    {
      listCalcSettings: listCalcSettingsActions.request,
      updateCalcSettings: updateCalcSettingsActions.request,
      deleteStateCalcSettingsList: deleteStateCalcSettingsListAction
    },
    dispatch
  )
});

export default connect<StateProps, ActionProps<ActionsMap>, Record<string, any>, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(CalcSettingsListContainer);
