import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import { Rule } from "antd/lib/form";
import dayjs, { Dayjs } from "dayjs";
import get from "lodash/get";
import t from "../../../app/i18n";
import ActionButton from "../../components/buttons/ActionButton";
import DeleteIcon from "../../components/icons/DeleteIcon";
import Ellipsis from "../../components/views/Ellipsis";
import { rowGutter } from "../../constants";
import {
  datePickerFormItemProps,
  datePickerStandardProps,
  disableDatePickerOutOfInterval,
  phoneNumberNormalizeFunction
} from "../../utils/formUtils";
import { validations } from "../../utils/validationUtils";
import { ContactType, MarketingConsent } from "../enums";
import ContactTypeIcon from "./ContactTypeIcon";
import MarketingConsentSelect from "./MarketingConsentSelect";

interface Props {
  marketingConsentApplicable?: boolean;
  onMarketingConsentChange?: (consent: MarketingConsent, index: number) => void;
  onMarketingConsentStartDateChange?: (startDate: Dayjs, index: number) => void;
}

const smallColSpan = 3;
const colSpan = 4;

const ContactsForm = ({
  marketingConsentApplicable,
  onMarketingConsentChange,
  onMarketingConsentStartDateChange
}: Props) => (
  <Form.List name={["contacts"]} initialValue={[]}>
    {(fields, { add, remove }) => (
      <>
        {fields.map(field => (
          <Row gutter={rowGutter} key={field.key}>
            <Col span={smallColSpan}>
              <Form.Item
                {...field}
                name={[field.name, "type"]}
                label={<Ellipsis>{t("contact.enums.type._label")}</Ellipsis>}
                rules={[validations.notNull]}
              >
                <Select<ContactType>
                  options={Object.values(ContactType).map(type => ({
                    value: type,
                    label: (
                      <>
                        <ContactTypeIcon type={type} /> {t("contact.enums.type." + type)}
                      </>
                    )
                  }))}
                />
              </Form.Item>
            </Col>

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                get(prev, ["contacts", field.name, "type"]) !== get(next, ["contacts", field.name, "type"])
              }
            >
              {({ getFieldValue }) => {
                const type = getFieldValue(["contacts", field.name, "type"]) as ContactType;

                const contactValueRules = [validations.notBlank, validations.size(1, 255)];
                switch (type) {
                  case ContactType.EMAIL:
                    contactValueRules.push(validations.email);
                    break;
                  case ContactType.PHONE_NUMBER:
                    contactValueRules.push(validations.phoneNumber);
                    break;
                }

                return (
                  <>
                    <Col span={colSpan}>
                      <Form.Item
                        {...field}
                        name={[field.name, "value"]}
                        label={t("contact.attrs.value")}
                        rules={contactValueRules}
                        normalize={value =>
                          type === ContactType.PHONE_NUMBER ? phoneNumberNormalizeFunction(value) : value
                        }
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        {...field}
                        name={[field.name, "description"]}
                        label={t("contact.attrs.description")}
                        rules={[validations.size(1, 255)]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    {marketingConsentApplicable &&
                      (type === ContactType.EMAIL || type === ContactType.PHONE_NUMBER) && (
                        <Col span={colSpan}>
                          <MarketingConsentSelect
                            formItemProps={{
                              ...field,
                              name: [field.name, "marketingConsent"],
                              label: <Ellipsis>{t("contact.enums.marketingConsent._label")}</Ellipsis>,
                              rules: [validations.notNull]
                            }}
                            selectProps={{
                              onChange: consent => onMarketingConsentChange?.(consent, field.name)
                            }}
                          />
                        </Col>
                      )}

                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) =>
                        get(prev, ["contacts", field.name, "marketingConsent"]) !==
                          get(next, ["contacts", field.name, "marketingConsent"]) ||
                        get(prev, ["contacts", field.name, "marketingConsentStartDate"]) !==
                          get(next, ["contacts", field.name, "marketingConsentStartDate"])
                      }
                    >
                      {({ getFieldValue }) => {
                        const marketingConsent = getFieldValue([
                          "contacts",
                          field.name,
                          "marketingConsent"
                        ]) as MarketingConsent;
                        const marketingConsentStartDate = getFieldValue([
                          "contacts",
                          field.name,
                          "marketingConsentStartDate"
                        ]) as string | undefined;

                        const marketingConsentEndDateRules: Rule[] = [validations.notNull];
                        if (marketingConsentStartDate) {
                          marketingConsentEndDateRules.push(
                            validations.dateInInterval(
                              dayjs(marketingConsentStartDate).add(1, "day"),
                              dayjs(marketingConsentStartDate).add(5, "year")
                            )
                          );
                        }

                        return (
                          marketingConsentApplicable &&
                          (type === ContactType.EMAIL || type === ContactType.PHONE_NUMBER) && (
                            <>
                              <Col span={colSpan}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "marketingConsentStartDate"]}
                                  label={t("contact.attrs.marketingConsentStartDate")}
                                  rules={[validations.notNull]}
                                  {...datePickerFormItemProps}
                                >
                                  <DatePicker<Dayjs>
                                    {...datePickerStandardProps}
                                    disabled={marketingConsent === MarketingConsent.NOT_GRANTED || undefined}
                                    onChange={startDate =>
                                      startDate ? onMarketingConsentStartDateChange?.(startDate, field.name) : undefined
                                    }
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={colSpan}>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "marketingConsentEndDate"]}
                                  label={t("contact.attrs.marketingConsentEndDate")}
                                  rules={marketingConsentEndDateRules}
                                  {...datePickerFormItemProps}
                                >
                                  <DatePicker
                                    {...datePickerStandardProps}
                                    disabled={marketingConsent === MarketingConsent.NOT_GRANTED || undefined}
                                    disabledDate={
                                      marketingConsentStartDate
                                        ? checked =>
                                            disableDatePickerOutOfInterval(
                                              checked,
                                              dayjs(marketingConsentStartDate).add(1, "day"),
                                              dayjs(marketingConsentStartDate).add(5, "year")
                                            )
                                        : undefined
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          )
                        );
                      }}
                    </Form.Item>

                    <Col
                      offset={
                        marketingConsentApplicable && type !== ContactType.EMAIL && type !== ContactType.PHONE_NUMBER
                          ? 12
                          : undefined
                      }
                      span={1}
                    >
                      <DeleteIcon onClick={() => remove(field.name)} />
                    </Col>
                  </>
                );
              }}
            </Form.Item>
          </Row>
        ))}

        <Row gutter={rowGutter}>
          <Col>
            <ActionButton
              className="margin-top-small"
              icon="plus"
              label={t("contact.actions.add")}
              onClick={() =>
                add({
                  type: undefined,
                  value: undefined,
                  description: undefined,
                  marketingConsent: marketingConsentApplicable ? undefined : MarketingConsent.NOT_GRANTED,
                  marketingConsentStartDate: undefined,
                  marketingConsentEndDate: undefined
                } as Record<string, any>)
              }
            />
          </Col>
        </Row>
      </>
    )}
  </Form.List>
);

export default ContactsForm;
