import t from "../../../../../../app/i18n";
import CopyToClipboardView from "../../../../../../common/components/views/CopyToClipboardView";
import HtmlView from "../../../../../../common/components/views/HtmlView";
import {
  formatAggregatedName,
  formatIban,
  formatLocaleDate,
  formatPhoneNumber
} from "../../../../../../common/utils/formatUtils";
import { SelfEmployedAgent } from "../../../../types";

interface Props {
  agent: SelfEmployedAgent;
}

const SelfEmployedAgentDataView = ({ agent }: Props) => (
  <>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("agent.attrs.companyName")}:</td>
      <td colSpan={3}>{agent.aggregatedName}</td>
    </tr>
    <tr>
      <td>{t("agent.attrs.aggregatedName")}:</td>
      <td colSpan={3}>{formatAggregatedName(agent)}</td>
    </tr>
    <tr>
      <td>{t("agent.attrs.companyRegistrationNumber")}:</td>
      <td>
        <CopyToClipboardView content={agent.companyRegistrationNumber} />
      </td>
      <td>{t("agent.attrs.districtOffice")}:</td>
      <td>{agent.districtOffice}</td>
    </tr>
    <tr>
      <td>{t("agent.attrs.taxId")}:</td>
      <td>
        <CopyToClipboardView content={agent.taxId} />
      </td>
      <td>{t("agent.attrs.tradeRegisterNumber")}:</td>
      <td>{agent.tradeRegisterNumber}</td>
    </tr>
    <tr>
      <td>{t("agent.attrs.vatId")}:</td>
      <td>
        <CopyToClipboardView content={agent.vatId} />
      </td>
      <td colSpan={2} />
    </tr>
    <tr>
      <td>{t("agent.attrs.personalIdentificationNumberLong")}:</td>
      <td>
        <CopyToClipboardView content={agent.personalIdentificationNumber} />
      </td>
      <td>{t("agent.attrs.birthDate")}:</td>
      <td>{formatLocaleDate(agent.birthDate)}</td>
    </tr>
    <tr>
      <td>{t("agent.attrs.identityCardNumber")}:</td>
      <td>
        <CopyToClipboardView content={agent.identityCardNumber} />
      </td>
      <td colSpan={2} />
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("agent.attrs.nbsRegistrationNumber")}:</td>
      <td colSpan={3}>
        <CopyToClipboardView content={agent.nbsRegistrationNumber} />
      </td>
    </tr>
    <tr>
      <td>{t("common.financialSectors")}:</td>
      <td colSpan={3}>{agent.sectors.map(sector => t("agent.enums.financialSector." + sector)).join(", ")}</td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("agent.attrs.email")}:</td>
      <td>
        <CopyToClipboardView content={agent.email} wrapper={<a href={`mailto:${agent.email}`}>{agent.email}</a>} />
      </td>
      <td>{t("agent.attrs.phone")}:</td>
      <td>
        <CopyToClipboardView
          content={agent.phone}
          wrapper={<a href={`tel:${agent.phone}`}>{formatPhoneNumber(agent.phone)}</a>}
        />
      </td>
    </tr>
    <tr>
      <td>{t("agent.attrs.web")}:</td>
      <td>{agent.web}</td>
      <td>{t("agent.attrs.bankAccountNumber")}:</td>
      <td>
        <span className="nowrap">
          <CopyToClipboardView content={formatIban(agent.bankAccountNumber)} />
        </span>
      </td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td colSpan={4}>{t("agent.attrs.note")}:</td>
    </tr>
    {agent.note && (
      <tr>
        <td colSpan={4}>
          <HtmlView value={agent.note} />
        </td>
      </tr>
    )}
  </>
);

export default SelfEmployedAgentDataView;
