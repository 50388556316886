import { Card, Col, DatePicker, Form, InputNumber, Row, Select } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import { Dayjs } from "dayjs";
import ReactQuill from "react-quill-new";
import t from "../../../../../../app/i18n";
import InputAddon from "../../../../../../common/components/form/addons/InputAddon";
import { rowGutter } from "../../../../../../common/constants";
import {
  datePickerClearableProps,
  datePickerFormItemProps,
  datePickerStandardProps,
  dateToIsoDateString,
  disableDatePickerOutOfMaxDate,
  disableDatePickerOutOfMaxDateIncluded,
  disableDatePickerOutOfMinDate,
  disableDatePickerOutOfMinDateIncluded,
  inputNumberDecimalStandardProps,
  quillEditorStandardProps,
  selectStandardProps
} from "../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { LoanRateType } from "../../../../enums";
import { CreateUpdateLoanContract } from "../../../../types";
import {
  calculateContactClientDate,
  calculateContractStatus,
  calculateFixationAnniversaryDate
} from "../../../../utils";
import ContractStatusTag from "../../../ContractStatusTag";

interface Props {
  form: FormInstance<CreateUpdateLoanContract>;
}

const LoanContractFormDataSection = ({ form }: Props) => {
  const handleSignDateChange = (): void => {
    setContractSpecificDates();
    handleStatusDefiningDateChange();
    const signDate = form.getFieldValue(["signDate"]);
    const records = [...form.getFieldValue(["gainerRecords"])];
    records[0] = { ...records[0], startDate: signDate ?? "" };
    form.setFieldsValue({ gainerRecords: records });
  };

  const handleStatusDefiningDateChange = (): void => {
    const { signDate, loanMaturityDate, cancellationDate } = form.getFieldsValue([
      ["signDate"],
      ["loanMaturityDate"],
      ["cancellationDate"]
    ]) as CreateUpdateLoanContract;
    form.setFieldsValue({
      status: calculateContractStatus({
        startDate: signDate,
        endDate: loanMaturityDate,
        cancellationDate: cancellationDate,
        transferredToOtherBrokerDate: undefined
      })
    });
  };

  const handleRateTypeChange = (rateType: LoanRateType): void => {
    if (rateType === LoanRateType.VARIABLE) {
      form.setFieldsValue({ fixationAnniversaryDate: undefined, contactClientDate: undefined });
    }
    setContractSpecificDates();
  };

  const handleFixationAnniversaryDateChange = (fixationAnniversaryDate: Dayjs | null): void => {
    if (fixationAnniversaryDate) {
      form.setFieldsValue({ contactClientDate: calculateContactClientDate(fixationAnniversaryDate) });
    }
  };

  const setContractSpecificDates = (): void => {
    const { signDate, rateType, fixationAnniversaryDate } = form.getFieldsValue([
      ["signDate"],
      ["rateType"],
      ["fixationAnniversaryDate"]
    ]) as CreateUpdateLoanContract;

    if (rateType !== LoanRateType.VARIABLE || !fixationAnniversaryDate) {
      const calculatedAnniversaryDate = calculateFixationAnniversaryDate(signDate, rateType);
      form.setFieldsValue({
        fixationAnniversaryDate: calculatedAnniversaryDate ? dateToIsoDateString(calculatedAnniversaryDate) : undefined,
        contactClientDate: calculateContactClientDate(calculatedAnniversaryDate)
      });
    }
  };

  const colSpan = 4;

  return (
    <Card
      type="inner"
      className="card-box card-box--inner-extra"
      title={t("contract.sections.contractData")}
      extra={
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.status !== next.status}>
          {({ getFieldValue }) => {
            const status = getFieldValue("status");
            return status ? <ContractStatusTag status={status} /> : undefined;
          }}
        </Form.Item>
      }
    >
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.applicationSignDate !== next.applicationSignDate}>
            {({ getFieldValue }) => {
              const applicationSignDate = getFieldValue("applicationSignDate");
              const rules: Rule[] = [validations.notNull];
              if (applicationSignDate) {
                rules.push(validations.notAfter(applicationSignDate, t("contract.attrs.applicationSignDate")));
              }
              return (
                <Form.Item
                  name="mediationReportSignDate"
                  label={t("contract.attrs.mediationReportSignDate")}
                  rules={rules}
                  {...datePickerFormItemProps}
                >
                  <DatePicker
                    {...datePickerStandardProps}
                    disabledDate={current =>
                      applicationSignDate ? disableDatePickerOutOfMaxDate(current, applicationSignDate) : false
                    }
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.signDate !== next.signDate}>
            {({ getFieldValue }) => {
              const signDate = getFieldValue("signDate") as string | undefined;
              const rules: Rule[] = [validations.notNull];
              if (signDate) {
                rules.push(validations.notAfter(signDate, t("contract.attrs.signDate")));
              }
              return (
                <Form.Item
                  name="applicationSignDate"
                  label={t("contract.attrs.applicationSignDate")}
                  rules={rules}
                  {...datePickerFormItemProps}
                >
                  <DatePicker
                    {...datePickerStandardProps}
                    disabledDate={current => (signDate ? disableDatePickerOutOfMaxDate(current, signDate) : false)}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="signDate"
            label={t("contract.attrs.signDate")}
            rules={[validations.notNull]}
            {...datePickerFormItemProps}
          >
            <DatePicker {...datePickerStandardProps} onChange={handleSignDateChange} />
          </Form.Item>
        </Col>

        <Form.Item noStyle shouldUpdate={(prev, next) => prev.signDate !== next.signDate}>
          {({ getFieldValue }) => {
            const signDate = getFieldValue("signDate") as string | undefined;
            const maturityDateRules: Rule[] = [validations.notNull];
            if (signDate) {
              maturityDateRules.push(validations.notSameOrBefore(signDate, t("contract.attrs.signDate")));
            }
            return (
              <Col span={colSpan}>
                <Form.Item
                  name="loanMaturityDate"
                  label={t("contract.attrs.loanMaturityDate")}
                  rules={maturityDateRules}
                  {...datePickerFormItemProps}
                >
                  <DatePicker
                    {...datePickerStandardProps}
                    disabledDate={current =>
                      signDate ? disableDatePickerOutOfMinDateIncluded(current, signDate) : false
                    }
                    onChange={handleStatusDefiningDateChange}
                  />
                </Form.Item>
              </Col>
            );
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.signDate !== next.signDate || prev.loanMaturityDate !== next.loanMaturityDate
          }
        >
          {({ getFieldValue }) => {
            const signDate = getFieldValue("signDate") as string | undefined;
            const loanMaturityDate = getFieldValue("loanMaturityDate") as string | undefined;
            const rules = [];
            if (signDate) {
              rules.push(validations.notBefore(signDate, t("contract.attrs.signDate")));
            }
            if (loanMaturityDate) {
              rules.push(validations.notSameOrAfter(loanMaturityDate, t("contract.attrs.loanMaturityDate")));
            }
            return (
              <Col span={colSpan}>
                <Form.Item
                  name="cancellationDate"
                  label={t("contract.attrs.cancellationDate")}
                  rules={rules.length > 0 ? rules : [validations.none]}
                  {...datePickerFormItemProps}
                >
                  <DatePicker
                    {...datePickerClearableProps}
                    disabledDate={current =>
                      (!!signDate && disableDatePickerOutOfMinDate(current, signDate)) ||
                      (!!loanMaturityDate && disableDatePickerOutOfMaxDateIncluded(current, loanMaturityDate))
                    }
                    onChange={handleStatusDefiningDateChange}
                  />
                </Form.Item>
              </Col>
            );
          }}
        </Form.Item>

        <Col span={colSpan}>
          <Form.Item
            name="ltvRatio"
            label={t("contract.attrs.ltvRatio")}
            rules={[validations.minNumber(0.01), validations.maxNumber(100)]}
          >
            <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="percentage" />} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="approvedAmount"
            label={t("contract.attrs.approvedAmount")}
            rules={[validations.notNull, validations.minNumber(0.01)]}
          >
            <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.approvedAmount !== next.approvedAmount}>
            {({ getFieldValue }) => (
              <Form.Item
                name="monthlyPaymentAmount"
                label={t("contract.attrs.monthlyPaymentAmount")}
                rules={[
                  validations.notNull,
                  validations.minNumber(0.01),
                  validations.maxNumber(getFieldValue("approvedAmount"), t("contract.attrs.approvedAmount"))
                ]}
              >
                <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
              </Form.Item>
            )}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="interestRate"
            label={t("contract.attrs.interestRate")}
            rules={[validations.notNull, validations.minNumber(0.01), validations.maxNumber(100)]}
          >
            <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="percentage" />} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item name="rateType" label={t("contract.enums.loanRateType._label")} rules={[validations.notNull]}>
            <Select
              {...selectStandardProps}
              options={Object.keys(LoanRateType).map(type => ({
                value: type,
                label: t("contract.enums.loanRateType." + type)
              }))}
              onChange={handleRateTypeChange}
            />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.signDate !== next.signDate ||
              prev.loanMaturityDate !== next.loanMaturityDate ||
              prev.rateType !== next.rateType
            }
          >
            {({ getFieldValue }) => {
              const rateType = getFieldValue("rateType");
              const signDate = getFieldValue("signDate") as string | undefined;
              const loanMaturityDate = getFieldValue("loanMaturityDate") as string | undefined;
              const rules: Rule[] = [validations.notNull];
              if (signDate) {
                rules.push(validations.notSameOrBefore(signDate, t("contract.attrs.signDate")));
              }
              if (loanMaturityDate) {
                rules.push(validations.notAfter(loanMaturityDate, t("contract.attrs.loanMaturityDate")));
              }
              return (
                <Form.Item
                  name="fixationAnniversaryDate"
                  label={t("contract.attrs.fixationAnniversaryDate")}
                  rules={rules}
                  {...datePickerFormItemProps}
                >
                  <DatePicker
                    {...datePickerStandardProps}
                    disabled={rateType && rateType !== LoanRateType.VARIABLE}
                    disabledDate={current =>
                      (!!signDate && disableDatePickerOutOfMinDateIncluded(current, signDate)) ||
                      (!!loanMaturityDate && disableDatePickerOutOfMaxDate(current, loanMaturityDate))
                    }
                    onChange={handleFixationAnniversaryDateChange}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="contactClientDate"
            label={t("contract.attrs.contactClientDate")}
            rules={[validations.notNull]}
            {...datePickerFormItemProps}
          >
            <DatePicker {...datePickerStandardProps} disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            name="note"
            label={t("contract.attrs.note")}
            rules={[validations.size(1, 8192)]}
            initialValue={undefined}
          >
            <ReactQuill {...quillEditorStandardProps} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default LoanContractFormDataSection;
