import { Alert, Card, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select, Switch } from "antd";
import { FormInstance } from "antd/lib/form";
import t from "../../../../../../../app/i18n";
import ActionButton from "../../../../../../../common/components/buttons/ActionButton";
import InputAddon from "../../../../../../../common/components/form/addons/InputAddon";
import LabelWithPopover from "../../../../../../../common/components/form/labels/LabelWithPopover";
import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";
import AntIcon from "../../../../../../../common/components/icons/AntIcon";
import DeleteIcon from "../../../../../../../common/components/icons/DeleteIcon";
import { rowGutter } from "../../../../../../../common/constants";
import { inputNumberIntegerStandardProps, selectStandardProps } from "../../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../../common/utils/validationUtils";
import { ClientType } from "../../../../../../client/enums";
import { BuildingState, BuildingType, HouseholdEquipmentType, Liability, NearbyBuildingType } from "../../../enums";
import { RealtyCalc, RealtyCalcHouseholdInsuranceData } from "../../../types";
import { NEARBY_BUILDING_TYPES_WITH_AREA, NEARBY_BUILDING_TYPES_WITH_VOLUME } from "../../../utils";

interface Props {
  form: FormInstance<RealtyCalc>;
  policyHolderType?: ClientType;
}

const RealtyCalcHouseholdRealtySection = ({ form, policyHolderType }: Props) => {
  const handleRealtyInsuranceEnabledChange = (checked: boolean): void => {
    if (checked) {
      const householdInsuranceData = form.getFieldValue("householdInsuranceData") as RealtyCalcHouseholdInsuranceData;
      if (householdInsuranceData?.equipmentReinsurances) {
        form.setFieldsValue({
          householdInsuranceData: {
            equipmentReinsurances: householdInsuranceData.equipmentReinsurances.filter(
              e => e?.type !== HouseholdEquipmentType.STRUCTURAL_COMPONENTS
            )
          }
        });
      }
    } else {
      form.setFieldsValue({ generalInsuranceData: { loanReinsurance: false, rentReinsurance: false } });
    }
  };

  const colSpan = 4;
  const twoColumnColSpan = 8;

  return (
    <Card type="inner" className="card-box" title={t("calc.realty.sections.realtyAndHouseholdInsurance")}>
      <Form.Item
        noStyle
        shouldUpdate={(prev, next) =>
          prev.generalBuildingData.type !== next.generalBuildingData.type ||
          prev.realtyInsuranceEnabled !== next.realtyInsuranceEnabled ||
          prev.householdInsuranceEnabled !== next.householdInsuranceEnabled
        }
      >
        {({ getFieldValue }) => {
          const type = getFieldValue(["generalBuildingData", "type"]) as BuildingType;
          const isHouse = type === BuildingType.HOUSE || type === BuildingType.RECREATIONAL_BUILDING;
          const realtyInsuranceEnabled = getFieldValue(["realtyInsuranceEnabled"]);
          const householdInsuranceEnabled = getFieldValue(["householdInsuranceEnabled"]);
          return (
            <Row gutter={rowGutter} className="margin-bottom-large">
              <Col span={12}>
                <Row>
                  <Col flex="170px" className="bold-text" style={{ marginTop: 5 }}>
                    {t("calc.realty.attrs.realtyInsuranceEnabled")}:
                  </Col>

                  <Col span={15}>
                    <Form.Item
                      name="realtyInsuranceEnabled"
                      valuePropName="checked"
                      dependencies={["householdInsuranceEnabled"]}
                      rules={[
                        validations.notFalseIfOtherFalse(
                          ["householdInsuranceEnabled"],
                          t("calc.realty.attrs.householdInsuranceEnabled")
                        )
                      ]}
                      initialValue={false}
                    >
                      <Switch
                        checkedChildren={<AntIcon type="check" />}
                        unCheckedChildren={<AntIcon type="close" />}
                        onChange={handleRealtyInsuranceEnabledChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {realtyInsuranceEnabled && (
                  <>
                    <Divider className="divider-subheader">{t("calc.realty.sections.realtyInsurance")}</Divider>

                    <Row gutter={rowGutter}>
                      <Col
                        span={twoColumnColSpan + (isHouse ? 2 : 0)}
                        style={{ marginTop: isHouse ? "-20px" : undefined }}
                      >
                        <Form.Item
                          name={["realtyInsuranceData", "insuranceAmount"]}
                          label={
                            isHouse ? (
                              <LabelWithTooltip
                                tooltip={t("calc.realty.helpers.realtyInsuranceAmountDesc")}
                                label={t("calc.realty.attrs.realtyInsuranceData.insuranceAmountWithNearbyBuildings")}
                              />
                            ) : (
                              t("calc.realty.attrs.realtyInsuranceData.insuranceAmount")
                            )
                          }
                          rules={[validations.notNull, validations.minNumber(1), validations.multipleOf100]}
                        >
                          <InputNumber
                            {...inputNumberIntegerStandardProps}
                            addonAfter={<InputAddon type="euro" />}
                            step={100}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={twoColumnColSpan}>
                        <Form.Item
                          name={["realtyInsuranceData", "expertTestimony"]}
                          className="form-item-without-label"
                          valuePropName="checked"
                          rules={[validations.none]}
                          initialValue={false}
                        >
                          <Checkbox>{t("calc.realty.attrs.realtyInsuranceData.expertTestimony")}</Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>

                    {isHouse ? (
                      <>
                        <Divider className="divider-subheader">
                          {t("calc.realty.sections.realtyReinsurances")}
                          <LabelWithPopover
                            popoverTitle={t("calc.realty.helpers.nearbyBuildingDesc.title")}
                            popoverContent={
                              <table className="data-table-view wide-margins" style={{ maxWidth: 500 }}>
                                <tbody>
                                  {[1, 2, 3, 4, 5, 6, 7, 8].map(value => (
                                    <tr key={value}>
                                      <td>{t("calc.realty.helpers.nearbyBuildingDesc.institution" + value)}</td>
                                      <td>{t("calc.realty.helpers.nearbyBuildingDesc.value" + value)}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            }
                            className="margin-left-tiny"
                          />
                        </Divider>

                        <Form.List name={["realtyInsuranceData", "nearbyBuildingReinsurances"]}>
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(field => (
                                <Row gutter={rowGutter} key={field.key}>
                                  <Col span={twoColumnColSpan}>
                                    <Form.Item
                                      name={[field.name, "type"]}
                                      label={t("calc.realty.enums.nearbyBuildingType._label")}
                                      rules={[validations.notNull]}
                                    >
                                      <Select
                                        {...selectStandardProps}
                                        options={Object.keys(NearbyBuildingType).map(type => ({
                                          value: type,
                                          label: t("calc.realty.enums.nearbyBuildingType." + type)
                                        }))}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col span={twoColumnColSpan}>
                                    <Form.Item
                                      name={[field.name, "insuranceAmount"]}
                                      label={t(
                                        "calc.realty.attrs.realtyInsuranceData.nearbyBuildingReinsurances.insuranceAmount"
                                      )}
                                      rules={[validations.notNull, validations.minNumber(1), validations.multipleOf100]}
                                    >
                                      <InputNumber
                                        {...inputNumberIntegerStandardProps}
                                        addonAfter={<InputAddon type="euro" />}
                                        step={100}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Form.Item
                                    noStyle
                                    shouldUpdate={(prev, next) =>
                                      prev.realtyInsuranceData?.nearbyBuildingReinsurances?.[field.name]?.type !==
                                      next.realtyInsuranceData?.nearbyBuildingReinsurances?.[field.name]?.type
                                    }
                                  >
                                    {({ getFieldValue }) => {
                                      const nearbyBuildingType = getFieldValue([
                                        "realtyInsuranceData",
                                        "nearbyBuildingReinsurances",
                                        field.name,
                                        "type"
                                      ]);
                                      return (
                                        <>
                                          {NEARBY_BUILDING_TYPES_WITH_AREA.includes(nearbyBuildingType) && (
                                            <Col span={twoColumnColSpan - 1}>
                                              <Form.Item
                                                name={[field.name, "floorArea"]}
                                                label={t(
                                                  "calc.realty.attrs.realtyInsuranceData.nearbyBuildingReinsurances.floorArea"
                                                )}
                                                rules={[validations.notNull, validations.minNumber(1)]}
                                              >
                                                <InputNumber
                                                  {...inputNumberIntegerStandardProps}
                                                  addonAfter={<InputAddon type="area" />}
                                                />
                                              </Form.Item>
                                            </Col>
                                          )}

                                          {NEARBY_BUILDING_TYPES_WITH_VOLUME.includes(nearbyBuildingType) && (
                                            <Col span={twoColumnColSpan - 1}>
                                              <Form.Item
                                                name={[field.name, "volume"]}
                                                label={t(
                                                  "calc.realty.attrs.realtyInsuranceData.nearbyBuildingReinsurances.volume"
                                                )}
                                                rules={[validations.minNumber(1)]}
                                              >
                                                <InputNumber
                                                  {...inputNumberIntegerStandardProps}
                                                  addonAfter={<InputAddon type="volume" />}
                                                />
                                              </Form.Item>
                                            </Col>
                                          )}

                                          <Col span={1} className="right-align" style={{ paddingLeft: 0 }}>
                                            <DeleteIcon onClick={() => remove(field.name)} />
                                          </Col>

                                          {nearbyBuildingType === NearbyBuildingType.OTHER && (
                                            <Col span={twoColumnColSpan * 2}>
                                              <Form.Item
                                                name={[field.name, "otherDesc"]}
                                                label={t(
                                                  "calc.realty.attrs.realtyInsuranceData.nearbyBuildingReinsurances.otherDesc"
                                                )}
                                                rules={[validations.notBlank, validations.size(1, 64)]}
                                              >
                                                <Input />
                                              </Form.Item>
                                            </Col>
                                          )}

                                          {nearbyBuildingType === NearbyBuildingType.DETACHED_GARAGE && (
                                            <Col span={twoColumnColSpan * 3}>
                                              <Form.Item
                                                name={[field.name, "placeOfGarageSameAsBuilding"]}
                                                valuePropName="checked"
                                                initialValue={true}
                                                rules={[validations.none]}
                                              >
                                                <Checkbox>
                                                  <LabelWithPopover
                                                    label={t(
                                                      "calc.realty.attrs.realtyInsuranceData.nearbyBuildingReinsurances.placeOfGarageSameAsBuilding"
                                                    )}
                                                    popoverTitle={t(
                                                      "calc.realty.helpers.placeOfGarageSameAsBuildingDesc.title"
                                                    )}
                                                    popoverContent={
                                                      <div style={{ maxWidth: 550 }}>
                                                        <table className="data-table-view wide-margins">
                                                          <tbody>
                                                            {[1, 2, 3].map(value => (
                                                              <tr key={value}>
                                                                <td>
                                                                  {t(
                                                                    "calc.realty.helpers.placeOfGarageSameAsBuildingDesc.institution" +
                                                                      value
                                                                  )}
                                                                </td>
                                                                <td>
                                                                  {t(
                                                                    "calc.realty.helpers.placeOfGarageSameAsBuildingDesc.value" +
                                                                      value
                                                                  )}
                                                                </td>
                                                              </tr>
                                                            ))}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    }
                                                  />
                                                </Checkbox>
                                              </Form.Item>
                                            </Col>
                                          )}
                                        </>
                                      );
                                    }}
                                  </Form.Item>
                                </Row>
                              ))}

                              <ActionButton
                                icon="plus"
                                className="margin-top-small"
                                label={t("calc.realty.actions.nearbyBuildingAdd")}
                                onClick={() => add()}
                              />

                              {fields.length > 0 && (
                                <Alert
                                  message={t("calc.realty.helpers.nearbyBuildingInsuranceAmount")}
                                  type="info"
                                  showIcon
                                  className="margin-top-medium"
                                  closable
                                />
                              )}
                            </>
                          )}
                        </Form.List>
                      </>
                    ) : undefined}
                  </>
                )}
              </Col>

              <Col span={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, next) => prev.generalBuildingData.state !== next.generalBuildingData.state}
                >
                  {({ getFieldValue }) => {
                    const state = getFieldValue(["generalBuildingData", "state"]) as BuildingState;
                    return (
                      <>
                        <Row gutter={rowGutter}>
                          <Col flex="170px" className="bold-text" style={{ marginTop: "5px" }}>
                            {t("calc.realty.attrs.householdInsuranceEnabled")}:
                          </Col>

                          <Col span={15}>
                            <Form.Item
                              name="householdInsuranceEnabled"
                              valuePropName="checked"
                              dependencies={["realtyInsuranceEnabled"]}
                              rules={[
                                validations.notFalseIfOtherFalse(
                                  ["realtyInsuranceEnabled"],
                                  t("calc.realty.attrs.realtyInsuranceEnabled")
                                )
                              ]}
                              initialValue={false}
                            >
                              <Switch
                                disabled={
                                  state === BuildingState.UNDER_CONSTRUCTION ||
                                  policyHolderType === ClientType.SELF_EMPLOYED ||
                                  policyHolderType === ClientType.LEGAL
                                }
                                checkedChildren={<AntIcon type="check" />}
                                unCheckedChildren={<AntIcon type="close" />}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        {state === BuildingState.UNDER_CONSTRUCTION && (
                          <Alert
                            message={t("calc.realty.helpers.householdInsuranceDisabledBuildingType")}
                            type="info"
                            showIcon
                          />
                        )}

                        {(policyHolderType === ClientType.SELF_EMPLOYED || policyHolderType === ClientType.LEGAL) && (
                          <Alert
                            message={t("calc.realty.helpers.householdInsuranceDisabledClientType")}
                            type="info"
                            showIcon
                          />
                        )}

                        {householdInsuranceEnabled && (
                          <>
                            <Divider className="divider-subheader">
                              {t("calc.realty.sections.householdInsurance")}
                            </Divider>

                            <Row gutter={rowGutter}>
                              <Col span={twoColumnColSpan}>
                                <Form.Item
                                  name={["householdInsuranceData", "insuranceAmount"]}
                                  label={t("calc.realty.attrs.householdInsuranceData.insuranceAmount")}
                                  rules={[validations.notNull, validations.minNumber(1), validations.multipleOf100]}
                                >
                                  <InputNumber
                                    {...inputNumberIntegerStandardProps}
                                    addonAfter={<InputAddon type="euro" />}
                                    step={100}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Divider className="divider-subheader" style={{ marginTop: "16px" }}>
                              {t("calc.realty.sections.additionalHouseholdAmounts")}
                            </Divider>

                            <Row gutter={rowGutter}>
                              <Form.Item
                                noStyle
                                shouldUpdate={(prev, next) =>
                                  prev.generalBuildingData.permanentlyOccupied !==
                                  next.generalBuildingData.permanentlyOccupied
                                }
                              >
                                {({ getFieldValue }) => {
                                  const permanentlyOccupied = getFieldValue([
                                    "generalBuildingData",
                                    "permanentlyOccupied"
                                  ]);
                                  return (
                                    <>
                                      <Col span={twoColumnColSpan}>
                                        <Form.Item
                                          name={["householdInsuranceData", "electronicsInsuranceAmount"]}
                                          label={
                                            <LabelWithTooltip
                                              tooltip={t("calc.realty.helpers.electronicsInsuranceAmountDesc")}
                                              label={t(
                                                "calc.realty.attrs.householdInsuranceData.electronicsInsuranceAmount"
                                              )}
                                            />
                                          }
                                          rules={[
                                            validations.minNumber(1),
                                            validations.maxNumber(permanentlyOccupied ? 20_000 : 2_000),
                                            validations.multipleOf100
                                          ]}
                                        >
                                          <InputNumber
                                            {...inputNumberIntegerStandardProps}
                                            addonAfter={<InputAddon type="euro" />}
                                            step={100}
                                          />
                                        </Form.Item>
                                      </Col>

                                      <Col span={twoColumnColSpan}>
                                        <Form.Item
                                          name={["householdInsuranceData", "artworksInsuranceAmount"]}
                                          label={
                                            <LabelWithTooltip
                                              tooltip={t("calc.realty.helpers.artworksInsuranceAmountDesc")}
                                              label={t(
                                                "calc.realty.attrs.householdInsuranceData.artworksInsuranceAmount"
                                              )}
                                            />
                                          }
                                          rules={[
                                            validations.minNumber(1),
                                            validations.maxNumber(10_000),
                                            validations.multipleOf100
                                          ]}
                                        >
                                          <InputNumber
                                            {...inputNumberIntegerStandardProps}
                                            addonAfter={<InputAddon type="euro" />}
                                            step={100}
                                            disabled={!permanentlyOccupied}
                                          />
                                        </Form.Item>
                                      </Col>

                                      <Col span={twoColumnColSpan}>
                                        <Form.Item
                                          name={["householdInsuranceData", "valuablesInsuranceAmount"]}
                                          label={
                                            <LabelWithTooltip
                                              tooltip={t("calc.realty.helpers.valuablesInsuranceAmountDesc")}
                                              label={t(
                                                "calc.realty.attrs.householdInsuranceData.valuablesInsuranceAmount"
                                              )}
                                            />
                                          }
                                          rules={[
                                            validations.minNumber(1),
                                            validations.maxNumber(10_000),
                                            validations.multipleOf100
                                          ]}
                                        >
                                          <InputNumber
                                            {...inputNumberIntegerStandardProps}
                                            addonAfter={<InputAddon type="euro" />}
                                            step={100}
                                            disabled={!permanentlyOccupied}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </>
                                  );
                                }}
                              </Form.Item>
                            </Row>

                            <Alert
                              message={t("calc.realty.helpers.additionalInsuranceAmount")}
                              type="info"
                              showIcon
                              className="margin-top-medium"
                              closable
                            />

                            <Divider className="divider-subheader" style={{ marginTop: "16px" }}>
                              {t("calc.realty.sections.equipmentReinsurances")}
                              <LabelWithPopover
                                popoverTitle={t("calc.realty.helpers.householdEquipmentDesc.title")}
                                popoverContent={
                                  <table className="data-table-view wide-margins" style={{ maxWidth: "500px" }}>
                                    <tbody>
                                      {[1, 2, 3, 4].map(value => (
                                        <tr key={value}>
                                          <td>{t("calc.realty.helpers.householdEquipmentDesc.institution" + value)}</td>
                                          <td>{t("calc.realty.helpers.householdEquipmentDesc.value" + value)}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                }
                                className="margin-left-tiny"
                              />
                            </Divider>

                            <Form.List name={["householdInsuranceData", "equipmentReinsurances"]}>
                              {(fields, { add, remove }) => (
                                <>
                                  {fields.map(field => (
                                    <Row gutter={rowGutter} key={field.key}>
                                      <Col span={twoColumnColSpan}>
                                        <Form.Item
                                          name={[field.name, "type"]}
                                          label={t("calc.realty.enums.householdEquipmentType._label")}
                                          rules={[validations.notNull]}
                                        >
                                          <Select
                                            {...selectStandardProps}
                                            options={Object.keys(HouseholdEquipmentType)
                                              .filter(type =>
                                                realtyInsuranceEnabled
                                                  ? type !== HouseholdEquipmentType.STRUCTURAL_COMPONENTS
                                                  : true
                                              )
                                              .map(type => ({
                                                value: type,
                                                label: t("calc.realty.enums.householdEquipmentType." + type)
                                              }))}
                                          />
                                        </Form.Item>
                                      </Col>

                                      <Col span={twoColumnColSpan}>
                                        <Form.Item
                                          name={[field.name, "insuranceAmount"]}
                                          label={t(
                                            "calc.realty.attrs.householdInsuranceData.equipmentReinsurances.insuranceAmount"
                                          )}
                                          rules={[
                                            validations.notNull,
                                            validations.minNumber(1),
                                            validations.maxNumber(20000),
                                            validations.multipleOf100
                                          ]}
                                        >
                                          <InputNumber
                                            {...inputNumberIntegerStandardProps}
                                            addonAfter={<InputAddon type="euro" />}
                                            step={100}
                                          />
                                        </Form.Item>
                                      </Col>

                                      <Col span={1} className="right-align" style={{ paddingLeft: 0 }}>
                                        <DeleteIcon onClick={() => remove(field.name)} />
                                      </Col>
                                    </Row>
                                  ))}

                                  <ActionButton
                                    icon="plus"
                                    className="margin-top-small"
                                    label={t("calc.realty.actions.householdEquipmentAdd")}
                                    disabled={fields.length >= 10}
                                    onClick={() => add()}
                                  />
                                </>
                              )}
                            </Form.List>
                          </>
                        )}
                      </>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          );
        }}
      </Form.Item>

      <Divider className="divider-subheader" orientation="left">
        {t("calc.realty.attrs.liabilityInsuranceData.liabilityFromRealtyAndHousehold")}
      </Divider>

      <Row gutter={rowGutter} className="margin-top-medium">
        <Col span={colSpan}>
          <Form.Item
            name={["liabilityInsuranceData", "liability"]}
            label={
              <LabelWithPopover
                label={t("calc.realty.enums.liability._label")}
                popoverTitle={t("calc.realty.helpers.liabilityDesc.title")}
                popoverContent={
                  <table className="data-table-view wide-margins">
                    <tbody>
                      {[1, 2, 3, 4, 5, 6, 7, 8].map(value => (
                        <tr key={value}>
                          <td>{t("calc.realty.helpers.liabilityDesc.institution" + value)}</td>
                          <td>{t("calc.realty.helpers.liabilityDesc.value" + value)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
              />
            }
            rules={[validations.notNull]}
          >
            <Select
              {...selectStandardProps}
              options={Object.keys(Liability).map(liability => ({
                value: liability,
                label: t("calc.realty.enums.liability." + liability)
              }))}
            />
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) =>
            prev.liabilityInsuranceData?.liability !== next.liabilityInsuranceData?.liability
          }
        >
          {({ getFieldValue }) => {
            const liability = getFieldValue(["liabilityInsuranceData", "liability"]) as Liability;
            return (
              liability &&
              liability !== Liability.NONE && (
                <Col span={colSpan + 1}>
                  <Form.Item
                    name={["liabilityInsuranceData", "extendedLiability"]}
                    className="form-item-without-label"
                    valuePropName="checked"
                    initialValue={false}
                    rules={[validations.none]}
                  >
                    <Checkbox style={{ maxHeight: 22 }}>
                      <LabelWithPopover
                        label={t("calc.realty.attrs.liabilityInsuranceData.extendedLiability")}
                        popoverTitle={t("calc.realty.helpers.extendedLiabilityDesc.title")}
                        popoverContent={
                          <div style={{ maxWidth: "550px" }}>
                            <table className="data-table-view wide-margins">
                              <tbody>
                                {[1, 2].map(value => (
                                  <tr key={value}>
                                    <td>{t("calc.realty.helpers.extendedLiabilityDesc.institution" + value)}</td>
                                    <td>{t("calc.realty.helpers.extendedLiabilityDesc.value" + value)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        }
                      />
                    </Checkbox>
                  </Form.Item>
                </Col>
              )
            );
          }}
        </Form.Item>
      </Row>
    </Card>
  );
};

export default RealtyCalcHouseholdRealtySection;
