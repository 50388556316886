import { Col, Row, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import t from "../../../../app/i18n";
import ActionButton from "../../../../common/components/buttons/ActionButton";
import { ContentType, rowGutter } from "../../../../common/constants";
import messageUtils from "../../../../common/utils/messageUtils";
import { CalcAttachmentType } from "../enums";

interface Props {
  type: CalcAttachmentType;
  onChange: (type: CalcAttachmentType, file?: RcFile) => void;
}

const CalcAttachmentUpload = ({ type, onChange }: Props) => {
  const handleFileUpload = (file: RcFile): boolean | string => {
    if (file.type !== ContentType.PDF) {
      messageUtils.errorNotification({
        message: t("common.error"),
        description: t("calc.helpers.unsupportedAttachmentType")
      });
      return Upload.LIST_IGNORE;
    }
    onChange(type, file);
    return false;
  };

  const handleFileRemove = (): boolean => {
    onChange(type);
    return true;
  };

  return (
    <Row gutter={rowGutter}>
      <Col span={10}>
        <b>{t("calc.enums.calcAttachmentType." + type)}:</b>
      </Col>

      <Col span={14}>
        <Upload maxCount={1} accept=".pdf" beforeUpload={handleFileUpload} onRemove={handleFileRemove}>
          <ActionButton icon="plus" label={t("common.add")} />
        </Upload>
      </Col>
    </Row>
  );
};

export default CalcAttachmentUpload;
