import "dayjs/locale/sk";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import i18n, { TOptions } from "i18next";
import intervalPluralPostProcessor from "i18next-intervalplural-postprocessor";
import { initReactI18next } from "react-i18next";
import locales from "./locales";

export const DEFAULT_LOCALE = "sk";
export const I18N_NAMESPACE = "cliip";

export const i18nResources = {
  [DEFAULT_LOCALE]: {
    [I18N_NAMESPACE]: locales.sk
  }
};

i18n
  .use(initReactI18next)
  .use(intervalPluralPostProcessor)
  .init({
    defaultNS: I18N_NAMESPACE,
    ns: I18N_NAMESPACE,
    lng: DEFAULT_LOCALE,
    fallbackLng: DEFAULT_LOCALE,
    debug: import.meta.env.NODE_ENV === "development" && import.meta.env.VITE_ACTIVE_PROFILE === "dev",
    resources: i18nResources,
    returnEmptyString: false,
    keySeparator: ".",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n.t as (key: string, options?: TOptions) => string;

dayjs.extend(timezone);
dayjs.locale(DEFAULT_LOCALE);
dayjs.tz.setDefault("Europe/Bratislava");
dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.updateLocale(DEFAULT_LOCALE, {
  formats: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD.MM.YYYY",
    LL: "D. MMMM YYYY",
    LLL: "D. MMMM YYYY H:mm",
    LLLL: "dddd D. MMMM YYYY H:mm"
  }
});
