import { Form, Modal } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import t from "../../../../app/i18n";
import { Permission } from "../../../../common/security/authorization/enums";
import { FieldConstraintViolation, RootState } from "../../../../common/types";
import { resolveFormValidationError, useFormErrorHandler } from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import messageUtils from "../../../../common/utils/messageUtils";
import { hasPermission, isDefinedValue, openUrl } from "../../../../common/utils/utils";
import { selectPermissions } from "../../../auth/ducks";
import { CalcValidationGroup, ClientType } from "../../../client/enums";
import { Client, CreateUpdateContractClient, NaturalClient } from "../../../client/types";
import {
  clientToCreateUpdateContractClient,
  processClientsDataViolations,
  useClientValidation
} from "../../../client/utils";
import { ProductFinancialSector } from "../../../product/enums";
import { PRODUCT_SECTOR_TO_UPDATE_CONTRACT_PERMISSION_MAP } from "../../../product/utils";
import { requests } from "../../api";
import {
  assignFinancialMediationActions,
  changeFinancialMediationVersionActions,
  createFinancialMediationActions,
  deleteFinancialMediationActions,
  updateFinancialMediationActions
} from "../../ducks";
import {
  FinancialMediationStatus,
  FinancialMediationVersion,
  InvestmentQuestionnaireClientStatement,
  MediationClientFormType
} from "../../enums";
import { FINANCIAL_MEDIATION_ROUTE_PATHS } from "../../paths";
import {
  AllFinancialMediationDataTypes,
  CreateUpdateFinancialMediation,
  DepositFinancialMediationData,
  DepositSpecificRequirement,
  FinancialMediation,
  FinancialMediationFormClients,
  FinancialMediationRecommendationData
} from "../../types";
import {
  FINANCIAL_MEDIATION_CHILDREN_SECTORS,
  FINANCIAL_MEDIATION_CLIENTS_WITH_ID_CARD,
  FINANCIAL_MEDIATION_SECONDARY_CLIENT_SECTORS,
  QUESTIONNAIRE_MULTIPLE_ANSWERS_QUESTIONS_NUMBERS
} from "../../utils";
import FinancialMediationAssignForm from "./FinancialMediationAssignForm";
import FinancialMediationFormClientDataSection from "./sections/FinancialMediationFormClientDataSection";
import FinancialMediationFormClientsSection from "./sections/FinancialMediationFormClientsSection";
import FinancialMediationFormHeaderSection from "./sections/FinancialMediationFormHeaderSection";
import FinancialMediationFormLifeQuestionnaireSection from "./sections/FinancialMediationFormLifeQuestionnaireSection";
import FinancialMediationFormOtherDataSection from "./sections/FinancialMediationFormOtherDataSection";
import FinancialMediationFormPillarsQuestionnaireSection from "./sections/FinancialMediationFormPillarsQuestionnaireSection";
import FinancialMediationFormRecommendationsSection from "./sections/FinancialMediationFormRecommendationsSection";
import FinancialMediationFormRequirementsSection from "./sections/FinancialMediationFormRequirementsSection";

interface Props {
  financialMediation?: FinancialMediation;
  onCreate?: typeof createFinancialMediationActions.request;
  onUpdate?: typeof updateFinancialMediationActions.request;
  onDelete?: typeof deleteFinancialMediationActions.request;
  onChangeVersion?: typeof changeFinancialMediationVersionActions.request;
  onAssign?: typeof assignFinancialMediationActions.request;
}

const FinancialMediationForm = ({ financialMediation, ...props }: Props) => {
  const [form] = Form.useForm<CreateUpdateFinancialMediation>();
  const sector = Form.useWatch("sector", form);
  const validateAllFields = Form.useWatch("validateAllFields", form) as boolean;

  const [formValuesChanged, setFormValuesChanged] = useState<boolean>(false);

  const errorResponse = useFormErrorHandler(form, "financialMediation.attrs", [
    requests.CREATE_FINANCIAL_MEDIATION,
    requests.UPDATE_FINANCIAL_MEDIATION
  ]);

  useRequestFinishedCallback([requests.UPDATE_FINANCIAL_MEDIATION], () => {
    if (formValuesChanged) {
      setFormValuesChanged(false);
    }
  });

  const permissions = useSelector<RootState, Permission[]>(selectPermissions);

  const clientValidation = useClientValidation();

  const createFormClients = (mediation?: FinancialMediation): FinancialMediationFormClients => ({
    policyHolder: mediation?.clients[mediation.policyHolderIndex],
    secondaryClient: mediation?.clients[mediation.secondaryClientIndex],
    policyHolderRepresentative: mediation?.clients[mediation.policyHolderRepresentativeIndex],
    secondaryClientRepresentative: mediation?.clients[mediation.secondaryClientRepresentativeIndex],
    child1: mediation?.clients[mediation.child1Index],
    child2: mediation?.clients[mediation.child2Index],
    child3: mediation?.clients[mediation.child3Index],
    child4: mediation?.clients[mediation.child4Index]
  });

  const [clients, setClients] = useState<FinancialMediationFormClients>(() => createFormClients(financialMediation));
  const [clientsViolationErrors, setClientsViolationErrors] = useState<
    Map<MediationClientFormType, FieldConstraintViolation[]>
  >(new Map());

  const [secondaryClientActive, setSecondaryClientActive] = useState<boolean>(
    !!financialMediation?.clients[financialMediation.secondaryClientIndex]
  );
  const [childrenCount, setChildrenCount] = useState<number>(
    [
      financialMediation?.child1Index,
      financialMediation?.child2Index,
      financialMediation?.child3Index,
      financialMediation?.child4Index
    ].filter(i => isDefinedValue(i)).length
  );

  const [representative1Active, setRepresentative1Active] = useState<boolean>(() => {
    const policyHolderType = financialMediation?.clients[financialMediation.policyHolderIndex]?.type;
    return (
      !!financialMediation?.clients[financialMediation.policyHolderRepresentativeIndex] &&
      (policyHolderType === ClientType.NATURAL || policyHolderType === ClientType.SELF_EMPLOYED)
    );
  });
  const [representative2Active, setRepresentative2Active] = useState<boolean>(() => {
    const secondaryClientType = financialMediation?.clients[financialMediation.secondaryClientIndex]?.type;
    return (
      !!financialMediation?.clients[financialMediation.secondaryClientRepresentativeIndex] &&
      (secondaryClientType === ClientType.NATURAL || secondaryClientType === ClientType.SELF_EMPLOYED)
    );
  });

  const [assignFormOpen, setAssignFormOpen] = useState<boolean>(false);

  const showSecondaryClient = secondaryClientActive && FINANCIAL_MEDIATION_SECONDARY_CLIENT_SECTORS.includes(sector);

  useEffect(() => {
    return () => {
      clientValidation.onErrorResponseDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (financialMediation) {
      const { policyHolderData, secondaryClientData, investmentQuestionnaireData, ...otherData } =
        financialMediation.mediationData as AllFinancialMediationDataTypes;

      const initValues = {
        sector: financialMediation.sector,
        mediationData: {
          ...otherData,
          policyHolderData: {
            ...policyHolderData,
            refuseToProvideFinancialSituationInfo: !!policyHolderData.refuseToProvideFinancialSituationInfo
          },
          secondaryClientData: secondaryClientData
            ? {
                ...secondaryClientData,
                refuseToProvideFinancialSituationInfo: !!secondaryClientData.refuseToProvideFinancialSituationInfo
              }
            : null,
          investmentQuestionnaireData: investmentQuestionnaireData
            ? {
                ...investmentQuestionnaireData,
                answers: investmentQuestionnaireData.answers?.map((answer, index) =>
                  QUESTIONNAIRE_MULTIPLE_ANSWERS_QUESTIONS_NUMBERS.includes(index + 1)
                    ? answer || []
                    : (answer as number[])?.[0]
                ),
                refuseToAnswerQuestions1To9: !!investmentQuestionnaireData.refuseToAnswerQuestions1To9,
                refuseToAnswerQuestion10: !!investmentQuestionnaireData.refuseToAnswerQuestion10,
                refuseToAnswerQuestions11To13: !!investmentQuestionnaireData.refuseToAnswerQuestions11To13,
                refuseToAnswerQuestions15To16: !!investmentQuestionnaireData.refuseToAnswerQuestions15To16,
                refuseToAnswerQuestions1To7: !!investmentQuestionnaireData.refuseToAnswerQuestions1To7,
                refuseToAnswerQuestions8To10: !!investmentQuestionnaireData.refuseToAnswerQuestions8To10,
                refuseToAnswerQuestions12To13: !!investmentQuestionnaireData.refuseToAnswerQuestions12To13
              }
            : null,
          signDate: otherData.signDate
        } as AllFinancialMediationDataTypes,
        policyHolderIdentifier: financialMediation.clients[financialMediation.policyHolderIndex]?.identifier,
        secondaryClientIdentifier: financialMediation.clients[financialMediation.secondaryClientIndex]?.identifier,
        policyHolderRepresentativeIdentifier:
          financialMediation.clients[financialMediation.policyHolderRepresentativeIndex]?.identifier,
        secondaryClientRepresentativeIdentifier:
          financialMediation.clients[financialMediation.secondaryClientRepresentativeIndex]?.identifier,
        child1Identifier: financialMediation.clients[financialMediation.child1Index]?.identifier,
        child2Identifier: financialMediation.clients[financialMediation.child2Index]?.identifier,
        child3Identifier: financialMediation.clients[financialMediation.child3Index]?.identifier,
        child4Identifier: financialMediation.clients[financialMediation.child4Index]?.identifier,
        child1RepresentativeIdentifier:
          financialMediation.clients[financialMediation.child1RepresentativeIndex]?.identifier,
        child2RepresentativeIdentifier:
          financialMediation.clients[financialMediation.child2RepresentativeIndex]?.identifier,
        child3RepresentativeIdentifier:
          financialMediation.clients[financialMediation.child3RepresentativeIndex]?.identifier,
        child4RepresentativeIdentifier:
          financialMediation.clients[financialMediation.child4RepresentativeIndex]?.identifier,
        validateAllFields: false
      };

      if (financialMediation.sector === ProductFinancialSector.DEPOSITS) {
        (initValues.mediationData as DepositFinancialMediationData).clientSpecificRequirements = (
          (otherData?.clientSpecificRequirements || []) as DepositSpecificRequirement[]
        ).map(r => ({ ...r, selected: !!r?.selected }));
      }

      form.setFieldsValue(initValues);
      setClients(createFormClients(financialMediation));
    }
  }, [financialMediation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorResponse?.violations?.some(violation => violation.fieldPath.startsWith("clients"))) {
      setClientsViolationErrors(
        new Map([
          ...clientsViolationErrors,
          ...processClientsDataViolations(
            createClientsIndicesMap(clients),
            "clients",
            errorResponse.violations.filter(violation => violation.fieldPath.startsWith("clients"))
          )
        ])
      );
    }
  }, [errorResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (clientValidation.errorResponse?.violations?.length) {
      setClientsViolationErrors(
        new Map([
          ...clientsViolationErrors,
          ...processClientsDataViolations(
            createClientsIndicesMap(clients),
            "clients",
            clientValidation.errorResponse.violations
          )
        ])
      );
    }
  }, [clientValidation.errorResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (validateAllFields) {
      handleFormSubmit();
    }
  }, [validateAllFields]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClientChange = (type: MediationClientFormType, client?: Client): void => {
    setFormValuesChanged(true);

    if (client) {
      const clients = setClient(client, type);
      clientValidation.onValidate({
        prefix: `clients[${createClientsIndicesMap(clients).get(type)}]`,
        client: clientToCreateUpdateContractClient(client),
        validationGroup: FINANCIAL_MEDIATION_CLIENTS_WITH_ID_CARD.includes(type)
          ? CalcValidationGroup.GENERATE
          : undefined
      });
    } else {
      switch (type) {
        case MediationClientFormType.POLICY_HOLDER:
          form.setFieldsValue({
            mediationData: { policyHolderData: { email: undefined, phone: undefined, marketingConsent: undefined } }
          });
          break;
        case MediationClientFormType.SECONDARY_CLIENT:
          form.setFieldsValue({
            mediationData: { secondaryClientData: { email: undefined, phone: undefined, marketingConsent: undefined } }
          });
          break;
      }
    }
  };

  const handleClientViolationErrorsChange = (
    type: MediationClientFormType,
    violations?: FieldConstraintViolation[]
  ): void => {
    const updatedViolationsErrors = new Map<MediationClientFormType, FieldConstraintViolation[]>([
      ...clientsViolationErrors
    ]);
    if (violations?.length) {
      updatedViolationsErrors.set(type, violations);
    } else {
      updatedViolationsErrors.delete(type);
    }
    setClientsViolationErrors(updatedViolationsErrors);
  };

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        const clientsIndices = createClientsIndicesMap(clients);
        const processedClients = createContractClientsArray(clients);
        const clientsViolationErrorsKeys = [...clientsViolationErrors.keys()];

        if ([...clientsIndices.keys()].some(clientType => clientsViolationErrorsKeys.includes(clientType))) {
          messageUtils.errorNotification({
            message: t("common.error"),
            description: t("financialMediation.validations.formError")
          });
        } else {
          const processedData = { ...values.mediationData } as AllFinancialMediationDataTypes;

          processedData.childrenRelationships = processedData.childrenRelationships || [];
          processedData.generalAttachments = processedData.generalAttachments || [];
          processedData.sectorAttachments = processedData.sectorAttachments || [];
          processedData.recommendationData = {
            ...(processedData.recommendationData as FinancialMediationRecommendationData),
            recommendations: processedData.recommendationData?.recommendations || []
          };

          if (
            (sector === ProductFinancialSector.LIFE_INSURANCES ||
              sector === ProductFinancialSector.SENIOR_PENSION_SAVINGS ||
              sector === ProductFinancialSector.SUPPLEMENTARY_PENSION_SAVINGS) &&
            processedData.investmentQuestionnaireData?.clientStatement ===
              InvestmentQuestionnaireClientStatement.WILLING_TO_ANSWER
          ) {
            processedData.investmentQuestionnaireData = {
              ...processedData.investmentQuestionnaireData,
              answers: (processedData.investmentQuestionnaireData.answers || []).map((answer, index) =>
                QUESTIONNAIRE_MULTIPLE_ANSWERS_QUESTIONS_NUMBERS.includes(index + 1)
                  ? (answer as number[]) || []
                  : isDefinedValue(answer)
                    ? ([answer] as number[])
                    : []
              ),
              informedAboutRisks: processedData.investmentQuestionnaireData.informedAboutRisks || []
            };
          }

          switch (sector) {
            case ProductFinancialSector.NON_LIFE_INSURANCES:
              processedData.clientRequirements = processedData.clientRequirements || [];
              break;
            case ProductFinancialSector.LIFE_INSURANCES:
              processedData.clientRequirements = processedData.clientRequirements || [];
              processedData.clientRiskRequirements = processedData.clientRiskRequirements || [];
              break;
            case ProductFinancialSector.DEPOSITS:
              processedData.clientRequirements = processedData.clientRequirements || [];
              processedData.clientSpecificRequirements = processedData.clientSpecificRequirements || [];
              break;
            case ProductFinancialSector.SENIOR_PENSION_SAVINGS:
            case ProductFinancialSector.SUPPLEMENTARY_PENSION_SAVINGS:
              processedData.clientRequirements = processedData.clientRequirements || [];
              break;
          }

          const processedValues = {
            ...values,
            optimisticLockVersion: financialMediation?.optimisticLockVersion,
            status: financialMediation
              ? values.validateAllFields
                ? FinancialMediationStatus.FINISHED
                : FinancialMediationStatus.UNFINISHED
              : undefined,
            sector: financialMediation ? undefined : values.sector,
            mediationData: processedData,
            clients: processedClients,
            policyHolderIndex: clientsIndices.get(MediationClientFormType.POLICY_HOLDER),
            secondaryClientIndex: clientsIndices.get(MediationClientFormType.SECONDARY_CLIENT),
            policyHolderRepresentativeIndex: clientsIndices.get(MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE),
            secondaryClientRepresentativeIndex: clientsIndices.get(
              MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE
            ),
            child1Index: clientsIndices.get(MediationClientFormType.CHILD_1),
            child2Index: clientsIndices.get(MediationClientFormType.CHILD_2),
            child3Index: clientsIndices.get(MediationClientFormType.CHILD_3),
            child4Index: clientsIndices.get(MediationClientFormType.CHILD_4),
            child1RepresentativeIndex: resolveClientIndex(processedClients, values.child1RepresentativeIdentifier),
            child2RepresentativeIndex: resolveClientIndex(processedClients, values.child2RepresentativeIdentifier),
            child3RepresentativeIndex: resolveClientIndex(processedClients, values.child3RepresentativeIdentifier),
            child4RepresentativeIndex: resolveClientIndex(processedClients, values.child4RepresentativeIdentifier),
            policyHolderIdentifier: undefined,
            secondaryClientIdentifier: undefined,
            policyHolderRepresentativeIdentifier: undefined,
            secondaryClientRepresentativeIdentifier: undefined,
            child1Identifier: undefined,
            child2Identifier: undefined,
            child3Identifier: undefined,
            child4Identifier: undefined,
            child1RepresentativeIdentifier: undefined,
            child2RepresentativeIdentifier: undefined,
            child3RepresentativeIdentifier: undefined,
            child4RepresentativeIdentifier: undefined,
            validateAllFields: undefined
          } as CreateUpdateFinancialMediation;

          if (values.validateAllFields) {
            Modal.confirm({
              title: t("financialMediation.helpers.finishConfirm"),
              okText: t("common.yes"),
              cancelText: t("common.no"),
              onOk: () => {
                if (financialMediation?.id) {
                  props.onUpdate?.({ id: financialMediation.id, object: processedValues });
                }
              }
            });
          } else {
            if (financialMediation?.id) {
              props.onUpdate?.({ id: financialMediation.id, object: processedValues });
            } else {
              props.onCreate?.(processedValues);
            }
          }
        }
      })
      .catch((errors: ValidateErrorEntity) => {
        messageUtils.errorNotification({
          message: t("common.error"),
          description: t("financialMediation.validations.formError")
        });
        resolveFormValidationError(errors);
      })
      .finally(() => {
        if (validateAllFields) {
          form.setFieldsValue({ validateAllFields: false });
        }
      });
  };

  const handleFormValuesChange = (changedValues: Partial<CreateUpdateFinancialMediation>): void => {
    if (changedValues && !formValuesChanged) {
      setFormValuesChanged(true);
    }
  };

  const handleSecondaryClientActiveToggle = (): void => {
    setSecondaryClientActive(!secondaryClientActive);
    setFormValuesChanged(true);
  };

  const handleChildAdd = (): void => {
    setChildrenCount(childrenCount + 1);
    setFormValuesChanged(true);
  };

  const handleChildRemove = (): void => {
    setChildrenCount(childrenCount - 1);
    setFormValuesChanged(true);
  };

  const handleRepresentative1Toggle = (): void => {
    setRepresentative1Active(!representative1Active);
    setFormValuesChanged(true);
  };

  const handleRepresentative2Toggle = (): void => {
    setRepresentative2Active(!representative2Active);
    setFormValuesChanged(true);
  };

  const handleGenerateClick = (version: FinancialMediationVersion): void => {
    if (!financialMediation) {
      return;
    }
    const generateCallback = () =>
      openUrl(
        window.location.origin +
          generatePath(FINANCIAL_MEDIATION_ROUTE_PATHS.file.to, { id: financialMediation.id }) +
          `?version=${version}`,
        "_blank"
      );

    if (financialMediation.version === version) {
      executeIfFormValuesNotChanged(generateCallback);
    } else {
      generateCallback();
    }
  };

  const handleChangeVersionClick = (): void => {
    executeIfFormValuesNotChanged(() => {
      if (financialMediation && props.onChangeVersion) {
        props.onChangeVersion({
          id: financialMediation.id,
          object: { optimisticLockVersion: financialMediation.optimisticLockVersion }
        });
      }
    });
  };

  const handleFinishClick = (): void => {
    executeIfFormValuesNotChanged(() => form.setFieldsValue({ validateAllFields: true }));
  };

  const handleAssignClick = (status: FinancialMediationStatus): void => {
    switch (status) {
      case FinancialMediationStatus.ASSIGNED_TO_CONTRACT:
        setAssignFormOpen(true);
        break;
      case FinancialMediationStatus.ASSIGNED_TO_CLIENT:
        executeIfFormValuesNotChanged(() =>
          Modal.confirm({
            title: t("financialMediation.helpers.finishWithoutContractConfirm"),
            okText: t("common.yes"),
            cancelText: t("common.no"),
            onOk: () => {
              if (financialMediation && props.onAssign) {
                props.onAssign({
                  id: financialMediation.id,
                  object: { optimisticLockVersion: financialMediation.optimisticLockVersion, status }
                });
              }
            }
          })
        );
        break;
    }
  };

  const executeIfFormValuesNotChanged = (callback: () => void): void => {
    if (formValuesChanged) {
      messageUtils.warnNotification({
        message: t("common.warning"),
        description: t("financialMediation.validations.unsavedChanges")
      });
    } else {
      callback();
    }
  };

  const setClient = (client: Client, type: MediationClientFormType): FinancialMediationFormClients => {
    let updatedClients: FinancialMediationFormClients;

    switch (type) {
      case MediationClientFormType.POLICY_HOLDER:
        updatedClients = { ...clients, policyHolder: client };
        break;
      case MediationClientFormType.SECONDARY_CLIENT:
        updatedClients = { ...clients, secondaryClient: client };
        break;
      case MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE:
        updatedClients = { ...clients, policyHolderRepresentative: client };
        break;
      case MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE:
        updatedClients = { ...clients, secondaryClientRepresentative: client };
        break;
      case MediationClientFormType.CHILD_1:
        updatedClients = { ...clients, child1: client };
        break;
      case MediationClientFormType.CHILD_2:
        updatedClients = { ...clients, child2: client };
        break;
      case MediationClientFormType.CHILD_3:
        updatedClients = { ...clients, child3: client };
        break;
      case MediationClientFormType.CHILD_4:
        updatedClients = { ...clients, child4: client };
        break;
    }

    setClients(updatedClients);
    return updatedClients;
  };

  const createClientsIndicesMap = (clients: FinancialMediationFormClients): Map<MediationClientFormType, number> => {
    const {
      policyHolder,
      secondaryClient,
      policyHolderRepresentative,
      secondaryClientRepresentative,
      child1,
      child2,
      child3,
      child4
    } = clients;
    const indices = new Map<MediationClientFormType, number>();
    let index = 0;

    if (policyHolder) {
      indices.set(MediationClientFormType.POLICY_HOLDER, index++);
    }

    if (FINANCIAL_MEDIATION_SECONDARY_CLIENT_SECTORS.includes(sector) && secondaryClientActive && secondaryClient) {
      indices.set(MediationClientFormType.SECONDARY_CLIENT, index++);
    }

    if (
      policyHolder &&
      policyHolderRepresentative &&
      (representative1Active || policyHolder.type === ClientType.LEGAL)
    ) {
      indices.set(MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE, index++);
    }

    if (
      FINANCIAL_MEDIATION_SECONDARY_CLIENT_SECTORS.includes(sector) &&
      secondaryClientActive &&
      secondaryClient &&
      secondaryClientRepresentative &&
      (representative2Active || secondaryClient.type === ClientType.LEGAL)
    ) {
      indices.set(MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE, index++);
    }

    if (
      FINANCIAL_MEDIATION_CHILDREN_SECTORS.includes(sector) &&
      (policyHolder?.type === ClientType.NATURAL || secondaryClient?.type === ClientType.NATURAL)
    ) {
      if (childrenCount >= 1 && child1) {
        indices.set(MediationClientFormType.CHILD_1, index++);
      }
      if (childrenCount >= 2 && child2) {
        indices.set(MediationClientFormType.CHILD_2, index++);
      }
      if (childrenCount >= 3 && child3) {
        indices.set(MediationClientFormType.CHILD_3, index++);
      }
      if (childrenCount >= 4 && child4) {
        indices.set(MediationClientFormType.CHILD_4, index++);
      }
    }

    return indices;
  };

  const createContractClientsArray = (clients: FinancialMediationFormClients): CreateUpdateContractClient[] => {
    const {
      policyHolder,
      secondaryClient,
      policyHolderRepresentative,
      secondaryClientRepresentative,
      child1,
      child2,
      child3,
      child4
    } = clients;
    const createUpdateClients: CreateUpdateContractClient[] = [];

    if (policyHolder) {
      createUpdateClients.push(clientToCreateUpdateContractClient(policyHolder));
    }

    if (FINANCIAL_MEDIATION_SECONDARY_CLIENT_SECTORS.includes(sector) && secondaryClientActive && secondaryClient) {
      createUpdateClients.push(clientToCreateUpdateContractClient(secondaryClient));
    }

    if (
      policyHolder &&
      policyHolderRepresentative &&
      (representative1Active || policyHolder.type === ClientType.LEGAL)
    ) {
      createUpdateClients.push(clientToCreateUpdateContractClient(policyHolderRepresentative));
    }

    if (
      FINANCIAL_MEDIATION_SECONDARY_CLIENT_SECTORS.includes(sector) &&
      secondaryClientActive &&
      secondaryClient &&
      secondaryClientRepresentative &&
      (representative2Active || secondaryClient.type === ClientType.LEGAL)
    ) {
      createUpdateClients.push(clientToCreateUpdateContractClient(secondaryClientRepresentative));
    }

    if (
      FINANCIAL_MEDIATION_CHILDREN_SECTORS.includes(sector) &&
      (policyHolder?.type === ClientType.NATURAL || secondaryClient?.type === ClientType.NATURAL)
    ) {
      if (childrenCount >= 1 && child1) {
        createUpdateClients.push(clientToCreateUpdateContractClient(child1));
      }
      if (childrenCount >= 2 && child2) {
        createUpdateClients.push(clientToCreateUpdateContractClient(child2));
      }
      if (childrenCount >= 3 && child3) {
        createUpdateClients.push(clientToCreateUpdateContractClient(child3));
      }
      if (childrenCount >= 4 && child4) {
        createUpdateClients.push(clientToCreateUpdateContractClient(child4));
      }
    }

    return createUpdateClients;
  };

  const resolveClientIndex = (clients: CreateUpdateContractClient[], identifier?: string): number | undefined => {
    if (identifier) {
      const index = clients.findIndex(c => (c.client as NaturalClient).personalIdentificationNumber === identifier);
      return index === -1 ? undefined : index;
    }
    return undefined;
  };

  const formDisabled =
    financialMediation &&
    (financialMediation.status !== FinancialMediationStatus.UNFINISHED ||
      !hasPermission(permissions, PRODUCT_SECTOR_TO_UPDATE_CONTRACT_PERMISSION_MAP.get(sector) as Permission));

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        name="financialMediationForm"
        disabled={formDisabled}
        onValuesChange={handleFormValuesChange}
      >
        <FinancialMediationFormHeaderSection
          form={form}
          financialMediation={financialMediation}
          permissions={permissions}
          onFormSubmit={handleFormSubmit}
          onGenerateClick={handleGenerateClick}
          onChangeVersionClick={handleChangeVersionClick}
          onFinishClick={handleFinishClick}
          onAssignClick={handleAssignClick}
          onDelete={props.onDelete}
        />

        {sector ? (
          <>
            <FinancialMediationFormClientsSection
              form={form}
              formDisabled={formDisabled}
              sector={sector}
              clients={clients}
              clientsViolationErrors={clientsViolationErrors}
              secondaryClientActive={secondaryClientActive}
              childrenCount={childrenCount}
              representative1Active={representative1Active}
              representative2Active={representative2Active}
              validateAllFields={validateAllFields}
              onClientChange={handleClientChange}
              onClientViolationErrorsChange={handleClientViolationErrorsChange}
              onSecondaryClientActiveToggle={handleSecondaryClientActiveToggle}
              onChildAdd={handleChildAdd}
              onChildRemove={handleChildRemove}
              onRepresentative1Toggle={handleRepresentative1Toggle}
              onRepresentative2Toggle={handleRepresentative2Toggle}
              onClientFormValuesChange={() => setFormValuesChanged(true)}
            />

            <FinancialMediationFormClientDataSection
              form={form}
              sector={sector}
              showSecondaryClient={showSecondaryClient}
              validateAllFields={validateAllFields}
            />

            <FinancialMediationFormRequirementsSection
              sector={sector}
              showSecondaryClient={showSecondaryClient}
              validateAllFields={validateAllFields}
            />

            {sector === ProductFinancialSector.LIFE_INSURANCES && (
              <FinancialMediationFormLifeQuestionnaireSection form={form} validateAllFields={validateAllFields} />
            )}

            {(sector === ProductFinancialSector.SENIOR_PENSION_SAVINGS ||
              sector === ProductFinancialSector.SUPPLEMENTARY_PENSION_SAVINGS) && (
              <FinancialMediationFormPillarsQuestionnaireSection form={form} validateAllFields={validateAllFields} />
            )}

            <FinancialMediationFormRecommendationsSection
              form={form}
              formDisabled={formDisabled}
              sector={sector}
              validateAllFields={validateAllFields}
            />

            <FinancialMediationFormOtherDataSection
              formDisabled={formDisabled}
              sector={sector}
              showSecondaryClient={showSecondaryClient}
              validateAllFields={validateAllFields}
              onFormSubmit={handleFormSubmit}
            />
          </>
        ) : (
          <div className="center-align sub-header-info dashed">{t("financialMediation.helpers.noSectorSelected")}</div>
        )}
      </Form>

      {(financialMediation?.status === FinancialMediationStatus.FINISHED ||
        financialMediation?.status === FinancialMediationStatus.ASSIGNED_TO_CONTRACT) &&
        props.onAssign && (
          <FinancialMediationAssignForm
            open={assignFormOpen}
            financialMediation={financialMediation}
            onAssign={props.onAssign}
            onFormCancel={() => setAssignFormOpen(false)}
          />
        )}
    </>
  );
};

export default FinancialMediationForm;
