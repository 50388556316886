import AgentActiveTag from "../../../../modules/agent/components/AgentActiveTag";
import AgentCanBeGainerTag from "../../../../modules/agent/components/AgentCanBeGainerTag";
import AgentTypeTag from "../../../../modules/agent/components/AgentTypeTag";
import { AgentType } from "../../../../modules/agent/enums";
import AgentEnumName from "../../../../modules/enumerations/components/view/AgentEnumName";
import { accessTreePathDelimiter } from "../../../constants";
import { isDefinedValue } from "../../../utils/utils";
import {
  AgentHistoryItem,
  BooleanHistoryItem,
  DateHistoryItem,
  EmptyHistoryItem,
  FinancialSectorsHistoryItem,
  IbanHistoryItem,
  NoteHistoryItem,
  PhoneHistoryItem
} from "./commonHistoryItems";

const AccessTreePathItem = (value: string) => {
  const tokens = (value as string).split(accessTreePathDelimiter);
  return <AgentEnumName id={tokens[tokens.length - 2] as string} />;
};

const CanBeGainerItem = (value: boolean) => <AgentCanBeGainerTag canBeGainer={value} />;

const DeactivatedItem = (value: boolean) => <AgentActiveTag agentDeactivated={value} />;

const IdNumberItem = (value: number) => <span>{value.toString().padStart(5, "0")}</span>;

const StructureIdNumberItem = (value: number) =>
  isDefinedValue(value) ? <span>{value.toString().padStart(3, "0")}</span> : EmptyHistoryItem();

const TypeItem = (value: AgentType) => <AgentTypeTag type={value} />;

export const AgentHistoryItemsMap = new Map<string, (value: any) => React.JSX.Element>([
  ["accessTreePath", AccessTreePathItem],
  ["bankAccountNumber", IbanHistoryItem],
  ["birthDate", DateHistoryItem],
  ["canBeGainer", CanBeGainerItem],
  ["deactivated", DeactivatedItem],
  ["idNumber", IdNumberItem],
  ["note", NoteHistoryItem],
  ["parent", AgentHistoryItem],
  ["phone", PhoneHistoryItem],
  ["sectors", FinancialSectorsHistoryItem],
  ["structureIdNumber", StructureIdNumberItem],
  ["tipper", BooleanHistoryItem],
  ["type", TypeItem]
]);
