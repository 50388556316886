import React from "react";
import { generatePath, Link } from "react-router-dom";
import t from "../../../../../../app/i18n";
import CopyToClipboardView from "../../../../../../common/components/views/CopyToClipboardView";
import HtmlView from "../../../../../../common/components/views/HtmlView";
import {
  formatAgentName,
  formatIban,
  formatLocaleDate,
  formatPhoneNumber
} from "../../../../../../common/utils/formatUtils";
import { AGENT_ROUTE_PATHS } from "../../../../paths";
import { NaturalAgent } from "../../../../types";

interface Props {
  agent: NaturalAgent;
}

const NaturalAgentDataView = ({ agent }: Props) => (
  <>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("agent.attrs.aggregatedName")}:</td>
      <td colSpan={3}>{agent.aggregatedName}</td>
    </tr>
    <tr>
      <td>{t("agent.attrs.personalIdentificationNumberLong")}:</td>
      <td>
        <CopyToClipboardView content={agent.personalIdentificationNumber} />
      </td>
      <td>{t("agent.attrs.birthDate")}:</td>
      <td>{formatLocaleDate(agent.birthDate)}</td>
    </tr>
    <tr>
      <td>{t("agent.attrs.identityCardNumber")}:</td>
      <td colSpan={3}>
        <CopyToClipboardView content={agent.identityCardNumber} />
      </td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("agent.attrs.nbsRegistrationNumber")}:</td>
      <td colSpan={3}>
        <CopyToClipboardView content={agent.nbsRegistrationNumber} />
      </td>
    </tr>
    <tr>
      <td>{t("common.financialSectors")}:</td>
      <td colSpan={3}>{agent.sectors.map(sector => t("agent.enums.financialSector." + sector)).join(", ")}</td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("agent.attrs.email")}:</td>
      <td>
        <CopyToClipboardView content={agent.email} wrapper={<a href={`mailto:${agent.email}`}>{agent.email}</a>} />
      </td>
      <td>{t("agent.attrs.phone")}:</td>
      <td>
        <CopyToClipboardView
          content={agent.phone}
          wrapper={<a href={`tel:${agent.phone}`}>{formatPhoneNumber(agent.phone)}</a>}
        />
      </td>
    </tr>
    <tr>
      <td>{t("agent.attrs.web")}:</td>
      <td>{agent.web}</td>
      <td>{t("agent.attrs.bankAccountNumber")}:</td>
      <td>
        <span className="nowrap">
          <CopyToClipboardView content={formatIban(agent.bankAccountNumber)} />
        </span>
      </td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td>{t("agent.helpers.representativeFor")}:</td>
      <td colSpan={3}>
        {agent.representedAgents.map((agent, index) => (
          <React.Fragment key={agent.id}>
            {index > 0 && ", "}
            <Link
              className="no-link-color"
              to={generatePath(AGENT_ROUTE_PATHS.detail.to, { id: agent.id })}
              target="_blank"
            >
              {formatAgentName(agent)}
            </Link>
          </React.Fragment>
        ))}
      </td>
    </tr>
    <tr>
      <td colSpan={4} />
    </tr>
    <tr>
      <td colSpan={4}>{t("agent.attrs.note")}:</td>
    </tr>
    {agent.note && (
      <tr>
        <td colSpan={4}>
          <HtmlView value={agent.note} />
        </td>
      </tr>
    )}
  </>
);

export default NaturalAgentDataView;
