import { Modal, Space, Spin } from "antd";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { bindActionCreators } from "redux";
import t from "../../../../app/i18n";
import ActionButton from "../../../../common/components/buttons/ActionButton";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import { RootState } from "../../../../common/types";
import { resolveFileIconType } from "../../../../common/utils/utils";
import { deleteStateBugReportDetailAction, getBugReportActions, selectBugReportDetail } from "../../../bugreport/ducks";
import { BUG_REPORT_ROUTE_PATHS } from "../../../bugreport/paths";
import { BugReport, UserReportedBug } from "../../../bugreport/types";
import { NotificationTopic } from "../../enums";
import { Notification, SysadminBugReportAdditionalData } from "../../types";

interface Props {
  open: boolean;
  notification?: Notification;
  onCancelClick: () => void;
}

const BugReportNotificationView = ({ open, notification, onCancelClick }: Props) => {
  const bugReport = useSelector<RootState, BugReport | undefined>(selectBugReportDetail);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          getBugReport: getBugReportActions.request,
          deleteStateBugReportDetail: deleteStateBugReportDetailAction
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    if (open && notification?.data.topic === NotificationTopic.SYSADMIN_BUG_REPORT_CREATED) {
      actions.getBugReport({
        id: (notification.data.additionalData as SysadminBugReportAdditionalData).bugReportId
      });
    }
    return () => {
      if (bugReport) {
        actions.deleteStateBugReportDetail();
      }
    };
  }, [open, notification]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      open={open}
      title={t("notification.titles.userBugReport")}
      footer={<ActionButton icon="close" label={t("common.close")} onClick={onCancelClick} />}
      onCancel={onCancelClick}
    >
      {bugReport ? (
        <Space direction="vertical">
          <h3>{(bugReport.data as UserReportedBug).title}</h3>
          <p>{(bugReport.data as UserReportedBug).content}</p>

          {bugReport.attachments.map((attachment, index) => (
            <ActionTextIcon
              key={index}
              icon={resolveFileIconType(attachment.file.contentType)}
              color="blue"
              text={attachment.file.filename}
              path={generatePath(BUG_REPORT_ROUTE_PATHS.attachment.to, { id1: bugReport.id, id2: attachment.id })}
              target="_blank"
            />
          ))}
        </Space>
      ) : (
        <Spin spinning size="large" />
      )}
    </Modal>
  );
};

export default BugReportNotificationView;
