import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import apiService from "../../../common/api/apiService";
import { CalcAttachment, CalcRequest, CalcResponse, CalcResultData, GenRequest, GenResponse, Offer } from "./types";

const CALCULATE = ApiRequestAdapter.create("/calculators/calculate", "POST");
const GENERATE = ApiRequestAdapter.create("/calculators/generate", "POST");
const GENERATE_OFFER = ApiRequestAdapter.create("/calculators/offer", "POST");
const UPLOAD_CALC_ATTACHMENTS = ApiRequestAdapter.create("/calculators/attachments", "POST");
const DOWNLOAD_CARD_READER = ApiRequestAdapter.create("/card-reader/download", "GET");

export const requests = {
  CALCULATE,
  GENERATE,
  GENERATE_OFFER,
  UPLOAD_CALC_ATTACHMENTS,
  DOWNLOAD_CARD_READER
};

const api = {
  calculate: (request: CalcRequest): AxiosPromise<CalcResponse<CalcResultData>> => {
    return apiService.post(CALCULATE.url, request);
  },
  generate: (request: GenRequest<CalcResultData>): AxiosPromise<GenResponse> => {
    return apiService.post(GENERATE.url, request);
  },
  generateOffer: (request: Offer<CalcRequest, CalcResultData>): AxiosPromise<Blob> => {
    return apiService.post(GENERATE_OFFER.url, request, { responseType: "blob" });
  },
  uploadCalcAttachments: (request: FormData): AxiosPromise<CalcAttachment[]> => {
    return apiService.post(UPLOAD_CALC_ATTACHMENTS.url, request);
  },
  downloadCardReader: (): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_CARD_READER.url, { responseType: "blob" });
  }
};

export default api;
