import { Card, Col, Collapse, CollapseProps, DatePicker, Form, InputNumber, Row, Space } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import { Dayjs } from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../../app/i18n";
import ActionButton from "../../../../../../common/components/buttons/ActionButton";
import InputAddon from "../../../../../../common/components/form/addons/InputAddon";
import HiddenInput from "../../../../../../common/components/form/components/HiddenInput";
import { rowGutter } from "../../../../../../common/constants";
import { RootState } from "../../../../../../common/types";
import {
  datePickerClearableProps,
  datePickerFormItemProps,
  datePickerStandardProps,
  disableDatePickerOutOfMaxDate,
  disableDatePickerOutOfMinDate,
  inputNumberDecimalStandardProps,
  toDate
} from "../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { Client } from "../../../../../client/types";
import { selectProductGroupsEnums } from "../../../../../enumerations/ducks";
import { ProductGroupWithProducts } from "../../../../../enumerations/types";
import { InsuranceType } from "../../../../enums";
import { CreateUpdateInsurance, CreateUpdateInsuranceContract, InsuranceContract } from "../../../../types";
import GenericInsuranceFormPart from "../parts/GenericInsuranceFormPart";
import LifeInsuranceFormPart from "../parts/LifeInsuranceFormPart";
import RealtyInsuranceFormPart from "../parts/RealtyInsuranceFormPart";
import TravelInsuranceFormPart from "../parts/TravelInsuranceFormPart";
import VehicleInsuranceFormPart from "../parts/VehicleInsuranceFormPart";

interface Props {
  initialContract?: InsuranceContract;
  form: FormInstance<CreateUpdateInsuranceContract>;
  clients: (Client | undefined)[];
  insurancesCount: number;
  onInsurancesCountChange: (count: number) => void;
  onAnnualPremiumChange: () => void;
  onPartialPremiumChange: () => void;
}

const InsuranceContractFormInsurancesSection = ({ form, clients, insurancesCount, ...props }: Props) => {
  const productGroups = useSelector<RootState, ProductGroupWithProducts[]>(selectProductGroupsEnums);

  const allInsurancesKeys = [...Array(insurancesCount)].map((_, index) => index.toString());

  const [openedInsurancesKeys, setOpenedInsurancesKeys] = useState<string[]>(allInsurancesKeys);

  const handleInsuranceAdd = (): void => {
    const contract = form.getFieldsValue([["insurances"], ["productId"]]);
    const insurances = [...(contract.insurances || [])];
    insurances[insurances.length] = {
      ...insurances[insurances.length],
      type: productGroups.flatMap(group => group.products).find(product => product.id === contract.productId)
        ?.insuranceType
    } as CreateUpdateInsurance[];
    form.setFieldsValue({ insurances });
    props.onInsurancesCountChange(insurancesCount + 1);

    setOpenedInsurancesKeys([...openedInsurancesKeys, insurancesCount.toString()]);
  };

  const handleInsuranceDelete = (index: number) => {
    const updatedInsurances = [...(form.getFieldValue(["insurances"]) || [])];

    updatedInsurances.splice(index, 1);
    if (updatedInsurances.length === 1) {
      updatedInsurances[0] = {
        ...updatedInsurances[0],
        contractEntryDate: undefined,
        contractWithdrawalDate: undefined,
        annualPremium: undefined,
        partialPremium: undefined
      };
    }
    form.setFieldsValue({ insurances: updatedInsurances as CreateUpdateInsurance[] });

    props.onInsurancesCountChange(insurancesCount - 1);
    props.onAnnualPremiumChange();
    props.onPartialPremiumChange();
  };

  const resolveEntryDateLabel = (type: InsuranceType): string => {
    return type === InsuranceType.LIFE
      ? t("contract.attrs.insurances.contractEntryDateLife")
      : t("contract.attrs.insurances.contractEntryDate");
  };

  const resolveWithdrawalDateLabel = (type: InsuranceType): string => {
    return type === InsuranceType.LIFE
      ? t("contract.attrs.insurances.contractWithdrawalDateLife")
      : t("contract.attrs.insurances.contractWithdrawalDate");
  };

  const getItems = (insurancesCount: number) => {
    const items: CollapseProps["items"] = [];

    [...Array(insurancesCount)].forEach((_, index) => {
      items.push({
        key: index,
        forceRender: true,
        label: (
          <b>
            {t("contract.attrs.insurances._label")} {index + 1}
          </b>
        ),
        extra: (
          <span onClick={event => event.stopPropagation()}>
            <ActionButton
              icon="minus"
              label={t("contract.actions.deleteInsurance")}
              disabled={insurancesCount === 1}
              onClick={() => handleInsuranceDelete(index)}
            />
          </span>
        ),
        children: (
          <>
            <HiddenInput name={["insurances", index, "id"]} />
            <HiddenInput name={["insurances", index, "optimisticLockVersion"]} />
            <HiddenInput name={["insurances", index, "type"]} />

            {insurancesCount > 1 && (
              <Row gutter={rowGutter}>
                <Col span={colSpan}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) =>
                      prev.insurances?.[index]?.type !== next.insurances?.[index]?.type ||
                      prev.effectiveBeginningDate !== next.effectiveBeginningDate ||
                      prev.effectiveEndDate !== next.effectiveEndDate
                    }
                  >
                    {({ getFieldValue }) => {
                      const beginningDate = getFieldValue("effectiveBeginningDate") as string;
                      const endDate = getFieldValue("effectiveEndDate") as string;
                      const rules: Rule[] = [validations.notNull];
                      if (beginningDate) {
                        rules.push(validations.notBefore(beginningDate, t("contract.attrs.effectiveBeginningDate")));
                      }
                      if (endDate) {
                        rules.push(validations.notAfter(endDate, t("contract.attrs.effectiveEndDate")));
                      }
                      return (
                        <Form.Item
                          name={["insurances", index, "contractEntryDate"]}
                          label={resolveEntryDateLabel(getFieldValue(["insurances", index, "type"]))}
                          rules={rules}
                          {...datePickerFormItemProps}
                        >
                          <DatePicker
                            {...datePickerStandardProps}
                            disabledDate={current => {
                              let result;
                              if (beginningDate) {
                                result = disableDatePickerOutOfMinDate(current, toDate(beginningDate) as Dayjs);
                              }

                              if (endDate) {
                                result = disableDatePickerOutOfMaxDate(current, toDate(endDate) as Dayjs);
                              }

                              return !!result;
                            }}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) =>
                      prev.insurances?.[index]?.type !== next.insurances?.[index]?.type ||
                      prev.insurances?.[index]?.contractEntryDate !== next.insurances?.[index]?.contractEntryDate ||
                      prev.effectiveBeginningDate !== next.effectiveBeginningDate ||
                      prev.effectiveEndDate !== next.effectiveEndDate
                    }
                  >
                    {({ getFieldValue }) => {
                      const type = getFieldValue(["insurances", index, "type"]) as InsuranceType;
                      const entryDate = getFieldValue(["insurances", index, "contractEntryDate"]) as string | undefined;
                      const beginningDate = getFieldValue("effectiveBeginningDate") as string | undefined;
                      const endDate = getFieldValue("effectiveEndDate") as string | undefined;
                      const rules = [];
                      if (entryDate) {
                        rules.push(validations.notBefore(entryDate, resolveEntryDateLabel(type)));
                      } else if (beginningDate) {
                        rules.push(validations.notBefore(beginningDate, t("contract.attrs.effectiveBeginningDate")));
                      }
                      if (endDate) {
                        rules.push(validations.notAfter(endDate, t("contract.attrs.effectiveEndDate")));
                      }
                      return (
                        <Form.Item
                          name={["insurances", index, "contractWithdrawalDate"]}
                          label={resolveWithdrawalDateLabel(type)}
                          rules={rules}
                          {...datePickerFormItemProps}
                        >
                          <DatePicker
                            {...datePickerClearableProps}
                            disabledDate={current =>
                              (!!entryDate && disableDatePickerOutOfMinDate(current, entryDate)) ||
                              (!!beginningDate && disableDatePickerOutOfMinDate(current, beginningDate)) ||
                              (!!endDate && disableDatePickerOutOfMaxDate(current, endDate))
                            }
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    name={["insurances", index, "annualPremium"]}
                    label={t("contract.attrs.insurances.annualPremium")}
                    rules={[validations.notNull, validations.minNumber(0)]}
                  >
                    <InputNumber
                      {...inputNumberDecimalStandardProps}
                      addonAfter={<InputAddon type="euro" />}
                      onChange={props.onAnnualPremiumChange}
                    />
                  </Form.Item>
                </Col>

                <Col span={colSpan}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) =>
                      prev.insurances?.[index]?.annualPremium !== next.insurances?.[index]?.annualPremium
                    }
                  >
                    {({ getFieldValue }) => (
                      <Form.Item
                        name={["insurances", index, "partialPremium"]}
                        label={t("contract.attrs.insurances.partialPremium")}
                        rules={[
                          validations.notNull,
                          validations.minNumber(0),
                          validations.maxNumber(
                            getFieldValue(["insurances", index, "annualPremium"]),
                            t("contract.attrs.insurances.annualPremium")
                          )
                        ]}
                      >
                        <InputNumber
                          {...inputNumberDecimalStandardProps}
                          addonAfter={<InputAddon type="euro" />}
                          onChange={props.onPartialPremiumChange}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.insurances?.[index]?.type !== next.insurances?.[index]?.type}
            >
              {({ getFieldValue }) => {
                const type = getFieldValue(["insurances", index, "type"]) as InsuranceType;
                switch (type) {
                  case InsuranceType.MTPL:
                  case InsuranceType.CRASH:
                  case InsuranceType.GAP:
                  case InsuranceType.PAS:
                    return <VehicleInsuranceFormPart index={index} clients={clients} form={form} type={type} />;
                  case InsuranceType.REALTY:
                    return <RealtyInsuranceFormPart index={index} clients={clients} form={form} />;
                  case InsuranceType.TRAVEL:
                    return <TravelInsuranceFormPart index={index} clients={clients} form={form} />;
                  case InsuranceType.LIFE:
                    return <LifeInsuranceFormPart index={index} clients={clients} />;
                  case InsuranceType.GENERIC:
                    return <GenericInsuranceFormPart index={index} clients={clients} />;
                  default:
                    return (
                      <span className="sub-header-info dashed">
                        {t("contract.helpers.insuranceProductPlaceholder")}
                      </span>
                    );
                }
              }}
            </Form.Item>
          </>
        )
      });
    });

    return items;
  };

  const colSpan = 4;

  return (
    <Card
      type="inner"
      className="card-box card-box--inner-extra"
      title={t("contract.sections.insurances")}
      extra={
        <Space>
          <ActionButton
            icon="arrows"
            label={t("common.openAll")}
            onClick={() => setOpenedInsurancesKeys(allInsurancesKeys)}
          />
          <ActionButton icon="shrink" label={t("common.closeAll")} onClick={() => setOpenedInsurancesKeys([])} />
        </Space>
      }
    >
      <Collapse
        activeKey={openedInsurancesKeys}
        onChange={key => setOpenedInsurancesKeys(key)}
        items={getItems(insurancesCount)}
      />

      <ActionButton
        icon="plus"
        label={t("contract.actions.addInsurance")}
        className="margin-top-medium"
        onClick={handleInsuranceAdd}
      />
    </Card>
  );
};

export default InsuranceContractFormInsurancesSection;
