import { Checkbox, Col, Form, Modal, Row, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import { ContentType, ModalSizes, rowGutter } from "../../../../../common/constants";
import { resolveFormValidationError } from "../../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../../common/utils/hooksUtils";
import messageUtils from "../../../../../common/utils/messageUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { requests } from "../../../api";
import { importClientsActions } from "../../../ducks";

interface Props {
  open: boolean;
  onFormCancel: () => void;
}

const ClientsImportForm = ({ open, onFormCancel }: Props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<{ dryRun: boolean; allowUpdate: boolean }>();

  const inProgress = useRequestFinishedCallback([requests.IMPORT_CLIENTS], onFormCancel);

  const [file, setFile] = useState<RcFile>();

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onImport: importClientsActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  const handleFileUpload = (file: RcFile): boolean | string => {
    if (file.type !== ContentType.CSV) {
      messageUtils.errorNotification({ message: t("common.error"), description: t("helpers.onlyCsvSupported") });
      return Upload.LIST_IGNORE;
    }
    setFile(file);
    return false;
  };

  const handleFileRemove = (): boolean => {
    setFile(undefined);
    return true;
  };

  const handleAfterClosed = (): void => {
    setFile(undefined);
    form.resetFields();
  };

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (!file) {
          messageUtils.errorNotification({ message: t("common.error"), description: t("helpers.missingInputFile") });
        } else {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("dryRun", values.dryRun.toString());
          formData.append("allowUpdate", values.allowUpdate.toString());
          actions.onImport(formData);
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={open}
      title={t("client.titles.import")}
      okText={t("common.submit")}
      cancelText={t("common.cancel")}
      maskClosable={false}
      confirmLoading={inProgress}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
      afterClose={handleAfterClosed}
    >
      <Form form={form} layout="vertical" name="clientsImportForm">
        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item name="dryRun" valuePropName="checked" rules={[validations.none]} initialValue={true}>
              <Checkbox>
                <LabelWithTooltip
                  label={t("client.helpers.import.dryRun")}
                  tooltip={t("client.helpers.import.dryRunDesc")}
                />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item name="allowUpdate" valuePropName="checked" rules={[validations.none]} initialValue={false}>
              <Checkbox>
                <LabelWithTooltip
                  label={t("client.helpers.import.allowUpdate")}
                  tooltip={t("client.helpers.import.allowUpdateDesc")}
                />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Upload
              maxCount={1}
              accept=".csv"
              fileList={file ? [file] : []}
              beforeUpload={handleFileUpload}
              onRemove={handleFileRemove}
            >
              <ActionButton icon="plus" label={t("common.chooseFile")} />
            </Upload>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ClientsImportForm;
