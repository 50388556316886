import { Card, Checkbox, Col, Divider, Form, Input, InputNumber, Radio, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import classNames from "classnames";
import t from "../../../../../app/i18n";
import BooleanRadioFormItem from "../../../../../common/components/form/components/BooleanRadioFormItem";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../common/constants";
import { inputNumberIntegerStandardProps, selectStandardProps } from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import {
  InvestmentQuestionnaireClientStatement,
  InvestmentQuestionnaireInvestorType,
  InvestmentQuestionnaireRisk,
  InvestmentQuestionnaireStrategyResult,
  LifeInsuranceSpecificRequirement
} from "../../../enums";
import { CreateUpdateFinancialMediation } from "../../../types";
import FinancialMediationQuestionnaireSelect from "../items/FinancialMediationQuestionnaireSelect";

interface Props {
  form: FormInstance<CreateUpdateFinancialMediation>;
  validateAllFields: boolean;
}

const FinancialMediationFormLifeQuestionnaireSection = ({ form, validateAllFields }: Props) => {
  const clientSpecificRequirement = Form.useWatch(
    ["mediationData", "clientSpecificRequirement"],
    form
  ) as LifeInsuranceSpecificRequirement;

  const clientStatement = Form.useWatch(
    ["mediationData", "investmentQuestionnaireData", "clientStatement"],
    form
  ) as InvestmentQuestionnaireClientStatement;

  const refuseToAnswerQuestions1To9 = Form.useWatch(
    ["mediationData", "investmentQuestionnaireData", "refuseToAnswerQuestions1To9"],
    form
  );

  const refuseToAnswerQuestion10 = Form.useWatch(
    ["mediationData", "investmentQuestionnaireData", "refuseToAnswerQuestion10"],
    form
  );

  const refuseToAnswerQuestions11To13 = Form.useWatch(
    ["mediationData", "investmentQuestionnaireData", "refuseToAnswerQuestions11To13"],
    form
  );

  const refuseToAnswerQuestions15To16 = Form.useWatch(
    ["mediationData", "investmentQuestionnaireData", "refuseToAnswerQuestions15To16"],
    form
  );

  return clientSpecificRequirement && clientSpecificRequirement !== LifeInsuranceSpecificRequirement.RISK_INSURANCE ? (
    <Card type="inner" className="card-box" title={t("financialMediation.sections.investmentQuestionnaire")}>
      <Row gutter={rowGutter}>
        <Col span={12}>
          <span className="sub-header-info form-item-additional">
            {t("financialMediation.enums.investmentQuestionnaireClientStatement._label")}:
          </span>

          <Form.Item
            name={["mediationData", "investmentQuestionnaireData", "clientStatement"]}
            rules={[validations.conditionalRule(validateAllFields, validations.notNull)]}
            className="margin-bottom-small"
          >
            <Radio.Group
              options={Object.keys(InvestmentQuestionnaireClientStatement).map(statement => ({
                label: t("financialMediation.enums.investmentQuestionnaireClientStatement." + statement),
                value: statement
              }))}
            />
          </Form.Item>
        </Col>

        {clientStatement === InvestmentQuestionnaireClientStatement.ALREADY_ANSWERED && (
          <Col span={12}>
            <Divider orientation="left" className="divider-subheader">
              {t("financialMediation.sections.previousQuestionnaireResult")}
            </Divider>

            <Row gutter={rowGutter}>
              <Col span={8}>
                <Form.Item
                  name={["mediationData", "investmentQuestionnaireData", "previousInvestorType"]}
                  label={
                    <span className="form-item-additional">
                      {t("financialMediation.enums.investmentQuestionnaireInvestorType._label")}
                    </span>
                  }
                  rules={[validations.conditionalRule(validateAllFields, validations.notNull)]}
                >
                  <Select
                    {...selectStandardProps}
                    options={Object.keys(InvestmentQuestionnaireInvestorType).map(type => ({
                      value: type,
                      label: t("financialMediation.enums.investmentQuestionnaireInvestorType." + type)
                    }))}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name={["mediationData", "investmentQuestionnaireData", "previousStrategyResult"]}
                  label={
                    <span className="form-item-additional">
                      {t("financialMediation.enums.investmentQuestionnaireStrategyResult._label")}
                    </span>
                  }
                  rules={[validations.conditionalRule(validateAllFields, validations.notNull)]}
                >
                  <Select
                    {...selectStandardProps}
                    options={Object.keys(InvestmentQuestionnaireStrategyResult).map(result => ({
                      value: result,
                      label: t("financialMediation.enums.investmentQuestionnaireStrategyResult." + result)
                    }))}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name={["mediationData", "investmentQuestionnaireData", "previousContractNumber"]}
                  label={
                    <LabelWithTooltip
                      label={t(
                        "financialMediation.attrs.mediationData.investmentQuestionnaireData.previousContractNumber"
                      )}
                      tooltip={t("financialMediation.helpers.questionnaire.previousContractNumberDesc")}
                      className="form-item-additional"
                    />
                  }
                  rules={[
                    validations.conditionalRule(validateAllFields, validations.notBlank),
                    validations.size(1, 64)
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        )}
      </Row>

      {clientStatement === InvestmentQuestionnaireClientStatement.WILLING_TO_ANSWER && (
        <>
          <Divider orientation="left" className="divider-subheader">
            {t("financialMediation.sections.questionnaireKnowledge")}
          </Divider>

          <Row gutter={rowGutter} align="bottom">
            {[...Array(9)].map((_, index) => (
              <FinancialMediationQuestionnaireSelect
                key={index}
                index={index}
                questionNumber={index + 1}
                required={!refuseToAnswerQuestions1To9}
                validateAllFields={validateAllFields}
              />
            ))}
          </Row>

          <Row gutter={rowGutter}>
            <Col span={12}>
              <Form.Item
                name={["mediationData", "investmentQuestionnaireData", "refuseToAnswerQuestions1To9"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>
                  {t("financialMediation.attrs.mediationData.investmentQuestionnaireData.refuseToAnswerQuestions1To9")}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Divider className="divider-subheader" />

          <Row gutter={rowGutter} align="bottom">
            <Col span={4}>
              <Form.Item
                name={["mediationData", "investmentQuestionnaireData", "clientAge"]}
                label={
                  <span className="form-item-additional">
                    {t("financialMediation.attrs.mediationData.investmentQuestionnaireData.clientAge")}
                  </span>
                }
                rules={[
                  validations.conditionalRule(validateAllFields, validations.notNull),
                  validations.minNumber(1),
                  validations.maxNumber(100)
                ]}
              >
                <InputNumber {...inputNumberIntegerStandardProps} />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                name={["mediationData", "investmentQuestionnaireData", "clientRetirementAge"]}
                label={
                  <span className="form-item-additional">
                    {t("financialMediation.attrs.mediationData.investmentQuestionnaireData.clientRetirementAge")}
                  </span>
                }
                rules={[
                  validations.conditionalRule(validateAllFields, validations.notNull),
                  validations.minNumber(1),
                  validations.maxNumber(100)
                ]}
              >
                <InputNumber {...inputNumberIntegerStandardProps} />
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left" className="divider-subheader">
            {t("financialMediation.sections.questionnaireTarget")}
          </Divider>

          <Row gutter={rowGutter}>
            <FinancialMediationQuestionnaireSelect
              index={9}
              questionNumber={10}
              required={!refuseToAnswerQuestion10}
              validateAllFields={validateAllFields}
            />

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.mediationData?.investmentQuestionnaireData?.answers?.[9] !==
                next.mediationData?.investmentQuestionnaireData?.answers?.[9]
              }
            >
              {({ getFieldValue }) =>
                getFieldValue(["mediationData", "investmentQuestionnaireData", "answers", 9]) === 3 && (
                  <Col span={8}>
                    <Form.Item
                      name={["mediationData", "investmentQuestionnaireData", "investmentTargetDescription"]}
                      label={
                        <span className={classNames({ "form-item-additional": !refuseToAnswerQuestion10 })}>
                          {t(
                            "financialMediation.attrs.mediationData.investmentQuestionnaireData.investmentTargetDescription"
                          )}
                        </span>
                      }
                      rules={[
                        validations.conditionalRule(
                          validateAllFields && !refuseToAnswerQuestion10,
                          validations.notBlank
                        ),
                        validations.size(1, 255)
                      ]}
                    >
                      <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
                    </Form.Item>
                  </Col>
                )
              }
            </Form.Item>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={12}>
              <Form.Item
                name={["mediationData", "investmentQuestionnaireData", "refuseToAnswerQuestion10"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>
                  {t("financialMediation.attrs.mediationData.investmentQuestionnaireData.refuseToAnswerQuestion10")}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left" className="divider-subheader">
            {t("financialMediation.sections.questionnaireFinanceSituation")}
          </Divider>

          <Row gutter={rowGutter} align="bottom">
            {[...Array(4)].map((_, index) => (
              <FinancialMediationQuestionnaireSelect
                key={index}
                index={index + 10}
                questionNumber={index + 11}
                required={index === 3 || !refuseToAnswerQuestions11To13}
                validateAllFields={validateAllFields}
              />
            ))}
          </Row>

          <Row gutter={rowGutter}>
            <Col span={12}>
              <Form.Item
                name={["mediationData", "investmentQuestionnaireData", "refuseToAnswerQuestions11To13"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>
                  {t(
                    "financialMediation.attrs.mediationData.investmentQuestionnaireData.refuseToAnswerQuestions11To13"
                  )}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left" className="divider-subheader">
            {t("financialMediation.sections.questionnaireRiskTolerance")}
          </Divider>

          <Row gutter={rowGutter} align="bottom">
            {[...Array(3)].map((_, index) => (
              <FinancialMediationQuestionnaireSelect
                key={index}
                index={index + 14}
                questionNumber={index + 15}
                required={index === 2 || !refuseToAnswerQuestions15To16}
                validateAllFields={validateAllFields}
              />
            ))}
          </Row>

          <Row gutter={rowGutter}>
            <Col span={12}>
              <Form.Item
                name={["mediationData", "investmentQuestionnaireData", "refuseToAnswerQuestions15To16"]}
                valuePropName="checked"
                rules={[validations.none]}
                initialValue={false}
              >
                <Checkbox>
                  {t(
                    "financialMediation.attrs.mediationData.investmentQuestionnaireData.refuseToAnswerQuestions15To16"
                  )}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Divider className="divider-subheader" />

          <Row gutter={rowGutter}>
            <Col span={8}>
              <Form.Item
                name={["mediationData", "investmentQuestionnaireData", "strategyResult"]}
                label={
                  <span className="form-item-additional">
                    {t("financialMediation.helpers.questionnaire.strategyResultLabel")}
                  </span>
                }
                rules={[validations.conditionalRule(validateAllFields, validations.notNull)]}
              >
                <Select
                  {...selectStandardProps}
                  options={Object.keys(InvestmentQuestionnaireStrategyResult).map(result => ({
                    value: result,
                    label: t("financialMediation.enums.investmentQuestionnaireStrategyResult." + result)
                  }))}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name={["mediationData", "investmentQuestionnaireData", "informedAboutRisks"]}
                label={
                  <span className="form-item-additional">
                    {t("financialMediation.helpers.questionnaire.informedAboutRisksLabel")}
                  </span>
                }
                rules={[validations.conditionalRule(validateAllFields, validations.notNull)]}
              >
                <Select
                  {...selectStandardProps}
                  mode="multiple"
                  maxTagCount="responsive"
                  options={Object.keys(InvestmentQuestionnaireRisk).map(risk => ({
                    value: risk,
                    label: t("financialMediation.enums.investmentQuestionnaireRisk." + risk)
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={12}>
              <span className="sub-header-info form-item-additional">
                {t("financialMediation.attrs.mediationData.investmentQuestionnaireData.agentReceivedInfoFromClient")}:
              </span>

              <BooleanRadioFormItem
                formItemProps={{
                  name: ["mediationData", "investmentQuestionnaireData", "agentReceivedInfoFromClient"],
                  rules: [validations.conditionalRule(validateAllFields, validations.notNull)],
                  className: "margin-bottom-small"
                }}
                trueLabel={
                  <LabelWithTooltip
                    label={t("financialMediation.helpers.questionnaire.agentReceivedInfoFromClient.true")}
                    tooltip={t("financialMediation.helpers.questionnaire.agentReceivedInfoFromClient.trueDesc")}
                  />
                }
                falseLabel={t("financialMediation.helpers.questionnaire.agentReceivedInfoFromClient.falseLife")}
              />
            </Col>
          </Row>

          <Row gutter={rowGutter}>
            <Col span={12}>
              <span className="sub-header-info form-item-additional">
                {t("financialMediation.attrs.mediationData.investmentQuestionnaireData.productSuitableForClient")}:
              </span>

              <BooleanRadioFormItem
                formItemProps={{
                  name: ["mediationData", "investmentQuestionnaireData", "productSuitableForClient"],
                  rules: [validations.conditionalRule(validateAllFields, validations.notNull)],
                  className: "margin-bottom-small"
                }}
                trueLabel={t("financialMediation.helpers.questionnaire.productSuitableForClient.true")}
                falseLabel={t("financialMediation.helpers.questionnaire.productSuitableForClient.false")}
              />
            </Col>

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.mediationData?.investmentQuestionnaireData?.productSuitableForClient !==
                next.mediationData?.investmentQuestionnaireData?.productSuitableForClient
              }
            >
              {({ getFieldValue }) =>
                getFieldValue(["mediationData", "investmentQuestionnaireData", "productSuitableForClient"]) && (
                  <Col span={8}>
                    <Form.Item
                      name={["mediationData", "investmentQuestionnaireData", "notSuitableProductDescription"]}
                      label={
                        <LabelWithTooltip
                          label={t(
                            "financialMediation.attrs.mediationData.investmentQuestionnaireData.notSuitableProductDescription"
                          )}
                          tooltip={t("financialMediation.helpers.questionnaire.notSuitableProductDescriptionDesc")}
                        />
                      }
                      rules={[validations.size(1, 255)]}
                    >
                      <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
                    </Form.Item>
                  </Col>
                )
              }
            </Form.Item>
          </Row>
        </>
      )}
    </Card>
  ) : null;
};

export default FinancialMediationFormLifeQuestionnaireSection;
