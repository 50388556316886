import { Button, Card, Col, Form, Row, Select } from "antd";
import { useEffect } from "react";
import t from "../../../../../app/i18n";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../common/constants";
import { selectStandardProps } from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import { AgentCompetenceLevel, AgentCompetenceProvider, AgentCompetenceType, FinancialSector } from "../../../enums";
import { AgentCompetenceFilterPageRequest, AgentCompetenceFilterPageResult } from "../../../types";

interface Props {
  filter: AgentCompetenceFilterPageResult;
  onFilterSubmit: (filter: AgentCompetenceFilterPageRequest) => void;
}

const AgentEndingCompetenceFilterView = ({ filter, onFilterSubmit }: Props) => {
  const [form] = Form.useForm<AgentCompetenceFilterPageRequest>();

  useEffect(() => {
    if (filter) {
      form.setFieldsValue({
        types: filter.types || [],
        sectors: filter.sectors || [],
        levels: filter.levels || [],
        providers: filter.providers || [],
        agentIds: filter.agentIds || []
      });
    }
  }, [filter, form]);

  const handleFilterClear = (): void => {
    form.resetFields();
    onFilterSubmit(form.getFieldsValue());
  };

  const colSpan = 4;

  return (
    <Card
      className="card-box"
      title={<h2>{t("agent.titles.endingCompetences")}</h2>}
      extra={<ActionTextIcon icon="reload" color="red" onClick={handleFilterClear} text={t("common.resetFilter")} />}
    >
      <Form form={form} layout="vertical" onFinish={onFilterSubmit} name="agentEndingCompetenceFilterForm">
        <Row gutter={rowGutter}>
          <Col span={colSpan + 1}>
            <AgentSelect
              formItemProps={{
                name: "agentIds",
                label: t("agent.competence.filter.agentIds"),
                rules: [validations.none]
              }}
              selectProps={{ mode: "multiple", maxTagCount: "responsive", allowClear: true }}
            />
          </Col>

          <Col span={colSpan}>
            <Form.Item name="types" label={t("agent.competence.filter.types")} rules={[validations.none]}>
              <Select
                {...selectStandardProps}
                allowClear
                mode="multiple"
                maxTagCount="responsive"
                options={Object.keys(AgentCompetenceType).map(type => ({
                  value: type,
                  label: t("agent.competence.enums.competenceType." + type)
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item name="sectors" label={t("agent.competence.filter.sectors")} rules={[validations.none]}>
              <Select
                {...selectStandardProps}
                allowClear
                mode="multiple"
                maxTagCount="responsive"
                options={Object.keys(FinancialSector).map(sector => ({
                  value: sector,
                  label: t("agent.enums.financialSector." + sector)
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item name="levels" label={t("agent.competence.filter.levels")} rules={[validations.none]}>
              <Select
                {...selectStandardProps}
                allowClear
                mode="multiple"
                maxTagCount="responsive"
                options={Object.keys(AgentCompetenceLevel).map(level => ({
                  value: level,
                  label: t("agent.competence.enums.competenceLevel." + level)
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan}>
            <Form.Item name="providers" label={t("agent.competence.filter.providers")} rules={[validations.none]}>
              <Select
                {...selectStandardProps}
                allowClear
                mode="multiple"
                maxTagCount="responsive"
                options={Object.keys(AgentCompetenceProvider).map(provider => ({
                  value: provider,
                  label: t("agent.competence.enums.competenceProvider." + provider)
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={colSpan - 1} className="no-title-space center-align">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default AgentEndingCompetenceFilterView;
