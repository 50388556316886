import { Alert, Col, Popover, Row } from "antd";
import groupBy from "lodash/groupBy";
import React from "react";
import t from "../../../../../../app/i18n";
import LabelWithTooltip from "../../../../../../common/components/form/labels/LabelWithTooltip";
import ActionTextIcon from "../../../../../../common/components/icons/ActionTextIcon";
import FieldViolationsView from "../../../../../../common/components/views/FieldViolationsView";
import MultiLineText from "../../../../../../common/components/views/MultiLineText";
import { HttpStatus, rowGutter } from "../../../../../../common/constants";
import ComponentWithPermission from "../../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { formatCoverageLimitValues, formatLocaleCurrency } from "../../../../../../common/utils/formatUtils";
import { cssVariables, isDefinedValue } from "../../../../../../common/utils/utils";
import { InsuranceCoverageType, PaymentFrequency } from "../../../../../contract/enums";
import { CalcType } from "../../../../enums";
import { CalcResult } from "../../../types";
import { DEFEND_D2C_COVERAGE } from "../../../utils";
import {
  CrashCalcResultData,
  GapCalcResultData,
  MtplCalcResultData,
  MtplCrashCalcResultData,
  PasCalcResultData,
  VehicleCalcResultData
} from "../../types";

interface Props {
  result: CalcResult<VehicleCalcResultData>;
  disabled: boolean;
  onGenerateContractClick: (result: CalcResult<VehicleCalcResultData>) => void;
  onShowErrorsClick?: (result: CalcResult<VehicleCalcResultData>) => void;
}

const VehicleCalcResultsItem = ({ result, disabled, ...props }: Props) => {
  let resultDescriptionTable = undefined;
  let resultCoveragesDescription = undefined;

  if (result.data) {
    if (result.calcType === CalcType.GAP) {
      const gapOptions = groupBy(
        (result.data as GapCalcResultData).options,
        o => o.duration + "-" + o.complicityReinsurance.toString()
      );
      resultDescriptionTable = (
        <table className="prices-table-view">
          <thead>
            <tr>
              <th />
              <th>{t("contract.enums.paymentFrequency.ONCE")}</th>
              <th>
                {t("contract.enums.paymentFrequency.ANNUALLY")}{" "}
                <span style={{ fontWeight: "normal" }}>({t("calc.vehicle.helpers.gapAnnualPremiumSum")})</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(gapOptions)
              .map(key => gapOptions[key])
              .map((options, index) => {
                const oncePaymentOption = options?.find(option => option.paymentFrequency === PaymentFrequency.ONCE);
                const annualPaymentOption = options?.find(
                  option => option.paymentFrequency === PaymentFrequency.ANNUALLY
                );
                return oncePaymentOption && annualPaymentOption ? (
                  <tr key={index}>
                    <td>
                      {t("calc.vehicle.enums.gapDuration." + oncePaymentOption.duration)}
                      &nbsp;
                      {result.coverage !== DEFEND_D2C_COVERAGE
                        ? oncePaymentOption.complicityReinsurance
                          ? `(${t("calc.vehicle.helpers.gapWithComplicityReinsurance")})`
                          : `(${t("calc.vehicle.helpers.gapWithoutComplicityReinsurance")})`
                        : undefined}
                    </td>
                    <td>{formatLocaleCurrency(oncePaymentOption.annualPremium)}</td>
                    <td>
                      {formatLocaleCurrency(annualPaymentOption.annualPremium)}{" "}
                      <span style={{ fontWeight: "normal" }}>
                        ({formatLocaleCurrency(annualPaymentOption.premium)})
                      </span>
                    </td>
                  </tr>
                ) : undefined;
              })}
          </tbody>
        </table>
      );
    } else {
      resultDescriptionTable = (
        <table className="prices-table-view">
          <tbody>
            <tr>
              <td>{t("calc.vehicle.results.annualPremium")}</td>
              <td>{formatLocaleCurrency(result.data.annualPremium)}</td>
            </tr>

            {result.calcType === CalcType.MTPL && (
              <tr>
                <td>{t("calc.vehicle.results.coverageLimit")}</td>
                <td>
                  {formatCoverageLimitValues(
                    (result.data as MtplCalcResultData).healthCoverageLimit,
                    (result.data as MtplCalcResultData).propertyCoverageLimit
                  )}
                </td>
              </tr>
            )}

            {result.calcType === CalcType.CRASH && (
              <>
                <tr>
                  <td>{t("calc.vehicle.results.complicity")}</td>
                  <td>{(result.data as CrashCalcResultData).complicity}</td>
                </tr>
                <tr>
                  <td>{t("calc.vehicle.results.vehiclePrice")}</td>
                  <td>{formatLocaleCurrency((result.data as CrashCalcResultData).vehiclePrice) || "-"}</td>
                </tr>
                <tr>
                  <td>{t("calc.vehicle.results.totalDamage")}</td>
                  <td>
                    {(result.data as CrashCalcResultData).totalDamage
                      ? `${(result.data as CrashCalcResultData).totalDamage}%`
                      : "-"}
                  </td>
                </tr>
              </>
            )}

            {result.calcType === CalcType.MTPL_CRASH && (
              <>
                <tr>
                  <td>{t("calc.vehicle.results.complicity")}</td>
                  <td>{(result.data as CrashCalcResultData).complicity}</td>
                </tr>
                <tr>
                  <td>{t("calc.vehicle.results.coverageLimit")}</td>
                  <td>
                    {formatCoverageLimitValues(
                      (result.data as MtplCalcResultData).healthCoverageLimit,
                      (result.data as MtplCalcResultData).propertyCoverageLimit
                    )}
                  </td>
                </tr>
                <tr>
                  <td>{t("calc.vehicle.results.vehiclePrice")}</td>
                  <td>{formatLocaleCurrency((result.data as CrashCalcResultData).vehiclePrice) || "-"}</td>
                </tr>
                <tr>
                  <td>{t("calc.vehicle.results.totalDamage")}</td>
                  <td>
                    {(result.data as CrashCalcResultData).totalDamage
                      ? `${(result.data as CrashCalcResultData).totalDamage}%`
                      : "-"}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      );
    }

    if (
      result.calcType === CalcType.MTPL ||
      result.calcType === CalcType.CRASH ||
      result.calcType === CalcType.MTPL_CRASH
    ) {
      resultCoveragesDescription = (
        <div className="sub-header-info">
          {result.data.appliedCoverages.map((coverage, index, array) => (
            <React.Fragment key={index}>
              {t("contract.enums.insuranceCoverageType." + coverage.type)}
              {isDefinedValue(coverage.annualPremium) ? (
                <span> ({formatLocaleCurrency(coverage.annualPremium)})</span>
              ) : undefined}
              {index < array.length - 1 && ", "}
            </React.Fragment>
          ))}
        </div>
      );
    }

    if (result.calcType === CalcType.PAS) {
      resultCoveragesDescription = (
        <div className="sub-header-info">
          {(result.data as PasCalcResultData).coverages.map((coverage, index, array) => (
            <React.Fragment key={index}>
              {coverage.name} <span className="no-break">({coverage.limit})</span>
              {index < array.length - 1 && (
                <>
                  , <br />
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      );
    }
  }

  let resultAlert = undefined;
  if (result.data && result.data.warnings && result.data.warnings.length > 0) {
    resultAlert = (
      <Alert type="warning" showIcon message={<MultiLineText showDivider tokens={result.data.warnings} />} />
    );
  } else if (result.error) {
    if (result.error.status === HttpStatus.UNPROCESSABLE_ENTITY) {
      const violations = result.error.violations?.filter(
        violation => !violation.fieldPath.startsWith("clientsData.clients")
      );

      if (result.error.violations?.some(violation => violation.fieldPath.startsWith("clientsData.clients"))) {
        violations?.unshift({
          fieldPath: "clientsData.holder",
          errors: [t("calc.validations.clientViolationError")],
          fieldValue: undefined
        });
      }

      resultAlert = (
        <Alert
          type="error"
          showIcon
          message={result.error.message}
          description={<FieldViolationsView violations={violations} rootPath="calc.vehicle.attrs" />}
        />
      );
    } else {
      resultAlert = (
        <Alert
          type="error"
          showIcon
          message={result.error.message}
          description={<MultiLineText tokens={result.error.errors} />}
        />
      );
    }
  }

  let resultAction = undefined;
  if (result.data) {
    resultAction = (
      <ComponentWithPermission
        permissions={
          result.calcType === CalcType.MTPL
            ? [Permission.MTPL_GENERATE_CONTRACT]
            : result.calcType === CalcType.CRASH
              ? [Permission.CRASH_GENERATE_CONTRACT]
              : [Permission.MTPL_GENERATE_CONTRACT, Permission.CRASH_GENERATE_CONTRACT]
        }
      >
        <ActionTextIcon
          icon="check-circle"
          color="green"
          size="large"
          disabled={disabled}
          text={t("calc.vehicle.actions.createContract")}
          onClick={() => props.onGenerateContractClick(result)}
        />
      </ComponentWithPermission>
    );
  } else if (props.onShowErrorsClick && result.error && result.error.status === HttpStatus.UNPROCESSABLE_ENTITY) {
    resultAction = (
      <ActionTextIcon
        icon="eye"
        color="red"
        size="large"
        text={t("calc.vehicle.actions.showErrors")}
        onClick={() => (props.onShowErrorsClick ? props.onShowErrorsClick(result) : undefined)}
      />
    );
  }

  const resultContent = (
    <div>
      {result.calcType !== CalcType.CRASH && result.coverage && (
        <h4 style={{ color: cssVariables.colorBlue }}>{result.coverage}</h4>
      )}
      {resultDescriptionTable}
      {resultCoveragesDescription}
      {resultAlert && <div className="margin-top-tiny">{resultAlert}</div>}
      {resultAction && <div className="margin-top-tiny">{resultAction}</div>}
    </div>
  );

  return result.data &&
    (result.calcType === CalcType.MTPL ||
      result.calcType === CalcType.CRASH ||
      result.calcType === CalcType.MTPL_CRASH) ? (
    <Popover
      placement="left"
      title={t("calc.vehicle.results.additionalInfo")}
      content={
        <div style={{ maxWidth: 600 }}>
          {result.calcType === CalcType.CRASH && (
            <p>
              <b>{t("calc.vehicle.enums.territorialValidity._label")}:</b>&nbsp;
              {t("calc.vehicle.enums.territorialValidity." + (result.data as CrashCalcResultData).territorialValidity)}
            </p>
          )}

          {result.calcType === CalcType.MTPL_CRASH &&
            (result.data as MtplCrashCalcResultData).mtplInsuranceAnnualPremium &&
            (result.data as MtplCrashCalcResultData).crashInsuranceAnnualPremium && (
              <Row gutter={rowGutter}>
                <Col span={10}>
                  <table className="prices-table-view margin-bottom-small">
                    <tbody>
                      <tr>
                        <td>{t("calc.vehicle.helpers.mtplAnnualPremium")}</td>
                        <td>
                          {formatLocaleCurrency((result.data as MtplCrashCalcResultData).mtplInsuranceAnnualPremium)}
                        </td>
                      </tr>
                      <tr>
                        <td>{t("calc.vehicle.helpers.crashAnnualPremium")}</td>
                        <td>
                          {formatLocaleCurrency((result.data as MtplCrashCalcResultData).crashInsuranceAnnualPremium)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            )}

          {result.data.appliedCoverages.length > 0 ? (
            <table className="prices-table-view bottom-border">
              <thead>
                <tr>
                  <th>{t("contract.enums.insuranceCoverageType._label")}</th>
                  <th>{t("calc.vehicle.results.insuranceAmount")}</th>
                  <th>{t("calc.vehicle.results.complicity")}</th>
                  {result.calcType === CalcType.MTPL && <th>{t("calc.vehicle.enums.territorialValidity._label")}</th>}
                  <th>{t("calc.vehicle.results.reinsuranceOrInCoverage")}</th>
                </tr>
              </thead>
              <tbody>
                {result.data.appliedCoverages.map((coverage, index) => (
                  <tr key={index}>
                    <td>
                      {coverage.note ? (
                        <LabelWithTooltip
                          label={t("contract.enums.insuranceCoverageType." + coverage.type)}
                          tooltip={coverage.note}
                        />
                      ) : (
                        <span>{t("contract.enums.insuranceCoverageType." + coverage.type)}</span>
                      )}
                    </td>
                    <td>
                      {coverage.injuryLongTermEffectsInsuranceAmount && coverage.injuryDeathInsuranceAmount ? (
                        <>
                          <span className="no-break">
                            {t("calc.vehicle.results.injuryLongTermEffectsInsuranceAmount")}:{" "}
                            {formatLocaleCurrency(coverage.injuryLongTermEffectsInsuranceAmount)}
                          </span>
                          <br />
                          <span className="no-break">
                            {t("calc.vehicle.results.injuryDeathInsuranceAmount")}:{" "}
                            {formatLocaleCurrency(coverage.injuryDeathInsuranceAmount)}
                          </span>
                        </>
                      ) : coverage.gapDuration ? (
                        <span className="no-break">{t("calc.vehicle.enums.gapDuration." + coverage.gapDuration)}</span>
                      ) : coverage.replacementVehicleLimit ? (
                        <span className={coverage.replacementVehicleLimit?.length < 25 ? "no-break" : ""}>
                          {coverage.replacementVehicleLimit}
                        </span>
                      ) : coverage.insuranceAmountDesc ? (
                        <span>{coverage.insuranceAmountDesc}</span>
                      ) : (
                        <>{formatLocaleCurrency(coverage.insuranceAmount)}</>
                      )}
                    </td>
                    <td>
                      {coverage.type === InsuranceCoverageType.GAP
                        ? coverage.gapComplicityReinsurance
                          ? t("calc.vehicle.helpers.gapWithComplicityReinsurance")
                          : t("calc.vehicle.helpers.gapWithoutComplicityReinsurance")
                        : coverage.complicity}
                    </td>
                    {result.calcType === CalcType.MTPL && (
                      <td>
                        {coverage.territorialValidity ? (
                          <span className="no-break">
                            {t("calc.vehicle.enums.territorialValidity." + coverage.territorialValidity)}
                          </span>
                        ) : undefined}
                      </td>
                    )}
                    <td>{t("calc.vehicle.results." + (coverage.reinsured ? "reinsurance" : "inCoverage"))}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="sub-header-info dashed">{t("calc.vehicle.results.noExtendedCoverages")}</p>
          )}

          {result.data && result.data.additionalWarnings && result.data.additionalWarnings.length > 0 ? (
            <div className="margin-top-tiny">
              <Alert
                type="warning"
                showIcon
                message={<MultiLineText showDivider tokens={result.data.additionalWarnings} />}
              />
            </div>
          ) : undefined}
        </div>
      }
    >
      {resultContent}
    </Popover>
  ) : (
    resultContent
  );
};

export default VehicleCalcResultsItem;
