import { Button, Col, Divider, Popconfirm, Row } from "antd";
import { useSelector } from "react-redux";
import t from "../../../../../../app/i18n";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../../common/components/icons/PopconfirmDeleteIcon";
import CopyToClipboardView from "../../../../../../common/components/views/CopyToClipboardView";
import { rowGutter } from "../../../../../../common/constants";
import ContactsView from "../../../../../../common/modules/contact/ContactsView";
import ComponentWithPermission from "../../../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { RootState } from "../../../../../../common/types";
import { formatAddress, formatAgentIdNumber, formatAgentName } from "../../../../../../common/utils/formatUtils";
import { hasPermission } from "../../../../../../common/utils/utils";
import { selectPermissions } from "../../../../../auth/ducks";
import { deleteAgentActions } from "../../../../ducks";
import { AgentType } from "../../../../enums";
import { Agent, LegalAgent, NaturalAgent, SelfEmployedAgent } from "../../../../types";
import AgentActiveTag from "../../../AgentActiveTag";
import AgentCanBeGainerTag from "../../../AgentCanBeGainerTag";
import AgentTipperTag from "../../../AgentTipperTag";
import AgentProfilePictureView from "../../profilepicture/AgentProfilePictureView";
import LegalAgentDataView from "./LegalAgentDataView";
import NaturalAgentDataView from "./NaturalAgentDataView";
import SelfEmployedAgentDataView from "./SelfEmployedAgentDataView";

interface Props {
  agent: Agent;
  onDelete: typeof deleteAgentActions.request;
  onUpdateClick: () => void;
}

const AgentDetailView = ({ agent, onUpdateClick, onDelete }: Props) => {
  const permissions = useSelector<RootState, Permission[]>(selectPermissions);

  let dataView;
  switch (agent.type) {
    case AgentType.NATURAL:
      dataView = <NaturalAgentDataView agent={agent as NaturalAgent} />;
      break;
    case AgentType.SELF_EMPLOYED:
      dataView = <SelfEmployedAgentDataView agent={agent as SelfEmployedAgent} />;
      break;
    case AgentType.LEGAL:
      dataView = <LegalAgentDataView agent={agent as LegalAgent} />;
      break;
  }

  return (
    <>
      <Divider orientation="left">{t("agent.titles.basicData")}</Divider>

      <Row gutter={rowGutter}>
        <Col span={16}>
          {agent.tipper ? <AgentTipperTag /> : undefined}
          <AgentCanBeGainerTag canBeGainer={agent.canBeGainer} />
          <AgentActiveTag agentDeactivated={agent.deactivated} />

          <table className="data-table-view margin-top-small">
            <tbody>
              <tr>
                <td>{t("agent.attrs.idNumber")}:</td>
                <td colSpan={3}>
                  <CopyToClipboardView content={formatAgentIdNumber(agent)} />
                </td>
              </tr>
              <tr>
                <td>{t("agent.attrs.partnerIdNumber")}:</td>
                <td colSpan={3}>
                  <CopyToClipboardView content={agent.partnerIdNumber} />
                </td>
              </tr>
              <tr>
                <td>{t("agent.attrs.parentId")}:</td>
                <td colSpan={3}>{formatAgentName(agent.parent)}</td>
              </tr>
              {dataView}
            </tbody>
          </table>
        </Col>

        <Col span={8} className="center-align">
          <AgentProfilePictureView
            agent={agent}
            width={200}
            showActions={hasPermission(permissions, Permission.AGENT_UPDATE)}
          />
        </Col>
      </Row>

      <Divider orientation="left">{t("agent.titles.addresses")}</Divider>

      <table className="data-table-view">
        <tbody>
          <tr>
            <td>{agent.type === AgentType.LEGAL ? t("agent.attrs.addressLongPO") : t("agent.attrs.addressLong")}:</td>
            <td>{formatAddress(agent.address)}</td>
          </tr>
          <tr>
            <td>
              {agent.type === AgentType.SELF_EMPLOYED
                ? t("agent.attrs.serviceAddressFOP")
                : t("agent.attrs.serviceAddress")}
              :
            </td>
            <td>{formatAddress(agent.serviceAddress)}</td>
          </tr>
          <tr>
            <td>{t("agent.attrs.correspondenceAddress")}:</td>
            <td>{formatAddress(agent.correspondenceAddress)}</td>
          </tr>
        </tbody>
      </table>

      <Divider orientation="left">{t("agent.titles.contacts")}</Divider>

      <ContactsView contacts={agent.contacts} />

      <div className="margin-top-large">
        <ComponentWithPermission permissions={[Permission.AGENT_UPDATE]}>
          <Button className="margin-right-tiny" type="primary" onClick={onUpdateClick} icon={<AntIcon type="edit" />}>
            {t("common.edit")}
          </Button>
        </ComponentWithPermission>

        <ComponentWithPermission permissions={[Permission.AGENT_DELETE]}>
          <Popconfirm
            title={t("agent.titles.deleteConfirm", { aggregatedName: agent.aggregatedName })}
            icon={<PopconfirmDeleteIcon />}
            okText={t("common.yes")}
            cancelText={t("common.no")}
            okType="danger"
            onConfirm={() => onDelete({ id: agent.id })}
          >
            <Button icon={<AntIcon type="delete" />} danger>
              {t("common.delete")}
            </Button>
          </Popconfirm>
        </ComponentWithPermission>
      </div>
    </>
  );
};

export default AgentDetailView;
