import { ProductFinancialSector } from "../product/enums";
import {
  FinancialMediationSectorAttachmentType,
  FinancialMediationVersion,
  MediationClientFormType,
  PillarsRequirement
} from "./enums";

export const FINANCIAL_MEDIATION_SECONDARY_CLIENT_SECTORS = [
  ProductFinancialSector.NON_LIFE_INSURANCES,
  ProductFinancialSector.LIFE_INSURANCES,
  ProductFinancialSector.LOANS_AND_MORTGAGES
];

export const FINANCIAL_MEDIATION_CHILDREN_SECTORS = [
  ProductFinancialSector.NON_LIFE_INSURANCES,
  ProductFinancialSector.LIFE_INSURANCES,
  ProductFinancialSector.DEPOSITS
];

export const FINANCIAL_MEDIATION_CLIENTS_WITH_ID_CARD = [
  MediationClientFormType.POLICY_HOLDER,
  MediationClientFormType.SECONDARY_CLIENT,
  MediationClientFormType.POLICY_HOLDER_REPRESENTATIVE,
  MediationClientFormType.SECONDARY_CLIENT_REPRESENTATIVE
];

export const FINANCIAL_MEDIATION_SECTOR_TO_ATTACHMENT_MAP = new Map<
  ProductFinancialSector,
  FinancialMediationSectorAttachmentType[]
>([
  [
    ProductFinancialSector.NON_LIFE_INSURANCES,
    [
      FinancialMediationSectorAttachmentType.CONTRACT,
      FinancialMediationSectorAttachmentType.GENERAL_CONDITIONS,
      FinancialMediationSectorAttachmentType.FEE_TARIFFS,
      FinancialMediationSectorAttachmentType.IPID,
      FinancialMediationSectorAttachmentType.IMPORTANT_CONTRACT_CONDITIONS,
      FinancialMediationSectorAttachmentType.INSURANCE_PARTS_FORM
    ]
  ],
  [
    ProductFinancialSector.LIFE_INSURANCES,
    [
      FinancialMediationSectorAttachmentType.CONTRACT,
      FinancialMediationSectorAttachmentType.GENERAL_CONDITIONS,
      FinancialMediationSectorAttachmentType.FEE_TARIFFS,
      FinancialMediationSectorAttachmentType.IPID,
      FinancialMediationSectorAttachmentType.IMPORTANT_CONTRACT_CONDITIONS,
      FinancialMediationSectorAttachmentType.INSURANCE_PARTS_FORM,
      FinancialMediationSectorAttachmentType.HEALTH_QUESTIONNAIRE
    ]
  ],
  [
    ProductFinancialSector.LOANS_AND_MORTGAGES,
    [
      FinancialMediationSectorAttachmentType.CONTRACT,
      FinancialMediationSectorAttachmentType.GENERAL_CONDITIONS,
      FinancialMediationSectorAttachmentType.FEE_TARIFFS
    ]
  ],
  [
    ProductFinancialSector.DEPOSITS,
    [
      FinancialMediationSectorAttachmentType.CONTRACT,
      FinancialMediationSectorAttachmentType.GENERAL_CONDITIONS,
      FinancialMediationSectorAttachmentType.FEE_TARIFFS,
      FinancialMediationSectorAttachmentType.ANNUAL_INTEREST_RATE_AMOUNT
    ]
  ],
  [
    ProductFinancialSector.SENIOR_PENSION_SAVINGS,
    [
      FinancialMediationSectorAttachmentType.CONTRACT,
      FinancialMediationSectorAttachmentType.GENERAL_CONDITIONS,
      FinancialMediationSectorAttachmentType.FEE_TARIFFS,
      FinancialMediationSectorAttachmentType.STATUTE,
      FinancialMediationSectorAttachmentType.KEY_INFORMATION
    ]
  ],
  [
    ProductFinancialSector.SUPPLEMENTARY_PENSION_SAVINGS,
    [
      FinancialMediationSectorAttachmentType.CONTRACT,
      FinancialMediationSectorAttachmentType.GENERAL_CONDITIONS,
      FinancialMediationSectorAttachmentType.FEE_TARIFFS,
      FinancialMediationSectorAttachmentType.STATUTE,
      FinancialMediationSectorAttachmentType.KEY_INFORMATION,
      FinancialMediationSectorAttachmentType.BATCH_PLAN
    ]
  ]
]);

export const FINANCIAL_MEDIATION_PILLAR_TO_REQUIREMENT_MAP = new Map<ProductFinancialSector, PillarsRequirement[]>([
  [
    ProductFinancialSector.SENIOR_PENSION_SAVINGS,
    [
      PillarsRequirement.CAPITAL_FORMATION,
      PillarsRequirement.VOLUNTARY_CONTRIBUTION,
      PillarsRequirement.PENSION_ACCOUNT_SAVINGS,
      PillarsRequirement.OTHER
    ]
  ],
  [
    ProductFinancialSector.SUPPLEMENTARY_PENSION_SAVINGS,
    [
      PillarsRequirement.CAPITAL_FORMATION,
      PillarsRequirement.VOLUNTARY_CONTRIBUTION,
      PillarsRequirement.TAX_BENEFITS,
      PillarsRequirement.EMPLOYER_CONTRIBUTION,
      PillarsRequirement.EMPLOYEE_CONTRIBUTION_OBLIGATION,
      PillarsRequirement.BENEFIT_FOR_EMPLOYEES,
      PillarsRequirement.GUARANTEED_RETURN,
      PillarsRequirement.POTENTIALLY_HIGHER_RETURN,
      PillarsRequirement.OTHER
    ]
  ]
]);

export const QUESTIONNAIRE_ANSWERS_COUNT_MAP = new Map<number, number>([
  [1, 5],
  [2, 5],
  [3, 3],
  [4, 3],
  [5, 5],
  [6, 4],
  [7, 4],
  [8, 4],
  [9, 4],
  [10, 4],
  [11, 4],
  [12, 4],
  [13, 4],
  [14, 4],
  [15, 4],
  [16, 4],
  [17, 4]
]);

export const QUESTIONNAIRE_MULTIPLE_ANSWERS_QUESTIONS_NUMBERS = [1, 2, 5];

export const getAllVersionsForFinancialMediation = (
  actualVersion: FinancialMediationVersion
): FinancialMediationVersion[] => {
  const versionsKeys = Object.keys(FinancialMediationVersion);
  return [
    FinancialMediationVersion.VERSION_1,
    FinancialMediationVersion.VERSION_2,
    FinancialMediationVersion.VERSION_3,
    FinancialMediationVersion.VERSION_4,
    FinancialMediationVersion.VERSION_5
  ].filter(version => versionsKeys.indexOf(version) <= versionsKeys.indexOf(actualVersion));
};
