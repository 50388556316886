import { Card, Switch, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import t from "../../../../app/i18n";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { Permission } from "../../../../common/security/authorization/enums";
import { RootState, SearchPageResult } from "../../../../common/types";
import { paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import { selectHasPermissions } from "../../../auth/ducks";
import { INSTITUTION_ROUTE_PATHS } from "../../paths";
import { InstitutionList } from "../../types";
import InstitutionTypeTagWithName from "../InstitutionTypeTagWithName";

interface Props {
  institutionsPage: SearchPageResult<InstitutionList>;
  onPageChange: (pageNumber: number) => void;
  onInstitutionDeactivatedToggle: (institution: InstitutionList) => void;
}

const InstitutionListTableView = (props: Props) => {
  const hasAdminGlobalSettingsPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.ADMIN_GLOBAL_ENUMERATIONS)(state)
  );

  const columns: ColumnsType<InstitutionList> = [
    {
      key: "name",
      title: t("institution.attrs.name"),
      width: 260,
      ellipsis: { showTitle: false },
      render: (_, record) => <InstitutionTypeTagWithName institution={record} ellipsis />
    },
    {
      key: "officialName",
      title: t("institution.attrs.officialName"),
      width: 450,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.officialName}</Ellipsis>
    },
    {
      key: "activated",
      title: (
        <LabelWithTooltip label={t("institution.attrs.activated")} tooltip={t("institution.helpers.deactivatedDesc")} />
      ),
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Switch
          size="small"
          checked={!record.settings?.deactivated}
          onChange={() => props.onInstitutionDeactivatedToggle(record)}
        />
      )
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 90,
      render: (_, record) =>
        hasAdminGlobalSettingsPermission && (
          <ActionTextIcon
            path={generatePath(INSTITUTION_ROUTE_PATHS.detail.to, { id: record.id })}
            icon="edit"
            color="blue"
            text={t("common.edit")}
          />
        )
    }
  ];

  return (
    <Card className="card-box">
      <Table<InstitutionList>
        {...tableStandardProps()}
        columns={columns}
        dataSource={props.institutionsPage.pageData}
        scroll={{ x: TableSizes.MEDIUM }}
        pagination={{
          ...paginationTableProps,
          current: props.institutionsPage.pageIndex + 1,
          pageSize: props.institutionsPage.pageSize,
          total: props.institutionsPage.totalElementsCount,
          onChange: props.onPageChange
        }}
      />
    </Card>
  );
};

export default InstitutionListTableView;
